import { isNumber } from "lodash"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { useMediaQuery } from "../../hooks/useMediaQuery"
import { useOnClickOutside } from "../../hooks/UserClickOutsideHook"
import { applicationSelector } from "../../screens/application/redux/ApplicationSelector"
import { LoaderSmall } from "../LoaderSmall/LoaderSmall"

const Select = props => {
  const {
    options,
    placeholder,
    className,
    onChangeCustomSelect,
    onSearchChange,
    value,
    isMulti,
    isLabelRequired,
    isCountryDropDown,
    isSearchable,
    isDisabled,
    isShowEditWarning,
    onFieldChange,
    isCheckPosition,
    isLoading,
    isNoClearIcon,
    isRemoveAns,
    ...restProps
  } = props

  const isMobileScreen = useMediaQuery("(max-width: 680px)")
  const { fieldsToShow } = useSelector(applicationSelector)
  const [isOpenCustomSelect, setIsOpenCustomSelect] = useState(false)
  const [selectedList, setSelectedList] = useState(null)
  const [isSearching, setIsSearching] = useState(false)
  const [inputVal, setInputVal] = useState("")
  const [clickCount, setClickCount] = useState(0)
  const [dropDownTop, setDropDownTop] = useState(0)
  const [dropDownLeft, setDropDownLeft] = useState(0)
  const [dropDownBottom, setDropDownBottom] = useState(0)
  const [dropDownWidth, setDropdownWidth] = useState(50)

  const customSelectRef = useRef(null)
  const customDropdownRef = useRef(null)

  const selectedValue = () => {
    if (isCountryDropDown && !isLabelRequired) {
      return value?.value && `${value?.value?.flag} ${value?.value?.code}`
    } else if (!isCountryDropDown && !isLabelRequired) {
      return value && value?.label
    } else {
      return selectedList[0]?.label
    }
  }

  const selectClassName = `custom-select ${isOpenCustomSelect && !isCountryDropDown && "focus border-secondary"}
  ${selectedList?.length > 0 && !isCountryDropDown && "focus border-primary"}
  ${selectedList?.length > 0 ? "just-bet" : "just-end"}
  `

  const onItemSelection = clickedItem => {
    const finalSelectedList = [...selectedList, clickedItem]
    if (isMulti) {
      setSelectedList(finalSelectedList)
      onChangeCustomSelect(finalSelectedList)
      setIsSearching(false)
    } else {
      setSelectedList([clickedItem])
      onChangeCustomSelect(clickedItem)
      setIsSearching(false)
      setInputVal("")
      setIsOpenCustomSelect(false)
    }
    setClickCount(clickCount + 1)
  }

  const onItemUnselect = clickedItem => {
    const finalSelectedList = selectedList?.filter(item => item?.value !== clickedItem?.value)
    if (isMulti) {
      setSelectedList(finalSelectedList)
      onChangeCustomSelect(finalSelectedList)
    } else {
      onChangeCustomSelect(clickedItem)
      // setSelectedList(options?.filter(item => item?.value !== clickedItem?.value))
      setIsOpenCustomSelect(false)
    }
  }

  const onSearchCustomSelect = e => {
    setInputVal(e.target.value)
  }

  const handleOnFocus = () => {
    if (!isDisabled) {
      setIsOpenCustomSelect(true)
      setClickCount(clickCount + 1)
      setTimeout(() => {
        const eleId = document.getElementById("custom-select-dropdown")
        if (eleId && eleId.offsetHeight > 199) {
          eleId.style.overflowY = "auto"
        }
        updateSelectLocation()
      }, 5)
    }
  }

  function updateSelectLocation() {
    if (clickCount === 0 && isCheckPosition) {
      setDropDownTop(customDropdownRef?.current?.getBoundingClientRect()?.top + 45)
    } else {
      setDropDownTop(customSelectRef?.current?.getBoundingClientRect()?.top + 45)
    }
    setDropDownLeft(customSelectRef?.current?.getBoundingClientRect()?.left)
    setDropdownWidth(customSelectRef?.current?.offsetWidth)
  }

  let singleSelectOptions = options?.filter(
    option =>
      option?.label
        ?.toString()
        ?.toLowerCase()
        ?.includes(inputVal?.toString()?.toLowerCase()) ||
      option?.value?.name
        ?.toString()
        ?.toLowerCase()
        ?.includes(inputVal?.toString()?.toLowerCase()),
  )

  singleSelectOptions = singleSelectOptions.filter(function(el) {
    return el?.value !== ""
  })

  const onClearSelectedItem = () => {
    setSelectedList([])
    onChangeCustomSelect(undefined)
  }

  const notSelectedList = () =>
    options?.filter(item =>
      isCountryDropDown
        ? item?.value?.name?.toLowerCase()?.includes(inputVal?.toString()?.toLowerCase()) ||
          item?.value?.code?.includes(inputVal?.toString()?.toLowerCase())
        : !selectedList?.map?.(e => e?.value)?.includes(item?.value) &&
          item?.label?.toLowerCase()?.includes(inputVal?.toString()?.toLowerCase()),
    )

  const handleKeyNavigation = (e, callback) => {
    const active = document?.activeElement
    if (e.keyCode === 40 && active.nextSibling) {
      active.nextSibling.focus()
    }
    if (e.keyCode === 38 && active.previousSibling) {
      active.previousSibling.focus()
    }
    if (e.keyCode === 13) {
      callback()
    }
  }

  useOnClickOutside(customDropdownRef, e => {
    // e.preventDefault()
    if (e?.touches?.length) {
      e.preventDefault()
      e.stopCapture()
    }
    setInputVal("")
    setIsSearching(false)
  })

  useOnClickOutside(customSelectRef, () => {
    setIsOpenCustomSelect(false)
  })
  // If dropdown options is open it should be on focus in mobile

  useEffect(() => {
    if (isMobileScreen && isOpenCustomSelect && customDropdownRef.current) {
      // timeout because keyboard takes time to open
      setTimeout(() => {
        customDropdownRef.current.scrollIntoView({ behaviour: "smooth", block: "start" })
      }, 200)
    }
  }, [isOpenCustomSelect, customDropdownRef.current, isSearching])

  useEffect(() => {
    if (value || isNumber(value)) {
      setSelectedList(isMulti ? (value?.length > 0 ? value : []) : value?.value || value?.value === 0 ? [value] : [])
    } else {
      setSelectedList([])
    }
  }, [value])

  useEffect(() => {
    setDropDownTop(customDropdownRef?.current?.getBoundingClientRect()?.top + 45)
    window.addEventListener("resize", updateSelectLocation)
    window.addEventListener("scroll", updateSelectLocation, true)
    updateSelectLocation()
  }, [customDropdownRef?.current, customSelectRef?.current, fieldsToShow])

  useEffect(() => {
    setDropDownBottom(window.innerHeight - dropDownTop)
  }, [window.innerHeight, dropDownTop])

  useEffect(() => {
    if (isRemoveAns === true) {
      setSelectedList([])
      onChangeCustomSelect(undefined)
      setIsSearching(false)
      setInputVal("")
      setIsOpenCustomSelect(false)
    }
  }, [isRemoveAns])
  useEffect(() => {
    customSelectRef?.current?.addEventListener("focusout", e => {
      e?.preventDefault()
      if (!customSelectRef?.current?.contains(e?.relatedTarget)) {
        if (e?.touches?.length) {
          e.preventDefault()
          e.stopCapture()
        }
        setInputVal("")
        setIsSearching(false)
        setIsOpenCustomSelect(false)
      }
    })
  }, [customSelectRef?.current])

  useEffect(() => {
    return () => {
      window.removeEventListener("resize", updateSelectLocation)
      window.removeEventListener("scroll", updateSelectLocation, true)
      customSelectRef.current = null
      customDropdownRef.current = null
    }
  }, [])

  useEffect(() => {
    if (isSearching) {
      setInputVal(inputVal)
    }
  }, [inputVal, isSearching])

  const optionsDOM = (
    <>
      {isSearchable && (
        <div className="custom-select__search">
          <div className="custom-select__search-input-container">
            <input
              className="custom-select__search-input"
              type="text"
              placeholder="Type here..."
              value={inputVal}
              onChange={onSearchCustomSelect}
              onBlur={() => {
                setIsSearching(false)
              }}
              onFocus={() => {
                setIsSearching(true)
                setInputVal("")
              }}
            />
            <span className="material-icons-outlined custom-select__search-icon">search</span>
          </div>
        </div>
      )}
      {options?.length ? (
        <>
          {isMulti ? (
            <>
              <ul>
                {selectedList?.map((selectedItem, index) => (
                  <li
                    key={index}
                    tabIndex={index}
                    onKeyDown={e => handleKeyNavigation(e, () => onItemUnselect(selectedItem))}
                    onClick={() => onItemUnselect(selectedItem)}
                    className="selected-list"
                  >
                    <>{selectedItem?.label || ""}</>
                    {!isCountryDropDown && <span className="material-icons-outlined">task_alt</span>}
                  </li>
                ))}
                {options?.length > 0 &&
                  notSelectedList()?.map((unselectedItem, index) => (
                    <li
                      key={index}
                      tabIndex={selectedList?.length + index}
                      onKeyDown={e => handleKeyNavigation(e, () => onItemSelection(unselectedItem))}
                      onClick={() => onItemSelection(unselectedItem)}
                    >
                      {unselectedItem?.label}
                    </li>
                  ))}
              </ul>
              {notSelectedList()?.length === 0 && selectedList?.length === 0 && (
                <div className="no-option-text">No options</div>
              )}
            </>
          ) : (
            <>
              {singleSelectOptions?.length > 0 ? (
                <ul className="unselected-list">
                  {singleSelectOptions?.map((option, index) => (
                    <li
                      key={index}
                      label={option?.label}
                      tabIndex={index}
                      className={
                        (isCountryDropDown && value?.value?.name === option?.value?.name) ||
                        value?.value === option?.value
                          ? "single-select-selected"
                          : ""
                      }
                      onKeyDown={e => handleKeyNavigation(e, () => onItemSelection(option))}
                      onClick={() => onItemSelection(option)}
                    >
                      {option?.label}
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="no-option-text">No options</div>
              )}
            </>
          )}
        </>
      ) : (
        <div className="no-option-text">No options</div>
      )}
    </>
  )
  const loadingDOM = (
    <div style={{ height: "45px" }}>
      <LoaderSmall />
    </div>
  )

  return (
    <div
      id="sim3-custom-select"
      onFocus={handleOnFocus}
      ref={customSelectRef}
      className={`custom-select-container ${className}`}
      tabIndex="0"
    >
      <div className={selectClassName} onClick={handleOnFocus} {...restProps}>
        {isLabelRequired && <label className="custom-select-label">{placeholder}</label>}
        {!isLabelRequired && selectedList?.length === 0 && !isCountryDropDown && (
          <span className="select-placeholder">{placeholder}</span>
        )}
        {selectedList?.length > 0 && <div className="selected-first-value">{selectedValue()}</div>}
        {isCountryDropDown && !isLabelRequired && selectedList?.length === 0 && (
          <div className="custom-select__simple-placeholder">{placeholder}</div>
        )}
        <div className="d-flex align-center">
          {isMulti && selectedList?.length > 1 && (
            <div
              className="more-text"
              title={selectedList
                ?.filter((_, index) => index !== 0)
                .map(record => record.label)
                .join(", ")}
            >
              +{selectedList?.length - 1} more
            </div>
          )}
          {selectedList?.length > 0 && !isNoClearIcon && isOpenCustomSelect && !isCountryDropDown && (
            <span
              className="material-icons-round custom-select-close-icon"
              title="Clear selected item"
              onClick={onClearSelectedItem}
            >
              close
            </span>
          )}
          {!isDisabled && (
            <span
              className={`material-icons-outlined ${isOpenCustomSelect && "active-select-arrow"} ${
                selectedList?.length > 0 ? "font-primary" : "font-placeholder"
              }`}
            >
              expand_more
            </span>
          )}
        </div>
      </div>
      {isOpenCustomSelect && (
        <div
          ref={customDropdownRef}
          id="custom-select-dropdown"
          className={`custom-select-dropdown ${isOpenCustomSelect &&
            "active-custom-select-dropdown"} ${isCountryDropDown && "country-select-dropdown"}`}
          style={
            !isMobileScreen
              ? {
                  top: window.innerHeight - dropDownTop > 200 && dropDownTop,
                  left: dropDownLeft,
                  width: dropDownWidth,

                  bottom:
                    window.innerHeight - dropDownTop < 200 &&
                    window.innerHeight - (dropDownTop + 40) < 200 &&
                    dropDownBottom + 55,
                }
              : { position: "relative" }
          }
        >
          {isLoading ? loadingDOM : optionsDOM}
        </div>
      )}
    </div>
  )
}

Select.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChangeCustomSelect: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  onSearchChange: PropTypes.func,
  isMulti: PropTypes.bool,
  isLabelRequired: PropTypes.bool,
  isCountryDropDown: PropTypes.bool,
  isSearchable: PropTypes.bool,
  isCheckPosition: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isShowEditWarning: PropTypes.bool,
  onFieldChange: PropTypes.func,
  isLoading: PropTypes.bool,
  isNoClearIcon: PropTypes.bool,
  isRemoveAns: PropTypes.bool,
}

Select.defaultProps = {
  className: "custom-select ",
  onChangeCustomSelect: () => {},
  onSearchChange: () => {},
  isMulti: false,
  isLabelRequired: true,
  isCountryDropDown: false,
  isSearchable: true,
  isDisabled: false,
  isShowEditWarning: false,
  isLoading: false,
  isNoClearIcon: false,
  isRemoveAns: false,
}

export default Select
