import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Button from "../../../common/Button/Button"
import CountrySelect from "../../../common/CountrySelect/CountrySelect"
import Input from "../../../common/Input/Input"
import { onlyAlphabets, validateEmail, validatePhone } from "../../../helpers/ValidationHelper"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import AuthLayout from "../AuthLayout"
import { onRegistrationCredentialValueChange, registerUser } from "./redux/RegisterActions"
import { useEffect } from "react"
import { REGISTER_REDUX_CONSTANTS } from "./redux/RegisterReduxConstants"
import useKeyPress from "../../../hooks/useKeyPress"
import { CheckBox } from "../../../common"

const RegistrationPage = () => {
  const history = useHistory()
  const { isRedirectedFromQuote, isQuoteSubmit } = history?.location?.state ?? {}
  const isEnterPressed = useKeyPress("Enter")
  const dispatch = useDispatch()
  const {
    firstName,
    lastName,
    email,
    countryName,
    mobileNumber,
    isAllowMessages,
    isAllowTermsCondition,
    errors,
  } = useSelector(({ registerReducer }) => registerReducer ?? {})

  const { isRegisterUserLoading } = useSelector(({ loaderReducer }) => loaderReducer ?? {})
  let registerErrors = {}
  const handleRegDetailChange = (fieldName, fieldValue) => {
    dispatch(onRegistrationCredentialValueChange(fieldName, fieldValue))
    const format = /[`!@#$%^&*()_+\-={};':"|,.<>?~]/
    registerErrors = { ...registerErrors }
    //  handleRegistrationButtonClick(true)
    if (fieldName === "firstName") {
      if (!fieldValue || !fieldValue.length) registerErrors.firstName = "Please enter first name"
      else if (fieldValue && !onlyAlphabets(fieldValue)) registerErrors.firstName = "Please enter valid first name"
      else if (format.test(fieldValue)) registerErrors.firstName = "Please enter valid first name"
    }
    if (fieldName === "lastName") {
      if (!fieldValue || !fieldValue.length) registerErrors.lastName = "Please enter last name"
      else if (fieldValue && !onlyAlphabets(fieldValue)) registerErrors.lastName = "Please enter valid last name"
      else if (format.test(fieldValue)) registerErrors.lastName = "Please enter valid last name"
    }
    if (fieldName === "email") {
      if (fieldValue && !validateEmail(fieldValue)) registerErrors.email = "Please enter valid email address"
    }
    if (fieldName === "countryName") {
      if (!fieldValue || !fieldValue?.label?.toString()?.trim()?.length)
        registerErrors.mobileNumber = "Please select country code"
    }
    if (fieldName === "mobileNumber") {
      if (!fieldValue || !fieldValue?.length) registerErrors.mobileNumber = "Please enter mobile number"
      else if (fieldValue && !validatePhone(fieldValue))
        registerErrors.mobileNumber = "Please enter valid mobile number"
    }
    if (fieldName === "isAllowMessages") {
      if (!fieldValue) registerErrors.sms = "Please agree on opt in to receive SMS"
    }
    if (fieldName === "isAllowTermsCondition") {
      if (!fieldValue) registerErrors.tnc = "Please agree T&C to continue"
    }

    dispatch(onRegistrationCredentialValueChange("errors", registerErrors))
  }

  const handleRegistrationButtonClick = () => {
    const format = /[`!@#$%^&*()_+\-={};':"|,.<>?~]/

    if (!firstName || !firstName.length) registerErrors.firstName = "Please enter first name"
    else if (firstName && !onlyAlphabets(firstName)) registerErrors.firstName = "Please enter valid first name"
    else if (format.test(firstName)) registerErrors.firstName = "Please enter valid first name"
    else if (!lastName || !lastName) registerErrors.lastName = "Please enter last name"
    else if (format.test(lastName)) registerErrors.lastName = "Please enter valid last name"
    else if (lastName && !onlyAlphabets(lastName)) registerErrors.lastName = "Please enter valid last name"
    else if (email && !validateEmail(email)) registerErrors.email = "Please enter valid email address"
    else if (!countryName || !countryName?.label?.toString()?.trim()?.length)
      registerErrors.mobileNumber = "Please select country code"
    else if (!mobileNumber || !mobileNumber?.length) registerErrors.mobileNumber = "Please enter mobile number"
    else if (mobileNumber && !validatePhone(mobileNumber))
      registerErrors.mobileNumber = "Please enter valid mobile number"
    else if (!isAllowMessages) registerErrors.sms = "Please agree on opt in to receive SMS"
    else if (!isAllowTermsCondition) registerErrors.tnc = "Please agree T&C to continue"
    if (_.isEmpty(registerErrors)) {
      dispatch(onRegistrationCredentialValueChange("errors", {}))
      const data = {
        firstName,
        lastName,
        email: email ? email.toLowerCase().trim() : undefined,
        countryCode: countryName?.value?.code,
        mobileNumber,
        isAllowTermsCondition,
        isAllowMessages,
        setDefaultPassword: false,
      }
      dispatch(registerUser(data, history, isRedirectedFromQuote, isQuoteSubmit, false))
    } else {
      dispatch(onRegistrationCredentialValueChange("errors", registerErrors))
    }
  }

  useEffect(() => {
    localStorage.removeItem("otpCount")
    return () => {
      dispatch({
        type: REGISTER_REDUX_CONSTANTS.CREDENTIAL_RESET,
      })
    }
  }, [])

  useEffect(async () => {
    if (isEnterPressed) {
      handleRegistrationButtonClick()
    }
  }, [isEnterPressed])

  return (
    <AuthLayout title="Register">
      <Input
        type="text"
        placeholder="First name*"
        name="firstName"
        borderClass="w-100"
        value={firstName}
        onChange={e => {
          handleRegDetailChange(e.target.name, e.target.value?.toString()?.trim())
        }}
        error={errors?.firstName}
      />
      <Input
        type="text"
        className="w-100 mt-15"
        name="lastName"
        value={lastName}
        placeholder="Last name*"
        onChange={e => {
          handleRegDetailChange(e.target.name, e.target.value?.toString()?.trim())
        }}
        error={errors?.lastName}
      />
      <Input
        type="email"
        className="w-100 mt-15"
        name="email"
        value={email}
        placeholder="Email address"
        onChange={e => {
          handleRegDetailChange(e.target.name, e.target.value?.toString()?.trim())
        }}
        error={errors?.email}
      />
      <CountrySelect
        countryCode=""
        countryName={countryName}
        phoneNumber={mobileNumber}
        placeholder="Mobile number*"
        className="w-100 mt-15"
        updatePhoneNumber={e => {
          handleRegDetailChange("mobileNumber", e?.toString().trim())
        }}
        updatePhoneCountry={e => {
          handleRegDetailChange("countryName", e)
        }}
        error={errors?.mobileNumber}
      />
      <CheckBox
        title="I opt in to receive SMS and Email messages specifically related to finance options from Company name.*"
        className="form-checkbox"
        name="isAllowMessages"
        checked={isAllowMessages}
        onChange={e => {
          handleRegDetailChange(e.target.name, e.target.checked)
        }}
      />
      {errors?.sms && !isAllowMessages && <span className="error-text">{errors?.sms}</span>}
      <CheckBox
        title="I have read and understood the Terms and Conditions, Privacy Collection Notice & Credit Guide and I acknowledge the results are not a suggestions that I apply for a particular loan. I am aware that only car loan products from Simply Finance Australia's participating lenders are provided.*"
        className="form-checkbox"
        name="isAllowTermsCondition"
        checked={isAllowTermsCondition}
        onChange={e => {
          handleRegDetailChange(e.target.name, e.target.checked)
        }}
      />
      {errors?.tnc && !isAllowTermsCondition && <span className="error-text">{errors?.tnc}</span>}
      <div className="text-center w-100">
        <Button
          buttonType="secondary"
          title="Register"
          className="form-button"
          onClick={() => handleRegistrationButtonClick()}
          isDisabled={isRegisterUserLoading}
          isLoading={isRegisterUserLoading}
        />
        <div className="form-already-account-text">
          Already have an account?{" "}
          <span
            onClick={() =>
              history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN, {
                isRedirectedFromQuote,
                isQuoteSubmit,
              })
            }
          >
            Login
          </span>
        </div>
      </div>
    </AuthLayout>
  )
}

export default RegistrationPage
