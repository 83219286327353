/* eslint-disable no-console */
import { Fragment, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  copyAsset,
  copyFields,
  performMergeForm,
  //  removeFormData,
  repeaterFormAddAndRemove,
  setFormData,
  setGlassGuideLabelData,
  setInitialFormData,
  syncFormDataWithFields,
  updateBrokerFields,
  validateFields,
} from "./redux/brokerSlice"
import _, { isEmpty, isNull } from "lodash"
import logo from "../../assets/images/logo.svg"
import { RenderField } from "../application/components/RenderField"
import {
  createUserAction,
  equifaxSaveDatForBroker,
  getApplicationFormPath,
  getBrokerData,
  getBrokerJSON,
  getQuoteFormPath,
  getVanishFormData,
  handleRegoSearchBroker,
  saveBrokerData,
  validateFieldsAction,
} from "./redux/BrokerAction"
import RepeaterForm from "./components/RepeaterForm"
import Button from "../../common/Button/Button"
import Loader from "../../common/loader/Loader"
import ABNModalTable from "../../common/ABNModalTable/ABNModalTable"
import ApplicationApiServices from "../application/services/ApplicationServices"
import { BASE_URL } from "../../constants/UrlConstants"
import { errorNotification } from "../../common/NotifyToaster"
import moment from "moment"
import BrokerOTPVerification from "./components/BrokerOTPVerification"
import BrokerEmailVerification from "./components/BrokerEmailVerification"
import { useQuery } from "../../hooks/useQuery"
import { DEAL_ID_QUERY_PARAM } from "../application/ApplicationPage/ApplicationPageConstants"
import { useHistory } from "react-router-dom"
import { ROUTE_CONSTANTS_VARIABLE } from "../../routes/RouteConstants"

const BrokerFormScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const brokerData = useSelector(state => state?.brokerReducer)
  const {
    brokerApplicationJsonData,
    formData,
    brokerFormPaths,
    brokerFormPathIndex,
    isLastQuestion,
    mergeFormsData,
    isApplicationLastQuestion,
    errorCounter,
    waitForSecond,
  } = useMemo(() => brokerData, [brokerData])
  const { brokerLoader, brokerLoaderNew } = useSelector(({ loaderReducer }) => loaderReducer ?? {})
  const [isAbnModalOpen, setIsAbnModalOpen] = useState(false)
  // const [ isInitialCalled, setIsInitialCalled ] = useState( false )
  const [isAbnDataReceived, setIsAbnDataReceived] = useState(true)
  const [abnDataBroker, SetAbnData] = useState([])
  const [requestSave, SetRequestSave] = useState("")
  const [cform, Setcform] = useState("")
  const [savebtnClicked, SetSavebtnClicked] = useState(false)
  const query = useQuery()

  const lastQuestion = useMemo(() => {
    return (
      brokerFormPaths?.length - 1 === brokerFormPathIndex &&
      brokerFormPaths?.[brokerFormPathIndex]?.[0] === "serviceability"
    )
  }, [brokerFormPaths, brokerFormPathIndex])

  useEffect(() => {
    ;(async () => {
      try {
        if (Object.values(brokerApplicationJsonData).length === 0) {
          await dispatch(getBrokerJSON())

          // START   -------------------------------------------------------------- Get broker data from dealId
          if (query.get(DEAL_ID_QUERY_PARAM)) {
            await dispatch(getBrokerData(query.get(DEAL_ID_QUERY_PARAM)))
          }
          // END     -------------------------------------------------------------- Get broker data from dealId
        }
      } catch (e) {
        /**/
      }
    })()
  }, [])

  useEffect(() => {
    if (Object.keys(brokerApplicationJsonData).length > 0) {
      dispatch(setInitialFormData())
      // setIsInitialCalled( true )
    }
  }, [brokerFormPaths, brokerFormPathIndex, brokerApplicationJsonData])

  useEffect(() => {
    ;(async () => {
      if (isLastQuestion || isApplicationLastQuestion) {
        // !isEmpty(mergeFormsData) ||
        await dispatch(validateFieldsAction())
      }
    })()
  }, [isLastQuestion, isApplicationLastQuestion, errorCounter]) // mergeFormsData,

  useEffect(() => {
    ;(async () => {
      if (isLastQuestion) {
        setTimeout(async () => {
          await dispatch(getQuoteFormPath())
        }, [1050])
      }
      if (isApplicationLastQuestion) {
        if (waitForSecond) {
          setTimeout(async () => {
            await dispatch(getApplicationFormPath())
          }, [1000])
        } else {
          await dispatch(getApplicationFormPath())
        }
      }
      if (!isEmpty(mergeFormsData) && !isNull(mergeFormsData)) {
        await dispatch(performMergeForm(mergeFormsData))
      }

      const incomeOutgoingPassed = brokerFormPaths?.filter((formList, index) => {
        if (formList[1] === "customerDetails") {
          const valueFilled = formData?.[`${formList[0]}`]?.[`${formList[1]}`]?.[1]?.fields?.[0]?.answer

          if (valueFilled) {
            return true
          }
        }
        if (formList[1] === "companyRegistrationDetails") {
          const valueFilled = formData?.[`${formList[0]}`]?.[`${formList[1]}`]?.[0]?.answer

          if (valueFilled) {
            return true
          }
        }
        if (formList[1] === "trustDetails") {
          const valueFilled = formData?.[`${formList[0]}`]?.[`${formList[1]}`]?.[0]?.answer

          if (valueFilled) {
            return true
          }
        }
        if (formList[0] === "quoteDetails") {
          const valueFilled = formData?.[`${formList[0]}`]?.[3]?.answer

          if (valueFilled) {
            return true
          }
        }
        return false
      })
      if (incomeOutgoingPassed.length === 0) {
        const divScroll = document.querySelector("#root > div.application-tool-form.movetoSidebar")
        divScroll.scrollTop = divScroll.scrollHeight
      }
    })()
  }, [errorCounter, mergeFormsData, isLastQuestion, isApplicationLastQuestion, waitForSecond])

  const getLabel = formToShow => {
    return _.get(brokerApplicationJsonData, `${formToShow.join(".")}.label`, "")
  }
  const getParamValue = paramName => {
    const url = window.location.search.substring(1)
    const qArray = url.split("&")
    for (let i = 0; i < qArray.length; i++) {
      const pArr = qArray[i].split("=")
      if (pArr[0] === paramName) return pArr[1]
    }
  }

  const formsToShow = useMemo(() => brokerFormPaths.slice(0, brokerFormPathIndex + 1), [
    brokerFormPaths,
    brokerFormPathIndex,
  ])
  const handleCancelClick = () => {
    const confirmBox = window.confirm("Are you sure you want to quite this process? action can't be undone")
    if (confirmBox === true) {
      window.location.replace(`https://crm.zoho.com/crm/simply/tab/Potentials/custom-view/4901466000000087545/list`)
    }
  }

  useEffect(() => {
    if (savebtnClicked && errorCounter === 0) {
      sendDataOfForm()
      SetSavebtnClicked(false)
    } else {
      SetSavebtnClicked(false)
    }
  }, [errorCounter, savebtnClicked])

  const sendDataOfForm = async () => {
    let equifaxScore = 700

    if (errorCounter <= 0) {
      const fieldsToSend = {}
      const fieldsToFind = [
        "firstName",
        "lastName",
        "middleName",
        "mobile",
        "email",
        "dob",
        "city",
        "postCode",
        "country",
        "stateshort",
        "street_number",
        "street_name",
        "toBeFinanced",
        "howMuchDoYouWantToBorrow",
      ]

      const isTrustFlow = brokerFormPaths?.findIndex(e => e?.[0] === "gurantor1" && e?.[1] === "customerDetails")
      const path =
        isTrustFlow > -1
          ? ["gurantor1.customerDetails", "gurantor1.addressInformation", "gurantor1.personalDetails", "financeNumbers"]
          : [
              "applicantDetails1.customerDetails",
              "applicantDetails1.addressInformation",
              "applicantDetails1.personalDetails",
              "financeNumbers",
            ]
      let jsonFields = []
      path.forEach(e => {
        const fields = _.get(formData, e) || []
        jsonFields = [...jsonFields, ...fields]
      })

      function collect(array) {
        array.forEach(function(el) {
          if (el?.fields && el?.fields?.length > 0) {
            collect(el?.fields)
          } else {
            if (fieldsToFind.includes(el?.fieldName) && el?.answer) {
              fieldsToSend[el.fieldName] = el?.answer
            }
          }
        })
      }

      collect(jsonFields)

      const {
        firstName,
        lastName,
        middleName,
        mobile,
        email,
        dob,
        city: suburb,
        postCode,
        country,
        stateshort: state,
        street_number: streetNumber,
        street_name: streetName,
        toBeFinanced,
        howMuchDoYouWantToBorrow,
      } = fieldsToSend ?? {}

      const financedAmount = toBeFinanced || howMuchDoYouWantToBorrow || 10000

      const dateOfBirth = dob
        ?.split("/")
        ?.reverse()
        ?.join("-")

      if (
        dateOfBirth &&
        lastName &&
        firstName &&
        state &&
        streetName &&
        suburb &&
        streetNumber &&
        postCode &&
        country
      ) {
        const data = {
          lastName: lastName?.trim(),
          firstName: firstName?.trim(),
          middleName: middleName?.trim(),
          dateOfBirth: dateOfBirth,
          gender: "U",
          streetNumber: streetNumber?.trim(),
          streetName: streetName?.trim(),
          suburb: suburb?.trim(),
          state: state?.trim(),
          postCode: postCode?.trim(),
          country: country?.trim(),
          toBeFinanced: Math.round(financedAmount),
        }
        await dispatch(
          equifaxSaveDatForBroker(data, score => {
            if (score) {
              equifaxScore = score
            }
          }),
        )
      }
      if (firstName && lastName && email && mobile && equifaxScore) {
        const dataToSend = { firstName, lastName, email, mobile, equifaxScore }
        await dispatch(
          createUserAction(dataToSend, data => {
            if (!data?.isContactNumberVerified)
              dispatch(
                updateBrokerFields({
                  fieldName: "verificationData",
                  value: { ...data, ...dataToSend, verifyOTP: true },
                }),
              )
            else if (!data?.isEmailVerified)
              dispatch(
                updateBrokerFields({
                  fieldName: "verificationData",
                  value: { ...data, ...dataToSend, verifyEmail: true },
                }),
              )
            else {
              dispatch(
                updateBrokerFields({
                  fieldName: "verificationData",
                  value: { id: data?.id },
                }),
              )
              dispatch(
                saveBrokerData(data.id, query.get(DEAL_ID_QUERY_PARAM), response => {
                  history.push(`${ROUTE_CONSTANTS_VARIABLE.BROKER_VIEW_FORM}/${response[0].applicationId}`)
                }),
              )
            }
          }),
        )
      }
    } else {
      setTimeout(() => {
        const errorElem = document.querySelector(".application-error-message")
        const divScroll = errorElem.closest("div")
        if (divScroll) {
          divScroll?.scrollIntoView({
            top: divScroll?.scrollHeight - 150,
            left: 0,
            behavior: "smooth",
          })
        }
      }, [1000])
    }
  }
  const handleSearchBroker = async (currentform, fieldName, value, request) => {
    if (fieldName === "vin" && value) {
      const { url, method, params } = request
      const paramData = {
        ...params,
        identifier: params?.identifier ?? params?.identifier,
        vin: params?.identifier === "getByVIN" ? value : undefined,
        assetType: "Car, Van, Ute etc",
      }
      dispatch(handleRegoSearchBroker(url, method, paramData, currentform?.[0])).then(() => {})
    } else if (fieldName === "abn" && value) {
      //
      SetRequestSave(request)
      Setcform(currentform)
      handleABNOrACNSearchBroker(value, "ABNLookUp", request, currentform)
    } else if ((fieldName === "entityName" || fieldName === "name") && value) {
      SetRequestSave(request)
      Setcform(currentform)
      handleABNOrACNSearchBroker(value, "ABRNameLookUp", request, currentform)
    } else {
      if (fieldName === "acn" && value) {
        SetRequestSave(request)
        Setcform(currentform)
        handleABNOrACNSearchBroker(value, "ASICLookup", request, currentform)
      }
    }
  }

  const onCloseAbnModal = () => {
    setIsAbnModalOpen(false)
  }
  const handleABNOrACNSearchBroker = async (value, identifier, request, currentform) => {
    if (request === undefined) {
      request = requestSave
    }
    if (currentform === undefined) {
      currentform = cform
    }
    if (request?.url) {
      const { url, method, params } = request

      setIsAbnDataReceived(false)
      let paramData = {
        ...params,
        identifier: identifier ?? params?.identifier,
        searchString: value.toString().trim(),
      }

      if (
        ["companyDetails", "companyGurantor"].includes(currentform?.[0]) &&
        currentform?.[1] === "companyRegistrationDetails"
      ) {
        paramData.searchType = "Company"
      }
      if (["companyDetails", "companyGurantor"].includes(currentform?.[0]) && currentform?.[1] === "trustDetails") {
        paramData.searchType = "Trust"
      }
      if (paramData?.identifier !== "ABNLookUp" && paramData?.identifier !== "ASICLookup") {
        setIsAbnModalOpen(true)
      } else {
        paramData = {
          ...params,
          identifier: identifier ?? params?.identifier,
          searchString: value.toString().replace(/ /g, ""),
        }
        if (
          ["companyDetails", "companyGurantor"].includes(currentform?.[0]) &&
          currentform?.[1] === "companyRegistrationDetails"
        ) {
          paramData.searchType = "Company"
        }
        if (["companyDetails", "companyGurantor"].includes(currentform?.[0]) && currentform?.[1] === "trustDetails") {
          paramData.searchType = "Trust"
        }
      }

      try {
        await dispatch(handleABNSearch(url, method, paramData, currentform))
        setIsAbnDataReceived(true)
      } catch (e) {
        /**/
      }
    }
  }
  const handleABNSearch = (url, method, params, currentform) => {
    return async dispatch => {
      try {
        const response = await ApplicationApiServices.getDataFromExternalSources(
          `${BASE_URL}${url}`,
          _.toUpper(method),
          params,
        )

        if (response?.data?.status === "SUCCESS") {
          if (params?.identifier === "ABRNameLookUp") {
            // set state
            SetAbnData(response?.data?.data)
          } else {
            if (currentform?.[0] === "employment") {
              const formIndexAbn = formData[`${currentform?.[0]}`]?.findIndex(e => e.fieldName === "abn")
              const formIndexentityName = formData[`${currentform?.[0]}`]?.findIndex(e => e.fieldName === "entityName")
              const formIndexacn = formData[`${currentform?.[0]}`]?.findIndex(e => e.fieldName === "acn")

              const formIndexRegisteredDate = formData[`${currentform?.[0]}`]?.findIndex(
                e => e.fieldName === "registeredDate",
              )
              const formIndexgstStatus = formData[`${currentform?.[0]}`]?.findIndex(e => e.fieldName === "gstStatus")

              formIndexAbn &&
                dispatch(
                  setFormData({
                    path: `${currentform?.[0]}[${formIndexAbn}].answer`,
                    value: response?.data?.data?.abn,
                  }),
                )
              formIndexentityName &&
                dispatch(
                  setFormData({
                    path: `${currentform?.[0]}[${formIndexentityName}].answer`,
                    value: response?.data?.data?.entityName,
                  }),
                )
              formIndexacn &&
                dispatch(
                  setFormData({
                    path: `${currentform?.[0]}[${formIndexacn}].answer`,
                    value: response?.data?.data?.acn,
                  }),
                )
              formIndexRegisteredDate &&
                dispatch(
                  setFormData({
                    path: `${currentform?.[0]}[${formIndexRegisteredDate}].answer`,
                    value: response?.data?.data?.registeredDate,
                  }),
                )
              formIndexgstStatus &&
                dispatch(
                  setFormData({
                    path: `${currentform?.[0]}[${formIndexgstStatus}].answer`,
                    value: response?.data?.data?.gstStatus || "-",
                  }),
                )
            } else {
              if (["companyDetails", "companyGurantor"].includes(currentform?.[0])) {
                if (currentform?.[1] === "companyRegistrationDetails") {
                  const trusteeType = formData?.companyDetails?.trustDetails?.find(e => e.fieldName === "trusteeType")
                    ?.answer
                  const trusteeTypeG = formData?.companyGurantor?.trustDetails?.find(e => e.fieldName === "trusteeType")
                    ?.answer
                  const companyStructure = response?.data?.data?.companyStructure

                  if (trusteeType === "Individual" || trusteeTypeG === "Individual") {
                    if (!["Sole Trader"].includes(companyStructure)) {
                      errorNotification(`This is a ${companyStructure} please go back and change the trustee type.`)
                      return false
                    }
                  }

                  const formIndexentityName = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
                    e => e.fieldName === "name",
                  )
                  const formIndexAbn = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
                    e => e.fieldName === "abn",
                  )
                  const formIndexAcn = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
                    e => e.fieldName === "acn",
                  )
                  const formIndexBNV = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
                    e => e.fieldName === "businessANewVenture",
                  )
                  // const formIndexNOD = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
                  //   e => e.fieldName === "numberOfDirectors",
                  // )
                  const formIndexCompanyStructure = formData[
                    `${currentform?.[0]}`
                  ]?.companyRegistrationDetails?.findIndex(e => e.fieldName === "companyStructure")

                  const indexofForm = brokerFormPaths.findIndex(e => e.join(".") === currentform.join("."))
                  if (indexofForm >= 0) {
                    dispatch(
                      syncFormDataWithFields({
                        path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexBNV}]`,
                        index: indexofForm,
                      }),
                    )
                  }

                  const formIndexRegisteredDate = formData[
                    `${currentform?.[0]}`
                  ]?.companyRegistrationDetails?.findIndex(e => e.fieldName === "registeredFrom")
                  const formIndexgstStatus = formData[`${currentform?.[0]}`]?.companyRegistrationDetails?.findIndex(
                    e => e.fieldName === "gstStatus",
                  )

                  if (formIndexentityName >= 0) {
                    dispatch(
                      setFormData({
                        path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexentityName}].answer`,
                        value: response?.data?.data?.entityName,
                      }),
                    )
                  }
                  formIndexAbn &&
                    dispatch(
                      setFormData({
                        path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexAbn}].answer`,
                        value: response?.data?.data?.abn,
                      }),
                    )
                  formIndexAcn &&
                    dispatch(
                      setFormData({
                        path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexAcn}].answer`,
                        value: response?.data?.data?.acn,
                      }),
                    )
                  // if ( companyStructure === "Sole Trader" )
                  // {
                  //   console.log( formIndexNOD )
                  //   formIndexNOD &&
                  //     dispatch(
                  //       setFormData({
                  //         path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexNOD}].answer`,
                  //         value: 1,
                  //       }),
                  //     )
                  // }
                  // if ( companyStructure !== "Sole Trader" )
                  // {
                  //   formIndexNOD &&
                  //     dispatch(
                  //       setFormData( {
                  //         path: `${ currentform?.[ 0 ] }.companyRegistrationDetails[${ formIndexNOD }].answer`,
                  //         value: "",
                  //       } ),
                  //     )
                  // }
                  if (response?.data?.data?.registeredDate) {
                    let diff = 0
                    if (params?.identifier === "ASICLookup") {
                      const startDate = moment(response?.data?.data?.registeredDate, "YYYY-MM-DD")
                      const endDate = moment(new Date(), "YYYY-MM-DD")
                      diff = endDate.diff(startDate, "days")
                    } else {
                      const startDate = moment(response?.data?.data?.registeredDate, "DD/MM/YYYY")
                      const endDate = moment(new Date(), "DD/MM/YYYY")
                      diff = endDate.diff(startDate, "days")
                    }
                    formIndexBNV &&
                      dispatch(
                        setFormData({
                          path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexBNV}].answer`,
                          value: diff > 90 ? "No" : "Yes",
                        }),
                      )
                  }
                  formIndexCompanyStructure &&
                    dispatch(
                      setFormData({
                        path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexCompanyStructure}].answer`,
                        value: response?.data?.data?.companyStructure,
                      }),
                    )

                  formIndexRegisteredDate &&
                    dispatch(
                      setFormData({
                        path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexRegisteredDate}].answer`,
                        value: response?.data?.data?.registeredDate,
                      }),
                    )
                  formIndexgstStatus &&
                    dispatch(
                      setFormData({
                        path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexgstStatus}].answer`,
                        value: response?.data?.data?.gstStatus || "-",
                      }),
                    )
                } else if (currentform?.[1] === "trustDetails") {
                  if (
                    response?.data?.data?.companyStructure === "Discretionary / Family Trust" ||
                    response?.data?.data?.companyStructure === "Unit Trust" ||
                    response?.data?.data?.companyStructure === "Hybrid Trust" ||
                    response?.data?.data?.companyStructure === "Trust"
                  ) {
                    const formIndexentityName = formData[`${currentform?.[0]}`]?.trustDetails?.findIndex(
                      e => e.fieldName === "name",
                    )
                    const formIndexAbn = formData[`${currentform?.[0]}`]?.trustDetails?.findIndex(
                      e => e.fieldName === "abn",
                    )

                    const formIndexRegisteredDate = formData[`${currentform?.[0]}`]?.trustDetails?.findIndex(
                      e => e.fieldName === "registeredDate",
                    )
                    const indexofForm = brokerFormPaths.findIndex(e => e.join(".") === currentform.join("."))
                    if (indexofForm >= 0) {
                      dispatch(
                        syncFormDataWithFields({
                          path: `${currentform?.[0]}.companyRegistrationDetails[${formIndexAbn}]`,
                          index: indexofForm,
                        }),
                      )
                    }

                    const formIndexgsttype = formData[`${currentform?.[0]}`]?.trustDetails?.findIndex(
                      e => e.fieldName === "type",
                    )
                    const formIndexgstStatus = formData[`${currentform?.[0]}`]?.trustDetails?.findIndex(
                      e => e.fieldName === "gstStatus",
                    )

                    formIndexAbn &&
                      dispatch(
                        setFormData({
                          path: `${currentform?.[0]}.trustDetails[${formIndexAbn}].answer`,
                          value: response?.data?.data?.abn,
                        }),
                      )
                    if (formIndexentityName >= 0) {
                      dispatch(
                        setFormData({
                          path: `${currentform?.[0]}.trustDetails[${formIndexentityName}].answer`,
                          value: response?.data?.data?.entityName,
                        }),
                      )
                    }
                    formIndexRegisteredDate &&
                      dispatch(
                        setFormData({
                          path: `${currentform?.[0]}.trustDetails[${formIndexRegisteredDate}].answer`,
                          value: response?.data?.data?.registeredDate,
                        }),
                      )
                    formIndexgsttype &&
                      dispatch(
                        setFormData({
                          path: `${currentform?.[0]}.trustDetails[${formIndexgsttype}].answer`,
                          value: response?.data?.data?.companyStructure,
                        }),
                      )
                    formIndexgstStatus &&
                      dispatch(
                        setFormData({
                          path: `${currentform?.[0]}.trustDetails[${formIndexgstStatus}].answer`,
                          value: response?.data?.data?.gstStatus || "-",
                        }),
                      )
                  } else {
                    errorNotification("Please select trust entity")
                  }
                }
              }
            }
          }
        }
      } catch (e) {
        errorNotification(e?.response?.data?.message || e.message)
      }
    }
  }

  const renderField = (formToShow, field, self, index, mainIndex, innerIndex = "") => {
    // if ( field?.fieldName === "newCurrentRepayment" )
    // {
    //   const formDataFields = _.get( formData, "financeNumbers" )
    //   const value = formDataFields?.find( x => x?.fieldName === "payoutRepayment" )?.answer || 0
    //   field = { ...field, answer: value }
    // }
    return (
      <RenderField
        key={`${formToShow.join(".")}-${field.fieldName}-${field.key}`}
        field={field}
        formPath={[]}
        isFromAssetPage={false}
        isFromBrokerPage={true}
        isFromBrokerPageNew={false}
        currentForm={formToShow}
        pathOfField={
          innerIndex || innerIndex === 0
            ? `${formToShow.join(".")}[${mainIndex}].fields[${innerIndex}]`
            : `${formToShow.join(".")}[${mainIndex}]`
        }
        onFieldUpdate={(fieldName, value, label) => {
          // START   -------------------------------------------------------------- Copy asset data
          if (field?.copyForms) {
            const copyForms = { ...field?.copyForms }
            if (typeof copyForms.formName !== "string") {
              copyForms.formName = copyForms.formName[value]
            }
            dispatch(copyAsset({ copyForms, mainForm: formToShow[0], isAdd: !!value }))
          }
          // END     -------------------------------------------------------------- Copy asset data
          // START   -------------------------------------------------------------- Copy fields
          if (field?.copyFields) dispatch(copyFields({ field: { ...field, answer: value }, formPathIndex: index }))
          // END     -------------------------------------------------------------- Copy fields

          let path = ""
          let pathAD = ""
          let pathADnv = ""
          // SUB FIELDS
          if (innerIndex || innerIndex === 0) {
            path = `${formToShow.join(".")}[${mainIndex}].fields[${innerIndex}]`
            // NO SUB FIELDS
          } else {
            path = `${formToShow.join(".")}[${mainIndex}]`
          }

          // Will store key if glassguide field already has answer and its changed
          const glassGuideFieldAnswerChangedKey = field?.request?.url && !!field?.answer && value ? field?.key : ""
          if (fieldName === "nvic" && formToShow[0] === "vehicleSelect") {
            /** for NVIC */
            label && field?.request && dispatch(setGlassGuideLabelData({ formToShow, fieldName, label, value }))
            dispatch(
              setFormData({
                path,
                value: { ...field, answer: value },
              }),
            )
            /** for NVIC */

            /** for asset description */
            const formIndex = formData[`${formToShow[0]}`]?.findIndex(e => e.fieldName === "assetDescription")
            const assetYear = formData[`${formToShow[0]}`]?.find(e => e.fieldName === "assetYear")?.answer
            if (innerIndex || innerIndex === 0) {
              pathAD = `${formToShow.join(".")}[${mainIndex}].fields[${formIndex}]`
              // NO SUB FIELDS
            } else {
              pathAD = `${formToShow.join(".")}[${formIndex}]`
            }
            dispatch(
              setFormData({
                path: `${pathAD}.answer`,
                value: `${assetYear} ${label}`,
              }),
            )
            /** for asset description */
          } else {
            if (formToShow[1] === "assetGlassGuide") {
              if (fieldName === "model") {
                /** for asset nvic */
                const formIndexnvic = _.get(formData, formToShow)?.findIndex(e => e.fieldName === "nvic")
                pathADnv = `${formToShow.join(".")}[${formIndexnvic}].answer`

                dispatch(
                  setFormData({
                    path: `${pathADnv}`,
                    value: `${value}`,
                  }),
                )
                /** for asset description */

                /** for asset description */
                const formIndex = _.get(formData, formToShow)?.findIndex(e => e.fieldName === "assetDescription")
                const assetYear = _.get(formData, `${formToShow["0"]}.assetDetails`)?.find(
                  e => e.fieldName === "assetAge",
                )?.answer

                pathAD = `${formToShow.join(".")}[${formIndex}].answer`
                dispatch(
                  setFormData({
                    path: `${pathAD}`,
                    value: `${assetYear} ${label}`,
                  }),
                )
                /** for asset description */
              }
            }
            label &&
              field?.request &&
              dispatch(setGlassGuideLabelData({ formToShow: formToShow?.[0], fieldName, label }))

            // if (
            //   [
            //     "currentPropertyYears",
            //     "currentPropertyMonths",
            //     "previousPropertyYears",
            //     "previousPropertyMonths",
            //   ].includes(field?.fieldName)
            // ) {
            //   // if (field?.vanishNextForms) {
            //   //   dispatch(syncFormDataWithFields({ path, index, mainIndex, glassGuideFieldAnswerChangedKey }))
            //   // }
            //   field?.vanishNextForms && dispatch( removeFormData( { index, mainIndex, innerIndex } ) )
            //   // field?.vanishNextForms &&
            //   //   dispatch( getVanishFormData( { formToShow, fieldName, value, index, mainIndex, innerIndex } ) )
            // }
            dispatch(
              setFormData({
                path,
                value: { ...field, answer: value },
              }),
            )
          }

          if (errorCounter > 0 || field?.errorMessage) dispatch(validateFieldsAction())
          if (field?.vanishNextForms) {
            dispatch(syncFormDataWithFields({ path, index, mainIndex, glassGuideFieldAnswerChangedKey }))
          }
          if (field?.type !== "formula") {
            // field?.vanishNextForms && dispatch(removeFormData({ index, mainIndex, innerIndex }))
            setTimeout(() => {
              field?.vanishNextForms &&
                dispatch(
                  getVanishFormData({
                    formToShow,
                    fieldName,
                    value,
                    answer: field?.answer,
                    index,
                    mainIndex,
                    innerIndex,
                  }),
                )
            }, 500)
          }
          dispatch(syncFormDataWithFields({ path, index, mainIndex, glassGuideFieldAnswerChangedKey }))
        }}
        errorMessage={field?.errorMessage || ""}
        handleSearch={handleSearchBroker}
      />
    )
  }

  const dealId = (getParamValue("deal_id") && `Deal ID: ${getParamValue("deal_id")}`) || ""

  return (
    <>
      <Loader fromPathLoader={false} isLoading={brokerLoader} />
      <>
        <div className="header-container logo-login-button ">
          <div>
            <img src={logo} alt="Simply Finance" title="Simply Finance" />
          </div>
          <div></div>
          <div>{dealId}</div>
        </div>
      </>

      <div className="application-tool-form broker-app-form movetoSidebar">
        {formsToShow.map((formToShow, index) => {
          const fields = _.get(formData, formToShow.join(".")) || []

          let formLabel = ""
          const subformLabel = getLabel(formToShow)
          const mainLabelIndex = formsToShow.findIndex(x => formToShow[0] === x[0])
          if (mainLabelIndex === index) {
            formLabel = getLabel([formToShow[0]])
          }

          return (
            <div key={`${formToShow.join(".")}`} className="application-tool-form-content">
              <div className="application-tool-form-inner">
                {formLabel && <h1 className="application-form-heading gridcolchange">{formLabel}</h1>}
                {subformLabel && formLabel !== subformLabel && (
                  <h3 className="application-form-subheading">{subformLabel}</h3>
                )}
                {fields?.length > 0 &&
                  fields.map((field, mainIndex, self) => {
                    return (
                      <Fragment key={`${formToShow.join(".")}-${field?.key}`}>
                        {field.type === "repeater" ? (
                          <RepeaterForm
                            field={field}
                            isFromAssetPage={false}
                            errorMessage={""}
                            handleSearch={handleSearchBroker}
                            currentForm={formToShow}
                            onFieldUpdate={(index, innerIndex, field, value) => {
                              if (errorCounter > 0 || field?.errorMessage) dispatch(validateFieldsAction())
                              const path = `${formToShow.join(".")}[${mainIndex}].fields[${index}][${innerIndex}]`
                              dispatch(
                                setFormData({
                                  path,
                                  value: { ...field, answer: value },
                                }),
                              )
                            }}
                            onAdd={() => {
                              dispatch(repeaterFormAddAndRemove({ isAdd: true, index, mainIndex }))
                            }}
                            onRemove={removeIndex => {
                              dispatch(repeaterFormAddAndRemove({ isAdd: false, index, mainIndex, removeIndex }))
                            }}
                          />
                        ) : field?.fields?.length > 0 ? (
                          field?.fields?.map((innerField, indexInner, self) =>
                            renderField(formToShow, innerField, self, index, mainIndex, indexInner),
                          )
                        ) : (
                          renderField(formToShow, field, self, index, mainIndex)
                        )}
                      </Fragment>
                    )
                  })}
              </div>
            </div>
          )
        })}
        {isAbnModalOpen && (
          <ABNModalTable
            tableData={abnDataBroker}
            isModalOpen={isAbnModalOpen}
            isLoading={isAbnDataReceived}
            onClose={onCloseAbnModal}
            onRecordClick={e => handleABNOrACNSearchBroker(e?.abn, "ABNLookUp")}
          />
        )}
      </div>
      <div className="footer-container">
        <div className="showbtns">
          <Button
            isDisabled={false}
            buttonType="outlined-primary"
            className="error-button white-text"
            onClick={handleCancelClick}
          >
            Cancel
          </Button>
          {brokerLoaderNew && <div className="w-100 d-flex align-center just-center p-20 font-primary">Loading...</div>}
          <Button
            isDisabled={!lastQuestion || errorCounter > 0}
            buttonType="outlined-primary"
            onClick={() => {
              dispatch(validateFields())
              SetSavebtnClicked(true)
            }}
          >
            Save
          </Button>
        </div>
      </div>
      <BrokerOTPVerification />
      <BrokerEmailVerification />
    </>
  )
}
export default BrokerFormScreen
