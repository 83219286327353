export const REGEX = {
  EMAIL: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  // PHONE: /^\+?[0-9]{2}[0-9a-zA-Z]{7,9}$/,
  PHONE: /^\+\d{2}\s\d{4}-\d{4}/,
  ONLY_ALPHABETIC: /^[a-zA-Z]+$/,
  ONLY_ALPHABETIC_WITHSPACE: /^[a-zA-Z\s]+$/,
  NUMBER_REGEX: /^\d+$/,
  DECIMAL_REGEX: /^\d*(\.\d{0,2})?$/,
  PASSWORD_REGEX: /^(?=.*\d)(?=.*[!@#$%^&*+-])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
  DATE_FORMAT_DD_MM_YYYY: /[0-9]{2}\/[0-9]{2}\/[0-9]{4}/,
  REMOVE_SQUARE_BRACKED_AND_CONTENT_INBETWEEN: /\[.*?\]/,
}

export const usdConverter = number => {
  const numberToUSD = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
  return numberToUSD.format(number)
}
export const EQUIFAX_DEFAULT_SCORE = 700
export const DATE_FORMAT = {
  DDMMYYYY: "DD/MM/YYYY",
  MMDDYYYY: "MM/DD/YYYY",
  DMY: "DD-MM-YYYY",
  MDY: "MM-DD-YYYY",
  YMD: "YYYY-MM-DD",
}
