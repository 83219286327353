/* eslint-disable no-unused-vars */
import { cloneDeep, get, isBoolean, isEmpty, isEqual, isNumber, pick, set, uniqBy } from "lodash"
import moment from "moment"
import { VALIDATION_REGEX } from "../screens/application/ApplicationPage/ApplicationPageConstants"
import { DATE_FORMAT } from "../constants/regexConstants"

const EmailList = {}

export const isObject = value => {
  if (value && typeof value === "object" && value !== null && !Array.isArray(value)) {
    return true
  }
  return false
}
const searchEmails = (obj, results = []) => {
  const r = results
  if (obj)
    Object.keys(obj).forEach(key => {
      const value = obj[key]
      if ((Array.isArray(value) || typeof value === "object") && value?.type !== "email") {
        return searchEmails(value, r)
      } else if (value?.type === "email") {
        r.push(value?.answer)
      }
    })
  return r
}
export const checkEmailExistInOld = (answer, savedFormData, formsKey) => {
  delete savedFormData?.[formsKey?.["0"]]
  const emails = searchEmails(savedFormData, [])
  return emails?.length && emails?.includes(answer)
}
export const checkEmailExistInBroker = (answer, savedFormData, formsKey) => {
  delete savedFormData?.[formsKey?.["0"]]
  const emails = searchEmails(savedFormData, [])
  return emails.filter(x => x === answer).length
}
export const generateApplicationErrorMessage = (type, errorField) => {
  switch (type) {
    case "radio":
    case "button":
    case "dropdown":
    case "dropdownForGlassGuide":
    case "regoState":
      return `Please make a choice of ${errorField}`

    case "text":
    case "search":
    case "amount":
      return `Please enter valid answer for ${errorField}`

    case "file":
      return `Please upload file for ${errorField}`
    case "phone":
      return `Please enter valid ${errorField} to continue`
    case "email":
      return `Please enter valid ${errorField} to continue`
    case "date":
      return `Please select valid ${errorField} to continue`

    default:
      return `Please select/enter valid answer for ${errorField}`
  }
}

export const formatAnswerForKey = (value = "") => {
  if (typeof value === "string") {
    return value
      ?.toString()
      ?.replaceAll(" ", "")
      ?.replace(/[^a-zA-Z0-9 ]/g, "")
  } else {
    return value
  }
}

export const formPathDetailsAPIEncodeDecodeData = (isEncode, arr) => {
  if (isEncode) {
    const answers = {}
    for (const parentForm in arr) {
      if (arr[parentForm].length > 0) {
        answers[parentForm] = {}
        arr[parentForm].forEach(item => {
          if (item.type === "repeater" && item.fields && item.fields.length > 0) {
            item.fields.forEach((temp, index) => {
              temp.forEach(e => {
                set(answers, `${parentForm}.${item.fieldName}.[${index}].${e.fieldName}`, e.answer)
              })
            })
          } else answers[parentForm][item.fieldName] = item.answer
        })
      } else {
        answers[parentForm] = formPathDetailsAPIEncodeDecodeData(true, arr[parentForm])
      }
    }
    return answers
  } else {
    return ""
  }
}

export const prepareApplicationDataWithSubTypes = rawData => {
  const preparedData = { ...rawData }

  Object.entries(rawData).forEach(([key, value]) => {
    if (value?.type) {
      preparedData[key] = cloneDeep({
        ...value,
        ...rawData[value.type],
      })
      preparedData[key].label = value?.label
    }
  })

  return preparedData
}

export const handleApplicationValidations = (fields, savedFormData, formsKey) => {
  const errorAccumulator = {}
  fields?.forEach(field => {
    const { fieldName: parentFieldName } = field
    if (field?.type === "repeater") {
      field.fields.forEach((temp, index) => {
        temp.forEach(e => {
          const { required, answer, errorField, fieldName, type, minLimit, maxLimit, editable } = e
          const errorFieldName = errorField !== undefined ? errorField : fieldName
          if (required && !isValidAnswer(answer) && editable) {
            set(
              errorAccumulator,
              `[${parentFieldName}][${index}].${fieldName}`,
              generateApplicationErrorMessage(type, errorFieldName),
            )
          } else if (answer && minLimit && answer?.toString()?.trim()?.length < minLimit) {
            set(
              errorAccumulator,
              `[${parentFieldName}][${index}].${fieldName}`,
              `The minimum limit for ${errorFieldName} is ${minLimit} characters`,
            )
          } else if (answer && maxLimit && answer?.toString()?.replace(/ +/g, "")?.length > maxLimit) {
            set(
              errorAccumulator,
              `[${parentFieldName}][${index}].${fieldName}`,
              `The maximum limit for ${errorFieldName} is ${maxLimit} characters`,
            )
          } else if (["amount", "number"].includes(type) && editable && required && isNaN(answer)) {
            set(
              errorAccumulator,
              `[${parentFieldName}][${index}].${fieldName}`,
              `Please enter valid ${errorFieldName} to continue`,
            )
          } else if (field?.fieldName === "abn") {
            const answerVal = answer ? Number(answer?.toString()?.replace(/ +/g, "")) : 0
            if (isNaN(answerVal)) {
              set(
                errorAccumulator,
                `[${parentFieldName}][${index}].${fieldName}`,
                `${errorFieldName} field must contain valid numeric value`,
              )
            }
          } else if (answer && editable && ["phone", "email"].includes(type)) {
            let format = VALIDATION_REGEX.TEXT
            if (type === "phone") format = VALIDATION_REGEX.PHONE
            if (type === "email") format = VALIDATION_REGEX.EMAIL

            const isValid = format.test(answer)
            if (!isValid) {
              set(
                errorAccumulator,
                `[${parentFieldName}][${index}].${fieldName}`,
                `Please enter valid ${errorFieldName} to continue`,
              )
            }
            if (type === "email" && !["vendorEmail"].includes(fieldName)) {
              const checkExistInOld = checkEmailExistInOld(answer, cloneDeep(savedFormData), formsKey)
              if (checkExistInOld) {
                set(
                  errorAccumulator,
                  `[${parentFieldName}][${index}].${fieldName}`,
                  `Please enter new ${fieldName} to continue`,
                )
              }
            }
            if (type === "phone") {
              const secondDigit = String(answer)?.substring(1, 2)
              const fistDigit = String(answer)?.substring(0, 1)

              if (fistDigit === "0" && secondDigit === "0") {
                set(
                  errorAccumulator,
                  `[${parentFieldName}][${index}].${fieldName}`,
                  `Please enter valid ${errorFieldName} to continue`,
                )
              }
            }
          }
          if (answer && editable && ["date"].includes(type)) {
            const isValid = moment(answer, DATE_FORMAT.DDMMYYYY, true).isValid()
            if (!isValid) {
              set(
                errorAccumulator,
                `[${parentFieldName}][${index}].${fieldName}`,
                `Please enter valid ${errorFieldName} to continue`,
              )
            }
          }
          if (field?.type === "amount" && field?.fieldName !== "toBeFinanced" && required) {
            const answerVal = answer ? Number(answer) : 0
            if (answerVal === 0) {
              set(
                errorAccumulator,
                `[${parentFieldName}][${index}].${fieldName}`,
                `${errorFieldName} field must contain valid numeric value`,
              )
            }
          }
          const firstError = Object?.keys(errorAccumulator)?.[0]
          if (firstError !== undefined) {
            const elementWithFirstError = document?.querySelector(`.row${index} [name="${fieldName}"]`)
            if (elementWithFirstError) {
              elementWithFirstError?.scrollIntoView({
                behavior: "smooth",
                block: "center",
              })
            }
          }
        })
      })
    } else {
      const { required, answer, errorField, fieldName, type, minLimit, maxLimit, editable } = field
      const errorFieldName = errorField !== undefined ? errorField : fieldName
      if (required && !isValidAnswer(answer) && editable) {
        errorAccumulator[fieldName] = generateApplicationErrorMessage(type, errorFieldName)
      } else if (answer && minLimit && answer?.toString()?.trim()?.length < minLimit) {
        errorAccumulator[fieldName] = `The minimum limit for ${errorFieldName} is ${minLimit} characters`
      } else if (answer && maxLimit && answer?.toString()?.replace(/ +/g, "")?.length > maxLimit) {
        errorAccumulator[fieldName] = `The maximum limit for ${errorFieldName} is ${maxLimit} characters`
      } else if (["amount", "number"].includes(type) && editable && required && isNaN(answer)) {
        errorAccumulator[fieldName] = `Please enter valid ${errorFieldName} to continue`
      } else if (field?.fieldName === "abn") {
        const answerVal = answer ? Number(answer?.toString()?.replace(/ +/g, "")) : 0
        if (isNaN(answerVal)) {
          errorAccumulator[fieldName] = `${errorFieldName} field must contain valid numeric value`
        }
      } else if (field?.fieldName === "state" && answer) {
        const format = VALIDATION_REGEX.ALPHABETIC_WITH_SPACE
        const isValid = format.test(answer)
        if (!isValid) {
          errorAccumulator[fieldName] = `Please enter valid answer for ${errorFieldName}`
        }
      } else if (field?.fieldName === "city" && answer) {
        const format = VALIDATION_REGEX.ALPHABETIC_WITH_SPACE
        const isValid = format.test(answer)
        if (!isValid) {
          errorAccumulator[fieldName] = `Please enter valid answer for ${errorFieldName}`
        }
      } else if (field?.fieldName === "postcode" && answer) {
        const format = VALIDATION_REGEX.NUMBER_REGEX
        const isValid = format.test(answer)
        if (!isValid) {
          errorAccumulator[fieldName] = `Please enter valid answer for ${errorFieldName}`
        }
      } else if (field?.fieldName === "country" && answer) {
        const format = VALIDATION_REGEX.ONLY_ALPHABETIC
        const isValid = format.test(answer)
        if (!isValid) {
          errorAccumulator[fieldName] = `Please enter valid answer for ${errorFieldName}`
        }
      } else if (answer && editable && ["phone", "email"].includes(type)) {
        let format = VALIDATION_REGEX.TEXT
        if (type === "phone") format = VALIDATION_REGEX.PHONE
        if (type === "email") format = VALIDATION_REGEX.EMAIL

        const isValid = format.test(answer)

        if (!isValid) {
          errorAccumulator[fieldName] = `Please enter valid ${errorFieldName} to continue`
        }
        if (type === "email" && !["vendorEmail"].includes(fieldName)) {
          const checkExistInOld = checkEmailExistInOld(answer, cloneDeep(savedFormData), formsKey)
          if (checkExistInOld) {
            errorAccumulator[fieldName] = `Please enter new ${fieldName} to continue`
          }
        }
        if (type === "phone") {
          const secondDigit = String(answer)?.substring(1, 2)
          const fistDigit = String(answer)?.substring(0, 1)

          if (fistDigit === "0" && secondDigit === "0") {
            errorAccumulator[fieldName] = `Please enter valid ${errorFieldName} to continue`
          }
        }
      } else if (answer && editable && ["website"].includes(type)) {
        const isValid = urlPatternValidation(answer)

        if (!isValid) {
          errorAccumulator[fieldName] = `Please enter valid ${errorFieldName} to continue`
        }
      }
      if (answer && editable && ["date"].includes(type)) {
        const isValid = moment(answer, DATE_FORMAT.DDMMYYYY, true).isValid()

        if (!isValid) {
          errorAccumulator[fieldName] = `Please enter valid ${errorFieldName} to continue`
        }
      }
      if (required && (field?.fieldName === "firstName" || field?.fieldName === "lastName")) {
        const format = VALIDATION_REGEX.ONLY_ALPHABETIC
        const isValid = format.test(answer)
        if (!isValid) {
          errorAccumulator[fieldName] = `Please enter valid ${errorFieldName} to continue`
        }
      }
      if (field?.fieldName === "firstName" || field?.fieldName === "lastName") {
        const format = VALIDATION_REGEX.ONLY_ALPHABETIC
        const isVaid = format.test(answer)
        if (!isVaid) {
          errorAccumulator[fieldName] = `Please enter valid ${errorField} to continue`
        }
      }

      if (field?.type === "checkbox" && required && formsKey["0"] === "disclosure") {
        if (!answer) {
          errorAccumulator[fieldName] = `Please select/enter valid answer for ${errorFieldName}`
        }
      }

      if (field?.type === "amount" && field?.fieldName !== "toBeFinanced" && required) {
        const answerVal = answer ? Number(answer) : 0
        if (answerVal === 0) {
          errorAccumulator[fieldName] = `${errorFieldName} field must contain valid numeric value`
        }
      }
      const firstError = Object?.keys(errorAccumulator)?.[0]
      const elementWithFirstError = document?.querySelector(`[name="${firstError}"]`)
      if (elementWithFirstError) {
        elementWithFirstError?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }
      // if (field?.fieldName === "NETIncomeAndOutgoings") {
      //   const answerVal = answer ? Number(answer) : 0
      //   if (answerVal <= 0) {
      //     errorAccumulator[fieldName] = `${errorFieldName} field must contain valid numeric value`
      //   }
      // }
    }
  })

  return errorAccumulator
}
export const urlPatternValidation = URL => {
  // eslint-disable-next-line prefer-regex-literals
  const regex = new RegExp("(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?")
  return regex.test(URL)
}
export const checkNextQuestionCondition = field => {
  let result = true

  if (field?.conditions?.length) {
    field?.conditions?.forEach(({ condition, value }) => {
      switch (condition) {
        case "gte":
          result = result && parseFloat(field?.answer) >= parseFloat(value)
          break
        case "gt":
          result = result && parseFloat(field?.answer) > parseFloat(value)
          break
        case "lte":
          result = result && parseFloat(field?.answer) < parseFloat(value)
          break
        default:
          break
      }
    })
  }

  return result
}

export const checkFieldVisibilityCondition = (field, savedFormData, fields) => {
  let result = true
  let result1 = true
  if (field?.fieldVisibility?.length) {
    for (const fieldVisibility of field.fieldVisibility) {
      //  console.log("checkFieldVisibilityCondition",{fieldVisibility})
      if (fieldVisibility?.subFieldName) {
        for (let i = 0; i < savedFormData?.applicantDetails1?.incomeAndOutgoings?.[0]?.fields?.length; i++) {
          if (
            savedFormData?.applicantDetails1?.incomeAndOutgoings?.[0]?.fields?.[i][0]?.answer
              .toString()
              ?.toLowerCase() === fieldVisibility.value.toString()?.toLowerCase()
          ) {
            return true
          }
        }
        for (let i = 0; i < savedFormData?.applicantDetails2?.incomeAndOutgoings?.[0]?.fields?.length; i++) {
          if (
            savedFormData?.applicantDetails2?.incomeAndOutgoings?.[0]?.fields?.[i][0]?.answer === fieldVisibility?.value
          ) {
            return true
          }
        }
        for (let i = 0; i < savedFormData?.gurantor1?.incomeAndOutgoings?.[0]?.fields?.length; i++) {
          if (
            savedFormData?.gurantor1?.incomeAndOutgoings?.[0]?.fields?.[i][0]?.answer.toString()?.toLowerCase() ===
            fieldVisibility.value.toString()?.toLowerCase()
          ) {
            return true
          }
        }
        for (let i = 0; i < savedFormData?.gurantor2?.incomeAndOutgoings?.[0]?.fields?.length; i++) {
          if (
            savedFormData?.gurantor2?.incomeAndOutgoings?.[0]?.fields?.[i][0]?.answer.toString()?.toLowerCase() ===
            fieldVisibility.value.toString()?.toLowerCase()
          ) {
            return true
          }
        }
        for (let i = 0; i < savedFormData?.gurantor3?.incomeAndOutgoings?.[0]?.fields?.length; i++) {
          if (
            savedFormData?.gurantor3?.incomeAndOutgoings?.[0]?.fields?.[i][0]?.answer.toString()?.toLowerCase() ===
            fieldVisibility.value.toString()?.toLowerCase()
          ) {
            return true
          }
        }
        for (let i = 0; i < savedFormData?.gurantor4?.incomeAndOutgoings?.[0]?.fields?.length; i++) {
          if (
            savedFormData?.gurantor4?.incomeAndOutgoings?.[0]?.fields?.[i][0]?.answer.toString()?.toLowerCase() ===
            fieldVisibility.value.toString()?.toLowerCase()
          ) {
            return true
          }
        }
        for (let i = 0; i < savedFormData?.gurantor5?.incomeAndOutgoings?.[0]?.fields?.length; i++) {
          if (
            savedFormData?.gurantor5?.incomeAndOutgoings?.[0]?.fields?.[i][0]?.answer.toString()?.toLowerCase() ===
            fieldVisibility.value.toString()?.toLowerCase()
          ) {
            return true
          }
        }
        for (let i = 0; i < savedFormData?.gurantor6?.incomeAndOutgoings?.[0]?.fields?.length; i++) {
          if (
            savedFormData?.gurantor6?.incomeAndOutgoings?.[0]?.fields?.[i][0]?.answer.toString()?.toLowerCase() ===
            fieldVisibility.value.toString()?.toLowerCase()
          ) {
            return true
          }
        }
        for (let i = 0; i < savedFormData?.gurantor7?.incomeAndOutgoings?.[0]?.fields?.length; i++) {
          if (
            savedFormData?.gurantor7?.incomeAndOutgoings?.[0]?.fields?.[i][0]?.answer.toString()?.toLowerCase() ===
            fieldVisibility.value.toString()?.toLowerCase()
          ) {
            return true
          }
        }
        for (let i = 0; i < savedFormData?.gurantor8?.incomeAndOutgoings?.[0]?.fields?.length; i++) {
          if (
            savedFormData?.gurantor8?.incomeAndOutgoings?.[0]?.fields?.[i][0]?.answer.toString()?.toLowerCase() ===
            fieldVisibility.value.toString()?.toLowerCase()
          ) {
            return true
          }
        }
      }
      if (fieldVisibility?.formName && fieldVisibility?.fieldName && fieldVisibility?.value) {
        const path = fieldVisibility?.subFormName
          ? `${fieldVisibility.formName}.${fieldVisibility.subFormName}`
          : `${fieldVisibility.formName}`
        const fieldToSearch = getCloneDeep(savedFormData, path)
        //  console.log( "checkFieldVisibilityCondition", { fieldToSearch,path } )

        if (fieldToSearch?.length > 0) {
          const foundField = fieldToSearch.find(y => y?.fieldName === fieldVisibility?.fieldName)

          if (fieldVisibility.fieldName === "registeredFrom") {
            const date1 = new Date()
            const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
            const date2 = foundField?.answer?.split("/")
            const utc2 = Date.UTC(date2?.["2"], date2?.["1"] - 1, date2?.["0"])
            const diffTime = Math.abs(utc2 - utc1)

            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
            if (fieldVisibility.condition) {
              switch (fieldVisibility.condition) {
                case "gte1":
                  if (diffDays >= 365) {
                    return true
                  }
                  break
                case "gte2":
                  if (diffDays >= 730) {
                    return true
                  }
                  break
                case "gt1":
                  if (diffDays > 365) {
                    return true
                  }
                  break
                case "gt2":
                  if (diffDays > 730) {
                    return true
                  }
                  break
                default:
                  return true
              }
            }
          }
          //    console.log( "checkFieldVisibilityCondition", { foundField} )
          if (foundField?.answer) {
            result = foundField?.answer?.toString()?.toLowerCase() === fieldVisibility?.value?.toString()?.toLowerCase()
            result1 = foundField?.answer && fieldVisibility?.value
            //    console.log( "checkFieldVisibilityCondition", { result} )
            if (result) {
              return result
            }
            if (result1) {
              switch (fieldVisibility.condition) {
                case "gte":
                  if (foundField?.answer) {
                    result1 = parseFloat(foundField?.answer) >= parseFloat(fieldVisibility?.value)
                    if (result1) {
                      return result1
                    }
                  } else {
                    result1 = false
                  }
                  break
                default:
                  break
              }
            }
          } else result = false
        } else result = false
      } else if (fieldVisibility?.fieldName && fieldVisibility?.value && fieldVisibility?.condition) {
        const fieldToSearch = fields.find(x => x?.fieldName === fieldVisibility?.fieldName)
        switch (fieldVisibility.condition) {
          case "gte":
            if (fieldToSearch?.answer) {
              result = parseFloat(fieldToSearch?.answer) >= parseFloat(fieldVisibility?.value)
              if (result) {
                return result
              }
            } else {
              result = false
            }
            break
          case "lte":
            if (fieldToSearch?.answer) {
              result = parseFloat(fieldToSearch?.answer) < parseFloat(fieldVisibility?.value)
              if (result) {
                return result
              }
            } else {
              result = false
            }
            break
          case "gt":
            if (fieldToSearch?.answer) {
              result = parseFloat(fieldToSearch?.answer) > parseFloat(fieldVisibility?.value)
              if (result) {
                return result
              }
            } else {
              result = false
            }
            break
          default:
            break
        }
      }
    }
  }
  return result
}

export const handleFieldVisibilityOfFieldInCurrentForm = (field, currentForm) => {
  let result = true
  if (field?.fieldVisibility?.length) {
    field?.fieldVisibility?.forEach(({ fieldName, condition, value }) => {
      if (currentForm?.length) {
        const fieldToCheck = currentForm?.find(data => data?.fieldName === fieldName)
        if (fieldToCheck && condition) {
          switch (condition) {
            case "gte":
              result = result && parseFloat(fieldToCheck?.answer) >= parseFloat(value)
              break
            case "gt":
              result = result && parseFloat(fieldToCheck?.answer) > parseFloat(value)
              break
            case "lte":
              result = result && parseFloat(fieldToCheck?.answer) < parseFloat(value)
              break
            default:
              break
          }
        }
      }
    })
  }

  return result
}

export const prepareApplicationDataForActiveSave = data => {
  let objectToSave = {}

  objectToSave = Object.entries(data).reduce((preparedObject, [key, value]) => {
    /**
     * Possibilities
     * array -> fieldName, answer, fields
     * object -> obejct -> fieldName, answer, fields
     * fields -> fieldName, answer, fields
     */

    const objectWithModification = {
      ...preparedObject,
    }
    const preaparedKeys = processApplicationDataFieldsForActiveSave(value)

    if (key && !isEmpty(preaparedKeys)) {
      objectWithModification[key] = { ...preaparedKeys, isCompleted: true, isBlocked: false, formName: key }
    }

    return objectWithModification
  }, {})
  return objectToSave
}

export const processApplicationDataFieldsForActiveSave = value => {
  let preparedObject = {}

  if (Array.isArray(value)) {
    preparedObject = value?.reduce((field, currentField) => {
      let finalObjectWithAllNestedFields = { ...field }
      const processedNestedFields = processApplicationDataFieldsForActiveSave(currentField)

      if (processedNestedFields && !isEmpty(processedNestedFields)) {
        if (currentField?.fieldName && currentField?.fields) {
          finalObjectWithAllNestedFields = {
            ...finalObjectWithAllNestedFields,
            [currentField?.fieldName]: processedNestedFields,
          }
        } else {
          finalObjectWithAllNestedFields = { ...finalObjectWithAllNestedFields, ...processedNestedFields }
        }
      }
      return finalObjectWithAllNestedFields
    }, {})
  } else if (isObject(value)) {
    if (value?.fields) {
      if (value?.type !== "repeater") {
        preparedObject = processApplicationDataFieldsForActiveSave(value?.fields)
      } else {
        preparedObject = value?.fields?.map(field => {
          return processApplicationDataFieldsForActiveSave(field)
        })
      }
    } else if (value?.fieldName && isValidAnswer(value?.answer)) {
      preparedObject = {
        ...preparedObject,
        [value?.fieldName]: value?.answer,
      }
    } else if (!value?.fieldName) {
      preparedObject = prepareApplicationDataForActiveSave(value)
    }
  }

  return preparedObject
}

export const clean = function(object) {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === "object") clean(v)
    if ((v && typeof v === "object" && !Object.keys(v).length) || v === null || v === undefined || v.length === 0) {
      if (Array.isArray(object)) object.splice(k, 1)
      else if (!(v instanceof Date)) delete object[k]
    }
    if (Array.isArray(v)) {
      const filteredV = v.filter(x => x)
      if (v.length !== filteredV.length && filteredV.length === 1) {
        object[k] = filteredV[0]
      }
    }
  })
  return object
}

export const findKeyInsideFields = (data, keyToFind) => {
  for (const [k, v] of Object.entries(data)) {
    if (Array.isArray(v) || isObject(v)) {
      return findKeyInsideFields(v, keyToFind)
    } else if (k?.toLowerCase() === keyToFind?.toLowerCase()) {
      return v
    }
  }
}
export const restructureAllViewAPIData = (data, key, applicationJsonData) => {
  const flatMap = {}
  data.forEach((item, index) => {
    for (const subKey in item) {
      for (const subNestedKey in item[subKey]) {
        if (Array.isArray(item[subKey][subNestedKey])) {
          const oldItem = JSON.parse(JSON.stringify(item[subKey]))
          item[subKey] = { ...oldItem, ...restructureAllViewAPIData(item[subKey][subNestedKey], subNestedKey) }
          delete item[subKey][subNestedKey]
        }
      }
      if (subKey === "customerDetails" || subKey === "companyAddressDetails") {
        flatMap[key + "_" + index + "_" + subKey] = {
          ...item[subKey],
          mainTitle: applicationJsonData?.[item?.formName?.Answer]?.label,
        }
      } else {
        flatMap[key + "_" + index + "_" + subKey] = { ...item[subKey] }
      }
    }
  })
  return flatMap
}

export const getNextQuestionsFromAnswer = (nextQuestions, answer = "") => {
  if (!answer) return undefined
  if (Array.isArray(nextQuestions)) {
    return nextQuestions
  }
  if (isObject(nextQuestions) && "default" in nextQuestions) {
    if (answer && Array.isArray(answer) && answer?.length > 0) {
      return nextQuestions.default
    } else if (answer && answer?.toLowerCase() !== "other") {
      return nextQuestions.default
    }
  }
  if (nextQuestions && formatAnswerForKey(answer || "") in nextQuestions) {
    return nextQuestions?.[formatAnswerForKey(answer || "")]
  }
  return undefined
}

export const getDefaultQuestions = defaultQuestions => {
  if (Array.isArray(defaultQuestions)) {
    return defaultQuestions
  }
  return undefined
}

export const isKeyInsideNextQuestions = (nextQuestions, key) => {
  if (Array.isArray(nextQuestions) && nextQuestions?.length > 0 && key) {
    return nextQuestions?.findIndex(x => x?.toLowerCase() === key?.toLowerCase()) > -1
  }
  return false
}

const sortByKey = fields => {
  const compare = (x, y) => {
    const a = parseInt(x?.key?.replace("Q", ""))
    const b = parseInt(y?.key?.replace("Q", ""))
    if (a < b) {
      return -1
    } else if (a > b) {
      return 1
    } else {
      return 0
    }
  }
  if (fields?.length > 0) return fields.sort(compare)
  return fields
}

export const sortAndUniqFieldsToShow = fieldsToShow => {
  if (fieldsToShow?.length > 0 && fieldsToShow?.[0]?.length > 0 && fieldsToShow?.[0]?.[0]?.key) {
    return cloneDeep(
      fieldsToShow.reduce((acc, x) => {
        acc.push(sortByKey(uniqBy(x, "key")))
        return acc
      }, []),
    )
  }
  return cloneDeep(fieldsToShow)
}

export const addEditWarningForInitialData = (data, path = []) => {
  return Object.entries(data)
    .reduce((acc, [key, value]) => {
      if ((Array.isArray(value) || isObject(value)) && !isValidAnswer(value?.answer)) {
        const keyToPush = isNaN(key) ? `${path.length > 0 ? "." : ""}${key}` : `[${key}]`
        acc.push(addEditWarningForInitialData(value, path?.length > 0 ? [...path, keyToPush] : [keyToPush]))
      } else {
        if (isValidAnswer(value?.answer)) {
          acc.push({ path: path.join(""), fieldKey: value?.key })
        }
      }
      return acc
    }, [])
    .flat(Infinity)
}

// So that in active save api call it don't send prefilled data
// if prefilled data have some required field then activeSave will give error
export const filterForActiveSave = (applicationData, navigationHistory) => {
  if (applicationData && navigationHistory?.length > 0) {
    if (navigationHistory?.length > 0) {
      const pathsToKeep = navigationHistory
        .reduce((acc, nHistory) => {
          const path = []
          if (nHistory?.formsKey?.length > 0) {
            nHistory.formsKey.forEach(form => {
              if (form) path.push(form)
            })
          }
          acc.push(path)
          return acc
        }, [])
        .flat()
      return pick(applicationData, pathsToKeep)
    }
  }
  return applicationData
}

export const copyFieldAnswer = (isAdd, fields, fromKey, toKey) => {
  const copyFromIndex = fields?.findIndex(x => x?.key === fromKey)
  const copyToIndex = fields?.findIndex(x => x?.key === toKey)
  if (copyFromIndex > -1 && copyToIndex > -1) {
    if (fields[copyFromIndex]?.answer) {
      if (isAdd) fields[copyToIndex].answer = fields[copyFromIndex]?.answer
      else fields[copyFromIndex].answer = ""
    } else if (fields[copyFromIndex]?.fields?.length > 0 && fields[copyToIndex]?.fields?.length > 0) {
      fields[copyFromIndex].fields.forEach(field => {
        const copyToFieldIndex = fields[copyToIndex].fields.findIndex(x => x?.fieldName === field?.fieldName)
        if (copyToFieldIndex > -1) {
          if (isAdd) fields[copyToIndex].fields[copyToFieldIndex].answer = field.answer
          else fields[copyToIndex].fields[copyToFieldIndex].answer = ""
        }
      })
    }
  }
  return fields
}
export const compare = (v1, v2) => {
  return isEqual(v1, v2)
}

export const areFieldsCorrect = (subFormKey, fields) => {
  switch (subFormKey) {
    case "incomeAndOutgoings":
      return fields?.find?.(x => x?.fieldName === "income")
    case "assetsAndLiabilities":
      return fields?.find?.(x => x?.fieldName === "assets")
    case "companyCommitment":
      return fields?.find?.(x => x?.fieldName === "companyCommitmentSchedule")
    default:
      return true
  }
}

export const uniqFields = fields => {
  return fields.reduce((acc, field, i, self) => {
    const alreadyhaveCurrentField = acc.find(x => x?.key === field?.key)
    const duplicateField = self.filter(x => x?.key === field?.key)
    if (duplicateField?.length > 1) {
      const fieldToKeep = duplicateField.find(x => isValidAnswer(x?.answer))
      if (fieldToKeep) {
        if (!acc.find(x => x?.key === fieldToKeep?.key)) {
          acc.push(fieldToKeep)
        }
      } else {
        if (!alreadyhaveCurrentField) acc.push(field)
      }
    } else {
      if (!alreadyhaveCurrentField) acc.push(field)
    }
    return acc
  }, [])
}
export const isValidAnswer = answer => {
  return answer || isBoolean(answer) || isNumber(answer) || answer === 0
}
export const isAnswer = answer => {
  return answer || isBoolean(answer) || isNumber(answer) || answer === "" || answer === 0
}
export const getCloneDeep = (...rest) => {
  return cloneDeep(get(...rest))
}

// case insensitive string compare
export const stringCompare = (str1, str2) => str1?.toString?.()?.toLowerCase?.() === str2?.toString?.()?.toLowerCase?.()
