import _ from "lodash"
import Input from "../../../common/Input/Input"
import { useDispatch, useSelector } from "react-redux"
import { equifaxSaveData, handleEquifaxFieldChange, setAddressData } from "./redux/EquifaxActions"
import { onlyAlphabets, validateEmail, validatePhone } from "../../../helpers/ValidationHelper"
import ReactGoogleAutocomplete from "../../../common/ReactGoogleAutocomplete/ReactGoogleAutocomplete"
import Button from "../../../common/Button/Button"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import Footer from "../../../common/Footer/Footer"

import Loader from "../../../common/loader/Loader"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import CountrySelect from "../../../common/CountrySelect/CountrySelect"
import { registerUser } from "../RegistrationPage/redux/RegisterActions"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"

import VerificationCode from "../../../common/Header/components/VerificationCode"
import DateRangePicker from "react-bootstrap-daterangepicker"
import "bootstrap-daterangepicker/daterangepicker.css"
import { DATE_FORMAT, EQUIFAX_DEFAULT_SCORE } from "../../../constants/regexConstants"
import { handleNextClick } from "../../../helpers/QuoteValidationHelper"
import logo from "../../../assets/images/logo.svg"
import moment from "moment"

const EquiFaxScreen = () => {
  dayjs.extend(duration)

  const dispatch = useDispatch()
  const {
    firstName,
    lastName,
    middleName,
    streetAddress,
    suburb,
    state,
    stateShort,
    bdate,
    postCode,
    errors,
    streetNumber,
    streetName,
    country,
    emailAddress,
    mobileNumber,
    countryName,
    isRegistered,
    isFormShow,
    isMobileVerified,
    isMobVerificationModal,
    registrationButtonDisable,
    isEquifaxFailed,
  } = useSelector(({ equifaxReducer }) => equifaxReducer ?? {})
  let { equifaxData } = useSelector(({ equifaxReducer }) => equifaxReducer ?? {})
  const { _id: userId } = useSelector(({ headerReducer }) => headerReducer?.userProfile ?? {})
  const {
    quoteId,
    currentQuoteData,
    quoteAnswersData,
    glassGuideData,
    glassGuideLabel,
    previousQuestions,
    dropDownOptions,
    currentForm,
    currentQuestion,
    quoteJsonData,
  } = useSelector(({ quoteReducer }) => quoteReducer ?? {})

  const { fieldName, nextQuestions, defaultQuestions, otherField, type, checkDefaultForNext, fields, isLastQuestion } =
    currentQuoteData ?? {}

  const { isEquifaxDataSave } = useSelector(({ loaderReducer }) => loaderReducer ?? {})
  const history = useHistory()
  const { isRedirectedFromQuote, isQuoteSubmit } = history?.location?.state ?? {}
  const [checkboxClicked, setCheckboxClicked] = useState(false)
  const loanType = quoteAnswersData?.loanType?.financeWhat

  let equifaxDataFinal = { data: { ...equifaxData?.data, isDefaultScore: false } }
  if (equifaxData?.data?.creditScore === undefined) {
    equifaxDataFinal = {
      data: {
        firstName: firstName,
        lastName: lastName,
        creditScore: EQUIFAX_DEFAULT_SCORE,
        isDefaultScore: true,
      },
    }
  }

  let financeNumberVal = 10000
  if (loanType === "Asset Finance") {
    financeNumberVal = quoteAnswersData?.financeNumbers?.toBeFinanced
  } else if (loanType !== "Insurance" && loanType !== "Warranty" && loanType !== "Other") {
    financeNumberVal = quoteAnswersData?.financeNumbers?.howMuchDoYouWantToBorrow
  } else {
    financeNumberVal = 10000
  }
  financeNumberVal = Math.round(financeNumberVal)
  const handleFieldChange = e => {
    const { name, value } = e?.target
    dispatch(handleEquifaxFieldChange(name, value.trim()))
  }
  const handleFieldChangeSpecific = (name, value) => {
    dispatch(handleEquifaxFieldChange(name, value))
  }

  const handlePopUp = () => {
    dispatch(handleEquifaxFieldChange("isMobVerificationModal", !isMobVerificationModal))
    dispatch(handleEquifaxFieldChange("registrationButtonDisable", false))
    dispatch(handleEquifaxFieldChange("isRegistered", false))
  }
  const userDetail = useSelector(({ headerReducer }) => headerReducer?.userProfile ?? {})
  const handleLogInButtonClick = () => {
    history.push(ROUTE_CONSTANTS_VARIABLE.LOGIN)
  }
  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)
  const handleRegistration = async () => {
    const equifaxErrors = {}
    const format = /[`!@#$%^&*()_+\-={};':"|,.<>?~]/
    if (!firstName || !firstName.length) equifaxErrors.firstName = "Please enter first name"
    if (firstName && !onlyAlphabets(firstName)) equifaxErrors.firstName = "Please enter valid first name"
    if (firstName && format.test(firstName)) equifaxErrors.firstName = "Please enter valid first name"
    if (!lastName || !lastName.length) equifaxErrors.lastName = "Please enter last name"
    if (lastName && !onlyAlphabets(lastName)) equifaxErrors.lastName = "Please enter valid last name"
    if (lastName && format.test(lastName)) equifaxErrors.lastName = "Please enter valid last name"
    if (!emailAddress) equifaxErrors.emailAddress = "Please enter email address"
    if (emailAddress && !validateEmail(emailAddress)) equifaxErrors.emailAddress = "Please enter valid email address"
    if (!countryName || !countryName?.label?.toString()?.trim()?.length)
      equifaxErrors.mobileNumber = "Please select country code"
    if (!mobileNumber || !mobileNumber?.length) equifaxErrors.mobileNumber = "Please enter mobile number"
    if (mobileNumber && !validatePhone(mobileNumber)) equifaxErrors.mobileNumber = "Please enter valid mobile number"

    if (_.isEmpty(equifaxErrors) && !registrationButtonDisable) {
      dispatch(handleEquifaxFieldChange("registrationButtonDisable", !registrationButtonDisable))
      const data = {
        firstName,
        lastName,
        email: emailAddress ? emailAddress.toLowerCase().trim() : undefined,
        countryCode: countryName?.value?.code,
        mobileNumber,
        isAllowTermsCondition: true,
        isAllowMessages: true,
        setDefaultPassword: true,
      }
      const isRegistered = await dispatch(registerUser(data, history, isRedirectedFromQuote, isQuoteSubmit, true))
      if (isRegistered) {
        dispatch(handleEquifaxFieldChange("isRegistered", true))
        dispatch(handleEquifaxFieldChange("isMobVerificationModal", !isMobVerificationModal))
        setCounter(300)
      }
      if (!isRegistered) {
        dispatch(handleEquifaxFieldChange("registrationButtonDisable", false))
      }

      dispatch(handleEquifaxFieldChange("errors", equifaxErrors))
    } else {
      dispatch(handleEquifaxFieldChange("errors", equifaxErrors))
      dispatch(handleEquifaxFieldChange("registrationButtonDisable", false))
    }
  }
  /* OTP Functions */
  const [counter, setCounter] = useState(localStorage.getItem("otpCount") || 300)

  useEffect(() => {
    if (isRegistered) {
      localStorage.setItem("otpCount", counter)
    }
  }, [counter, isRegistered])

  useEffect(() => {
    const size = Object.keys(quoteAnswersData).length
    if (size === 0) {
      if (authStatus) {
        history.push(ROUTE_CONSTANTS_VARIABLE.AUTH_QUOTE)
      } else {
        history.push(ROUTE_CONSTANTS_VARIABLE.UN_AUTH_QUOTE)
      }
    }
  }, [quoteAnswersData])

  /* OTP Functions */
  const handleApply = (event, picker) => {
    dispatch(handleEquifaxFieldChange("bdate", picker.startDate.format(DATE_FORMAT.DDMMYYYY)))
  }
  const handleEqButtonClick = async (onlyvalidation = false) => {
    const equifaxErrors = {}

    const format = /[`!@#$%^&*()_+\-={};':"|,.<>?~]/

    if (!firstName || !firstName.length) equifaxErrors.firstName = "Please enter first name"
    if (firstName && !onlyAlphabets(firstName)) equifaxErrors.firstName = "Please enter valid first name"
    if (firstName && format.test(firstName)) equifaxErrors.firstName = "Please enter valid first name"
    if (!lastName || !lastName.length) equifaxErrors.lastName = "Please enter last name"
    if (lastName && !onlyAlphabets(lastName)) equifaxErrors.lastName = "Please enter valid last name"
    if (lastName && format.test(lastName)) equifaxErrors.lastName = "Please enter valid last name"
    if (lastName && format.test(lastName)) equifaxErrors.lastName = "Please enter valid last name"
    if (lastName && format.test(lastName)) equifaxErrors.lastName = "Please enter valid last name"
    if (!checkboxClicked) equifaxErrors.checkboxclicked = "Please select Terms & Conditions"

    if (_.isEmpty(equifaxErrors)) {
      dispatch(handleEquifaxFieldChange("errors", {}))
      let equifaxResponse = true
      if (
        bdate !== "" &&
        lastName !== "" &&
        firstName !== "" &&
        streetNumber !== "" &&
        streetName !== "" &&
        suburb !== "" &&
        stateShort !== "" &&
        postCode !== "" &&
        country !== ""
      ) {
        const newdate = bdate
          .split("/")
          .reverse()
          .join("-")

        if (!onlyvalidation) {
          equifaxResponse = false
          const data = {
            lastName: lastName.trim(),
            firstName: firstName.trim(),
            middleName: middleName.trim(),
            dateOfBirth: newdate,
            gender: "U",
            streetNumber: streetNumber.trim(),
            streetName: streetName.trim(),
            suburb: suburb.trim(),
            state: stateShort.trim(),
            postCode: postCode.trim(),
            country: country.trim(),
            toBeFinanced: financeNumberVal,
          }
          try {
            const equifaxResponse1 = await dispatch(equifaxSaveData(data))
            if (equifaxResponse1 !== false) {
              const responseFromequifax = equifaxResponse1.split("_test_")
              if (JSON.parse(responseFromequifax["1"])?.data?.creditScore !== undefined) {
                equifaxDataFinal = {
                  data: {
                    ...JSON.parse(responseFromequifax["1"])?.data,
                    isDefaultScore: false,
                  },
                }
                equifaxData = JSON.parse(responseFromequifax["1"])
              } else {
                equifaxDataFinal = {
                  data: {
                    firstName: firstName,
                    lastName: lastName,
                    creditScore: EQUIFAX_DEFAULT_SCORE,
                    isDefaultScore: true,
                  },
                }
              }
              equifaxResponse = true
            } else {
              equifaxResponse = equifaxResponse1
            }
          } catch (e) {
            //
          }
        }
      }
      if (equifaxResponse && checkboxClicked) {
        await handleNextClick(
          true,
          true,
          currentQuoteData,
          quoteAnswersData,
          dropDownOptions,
          currentForm,
          equifaxDataFinal,
          equifaxData,
          currentQuestion,
          authStatus,
          userId,
          quoteId,
          glassGuideData,
          glassGuideLabel,
          previousQuestions,
          isLastQuestion,
          true,
          nextQuestions,
          defaultQuestions,
          otherField,
          type,
          checkDefaultForNext,
          fields,
          fieldName,
          history,
          true,
          quoteJsonData,
          isEquifaxFailed,
          isFormShow,
          dispatch,
          false,
          true,
        )
      }
    } else {
      dispatch(handleEquifaxFieldChange("errors", equifaxErrors))
    }
  }

  const onEnterKeyPress = async e => {
    if (e.keyCode === 13) {
      // await handleEqButtonClick(true)
    }
  }
  const dateCheck = (from, to, check) => {
    const fDate = Date.parse(from)
    const lDate = Date.parse(to)
    const cDate = Date.parse(check)
    if (cDate <= lDate && cDate >= fDate) {
      return true
    }
    return false
  }
  const d = new Date()
  d.setFullYear(d.getFullYear() - 18)
  const minYear = d.getFullYear() - 80
  return (
    <>
      {!authStatus && (
        <div className="header-container logo-login-button">
          <img src={logo} alt="Simply Finance" title="Simply Finance" />
          <Button buttonType="primary" onClick={handleLogInButtonClick}>
            Login
          </Button>
        </div>
      )}
      {isFormShow && (
        <div>
          <div className="equifax-container-register">
            <div className="two-inputs-row">
              <div>
                <Input
                  type="text"
                  placeholder="First Name*"
                  name="firstName"
                  value={firstName.trim()}
                  onChange={handleFieldChange}
                  onKeyDown={onEnterKeyPress}
                  error={errors.firstName}
                />
              </div>
              <div>
                <Input
                  type="text"
                  placeholder="Last Name*"
                  name="lastName"
                  value={lastName.trim()}
                  onChange={handleFieldChange}
                  onKeyDown={onEnterKeyPress}
                  error={errors.lastName}
                />
              </div>
            </div>
            <div className="two-inputs-row">
              <div>
                <Input
                  type="email"
                  placeholder="Email address *"
                  name="emailAddress"
                  value={emailAddress}
                  disabled={isRegistered || authStatus}
                  onChange={handleFieldChange}
                  onKeyDown={onEnterKeyPress}
                  error={errors.emailAddress}
                />
              </div>
              <div>
                <CountrySelect
                  countryCode={userDetail?.countryCode ? userDetail?.countryCode : ""}
                  countryName={countryName}
                  phoneNumber={userDetail?.mobileNumber ? userDetail?.mobileNumber?.trim() : mobileNumber?.trim()}
                  placeholder="Mobile number*"
                  disabled={isRegistered || authStatus}
                  className="w-100"
                  updatePhoneNumber={e => {
                    handleFieldChangeSpecific("mobileNumber", e?.toString().trim())
                  }}
                  updatePhoneCountry={e => {
                    handleFieldChangeSpecific("countryName", e)
                  }}
                  error={errors?.mobileNumber}
                />
              </div>
            </div>
            <Button
              buttonType="secondary"
              isDisabled={isRegistered || authStatus || registrationButtonDisable}
              title="Get Verification Code"
              className="form-button"
              onClick={() => handleRegistration()}
            />
          </div>

          {(isMobileVerified || authStatus) && (
            <div className="equifax-container">
              <div className="pad4 align-center">{`Provide as much information below to proceed. All fields are optional, but allow us to provide the most accurate quote.`}</div>
              <div className="two-inputs-row">
                <div>
                  <DateRangePicker
                    initialSettings={{
                      autoUpdateInput: false,
                      locale: {
                        format: DATE_FORMAT.DDMMYYYY,
                        cancelLabel: "Clear",
                      },
                      showDropdowns: true,
                      singleDatePicker: true,
                      autoApply: true,
                      minYear: minYear,
                      startDate: new Date("01/01/" + minYear),
                      maxDate: d,
                      cancelButtonClasses: "button outlined-primary-button",
                      applyButtonClasses: "button outlined-primary-button",
                    }}
                    onApply={handleApply}
                  >
                    <Input
                      placeholder="Date of Birth"
                      value={bdate}
                      onChange={e => {
                        const value = e.target.value
                        handleFieldChangeSpecific("bdate", value)
                      }}
                      onBlur={e => {
                        const maxD = dayjs(d).format(DATE_FORMAT.YMD)
                        const value = e.target.value

                        const isValid = moment(value, DATE_FORMAT.DDMMYYYY, true).isValid()

                        if (isValid) {
                          const dateValue = value
                            .split("/")
                            .reverse()
                            .join("-")

                          const dateValid = dateCheck(minYear + "-01-01", maxD, dateValue)
                          if (dateValid) {
                            handleFieldChangeSpecific("bdate", value)
                          } else {
                            handleFieldChangeSpecific("bdate", "")
                          }
                        } else {
                          handleFieldChangeSpecific("bdate", "")
                        }
                      }}
                    />
                  </DateRangePicker>
                </div>
                <div>
                  <Input
                    type="text"
                    placeholder="Middle Name"
                    name="middleName"
                    value={middleName}
                    onChange={handleFieldChange}
                    onKeyDown={onEnterKeyPress}
                    error={errors.middleName}
                  />
                </div>
              </div>
              <div className="two-inputs-row">
                <div>
                  <ReactGoogleAutocomplete
                    // value={location}
                    placeholder="Search location here"
                    setFinalAddress={e => {
                      dispatch(setAddressData(e))
                    }}
                    error={errors.location}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    placeholder="Street Address"
                    name="streetAddress"
                    value={streetAddress}
                    onChange={handleFieldChange}
                    onKeyDown={onEnterKeyPress}
                    error={errors.streetAddress}
                  />
                </div>
              </div>
              <div className="three-inputs-row">
                <div>
                  <Input
                    type="text"
                    placeholder="Suburb"
                    name="suburb"
                    value={suburb}
                    onChange={handleFieldChange}
                    onKeyDown={onEnterKeyPress}
                    error={errors.suburb}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    placeholder="State"
                    name="state"
                    value={state}
                    onChange={handleFieldChange}
                    onKeyDown={onEnterKeyPress}
                    error={errors.state}
                  />
                </div>
                <div>
                  <Input
                    type="text"
                    placeholder="Post Code"
                    name="postCode"
                    value={postCode}
                    onChange={handleFieldChange}
                    onKeyDown={onEnterKeyPress}
                    error={errors.postCode}
                  />
                </div>
              </div>
              <>
                <div className={"d-flex"} onClick={e => e.stopPropagation()}>
                  <label className="checkbox-container-equifax align-left" style={{ paddingLeft: "25px" }}>
                    By ticking this box, I confirm that I have read, understood and accept this{" "}
                    <a
                      href="https://simplyfinance.com.au/wp-content/uploads/2021/09/Credit-Guide-09-2021.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Credit Guide
                    </a>
                    , this{" "}
                    <a href="https://simplyfinance.com.au/privacy-policy/" target="_blank" rel="noreferrer">
                      Privacy Policy and Credit Reporting Policy
                    </a>{" "}
                    and this{" "}
                    <a
                      href="https://simplyfinance.com.au/wp-content/uploads/2023/03/Privacy-Consent-03-23.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Collection Notice and Consent Form
                    </a>
                    , which provide more information about how my personal information will be collected, used, and
                    disclosed. I confirm that I am authorised to provide the personal information presented and I
                    consent to my information being checked by Simply to confirm my identity.
                    <input
                      name={"privacyagree"}
                      type="checkbox"
                      checked={checkboxClicked}
                      onChange={e => setCheckboxClicked(e.target.checked)}
                    />
                    <span className="checkmark" style={{ top: "5px" }} />
                  </label>
                </div>
                {errors.checkboxclicked && <span className="error-text">{errors.checkboxclicked}</span>}
              </>
              <Button buttonType="secondary" title="Let's Go" onClick={() => handleEqButtonClick(false)} />
            </div>
          )}
        </div>
      )}
      <Footer />
      {isMobVerificationModal && (
        <VerificationCode
          countryName={countryName}
          mobileNumber={mobileNumber}
          emailAddress={emailAddress}
          isOpen={isMobVerificationModal}
          isRedirectedFromQuote
          isQuoteSubmit
          onClose={() => handlePopUp()}
        />
      )}
      <Loader isLoading={isEquifaxDataSave} />
    </>
  )
}
export default EquiFaxScreen
