import { REGEX } from "../constants/regexConstants"

export const validateEmail = emailAddress => REGEX.EMAIL.test(emailAddress)
export const validatePhone = phoneNumber => {
  const secondDigit = String(phoneNumber)?.substring(1, 2)
  const fistDigit = String(phoneNumber)?.substring(0, 1)

  if (fistDigit === "0") {
    return REGEX.PHONE.test(phoneNumber) && secondDigit !== "0"
  } else {
    return REGEX.PHONE.test(phoneNumber)
  }
}
export const onlyAlphabets = string => REGEX.ONLY_ALPHABETIC.test(string)
