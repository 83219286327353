import { Redirect, Route } from "react-router-dom"
import PropTypes from "prop-types"
import { useSelector } from "react-redux"
import { ROUTE_CONSTANTS_VARIABLE } from "./RouteConstants"

export const NonAuthenticatedRoute = ({ isEscapeRedirect, component: Component, ...options }) => {
  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)

  if (authStatus && !isEscapeRedirect) {
    return (
      <Route {...options}>
        <Redirect to={ROUTE_CONSTANTS_VARIABLE.DASHBOARD} />
      </Route>
    )
  }

  if (!authStatus && options.path === "/") {
    return (
      <Route {...options}>
        <Redirect to={ROUTE_CONSTANTS_VARIABLE.UN_AUTH_QUOTE} />
      </Route>
    )
  }

  return <Route {...options} component={Component} />
}

NonAuthenticatedRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  isEscapeRedirect: PropTypes.bool,
}
NonAuthenticatedRoute.defaultProps = {
  component: null,
  isEscapeRedirect: false,
}
