import PropTypes from "prop-types"

const Button = props => {
  const { isLoading, isDisabled, title, buttonType, className, children, ...restProps } = props
  const buttonClass = `button ${buttonType}-button ${className}`

  return (
    <button
      type="button"
      className={buttonClass}
      {...restProps}
      disabled={isDisabled || isLoading || buttonType === "outlined-secondary"}
    >
      {title && <div className={isLoading ? "button-loader" : ""}>{!isLoading && title}</div>}
      {children}
    </button>
  )
}

Button.propTypes = {
  title: PropTypes.string,
  buttonType: PropTypes.oneOf(["primary", "secondary", "success", "error", "outlined-primary", "outlined-secondary"])
    .isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  children: PropTypes.any,
}

Button.defaultProps = {
  title: "",
  className: "",
  isLoading: false,
  isDisabled: false,
}

export default Button
