/* eslint-disable no-console */
import { createSlice, current } from "@reduxjs/toolkit"
import _, { isEmpty } from "lodash"
import {
  checkFieldVisibilityCondition,
  copyFieldAnswer,
  getNextQuestionsFromAnswer,
  isValidAnswer,
  prepareApplicationDataWithSubTypes,
} from "../../../helpers/applicationHelper"
import {
  convertAPIDataToSavedFormData,
  fieldVisibilityWithoutFormname,
  restructureAllGetAPIData,
} from "../../../helpers/dataParser"
import FieldsToKeep from "../helpers/keysToKeep"
import { brokerFieldValidation } from "../helpers/brokerValidations"
import { getCorrectMergeForm, getFormIndex, mergeFormFilterByFormVisibility } from "../helpers"
// import applicationJSON from "../applicationJSON.json"

const getFieldsFromJSON = (data, path) => {
  return _.get(data, [...path, "fields"].join("."), [])
}
const getFieldsFromFormData = (data, path) => {
  return _.get(data, path?.join("."), [])
}
export const getCurrentForm = (formPaths, formPathIndex) => {
  return formPaths?.[formPathIndex]
}

const initialFormPath = [["loanType"]]
// const initialFormPath = [ [ "asset1", "assetDetails" ] ]

const initialState = {
  brokerFormPathIndex: 0,
  currentFormPathIndex: 0,
  brokerFormPaths: initialFormPath,
  formData: {},
  brokerApplicationJsonData: {},
  // updatedFieldsLength: { prev: 0, current: 0 },
  glassGuideDataBroker: {},
  glassGuideLabelBroker: {},
  brokerQuoteData: {},
  brokerApplicationData: {},
  rateData: {},
}
const brokerSlice = createSlice({
  name: "broker",
  initialState,
  reducers: {
    setJsonData(state, { payload }) {
      const preparedApplicationData = prepareApplicationDataWithSubTypes(payload)

      // START   -------------------------------------------------------------- Add current formname to fieldVisibility where there is no formname
      fieldVisibilityWithoutFormname(preparedApplicationData)
      // END     -------------------------------------------------------------- Add current formname to fieldVisibility where there is no formname

      state.brokerApplicationJsonData = preparedApplicationData
    },
    changeJsonData(state, { payload }) {
      state.brokerApplicationJsonData.financeNumbers = state?.brokerApplicationJsonData?.[payload[0]]
      state.brokerApplicationJsonData.employment = state?.brokerApplicationJsonData?.[payload[1]]
    },
    setFormPath(state, { payload }) {
      const { newForms, isForApplication } = payload ?? {}

      let formPaths = newForms.map(x => {
        const returnValue = []
        if (x.nextForm) returnValue.push(x.nextForm)
        if (x.innerForm) returnValue.push(x.innerForm)
        if (Array.isArray(x)) return x
        return returnValue
      })

      // Add assetDetails path if path is commonQuestion
      formPaths.forEach((formPath, i) => {
        if (
          formPath?.[0]?.includes("asset") &&
          formPath?.[1] === "commonQuestion" &&
          formPaths?.[i + 1]?.[1] !== "assetDetails"
        ) {
          formPaths.splice(i + 1, 0, [formPath[0], "assetDetails"])
        }
      })
      const shuffledPaths = state.brokerFormPaths

      // check form name and create array of paths
      const arrayOfMerge = []
      shuffledPaths.forEach(formsforarray => {
        if (formsforarray?.["1"] === "dependant" || formsforarray?.["1"] === "driverLicenceDetails") {
          arrayOfMerge.push(formsforarray)
        }
        if (
          formsforarray?.["1"] === "assetGlassGuide" ||
          formsforarray?.["1"] === "assetOptions" ||
          formsforarray?.["1"] === "assetIdentifiersDetails" ||
          formsforarray?.["1"] === "assetPricingDetails" ||
          formsforarray?.["1"] === "assetVendorDetails"
        ) {
          arrayOfMerge.push(formsforarray)
        }
      })

      // console.log(
      //   JSON.parse(
      //     JSON.stringify( {
      //       shuffledPaths,
      //     } )
      //   ),
      // )
      if (isForApplication) {
        const isAssetFormOnCurrentFormPath = [
          "adverseCreditHistory",
          "assetDetails",
          "commonQuestion",
          "assetGlassGuide",
          "assetOptions",
          "assetPricingDetails",
          "assetIdentifiersDetails",
          "assetVendorDetails",
        ].includes(shuffledPaths[state.currentFormPathIndex][1])

        const indexToUpdateFrom = getFormIndex(
          shuffledPaths,
          getCurrentForm(
            shuffledPaths,
            isAssetFormOnCurrentFormPath ? state.brokerFormPathIndex : state.currentFormPathIndex,
          ),
        )

        const indexToUpdateAfterFromReceivedForm = getFormIndex(
          formPaths,
          getCurrentForm(
            shuffledPaths,
            isAssetFormOnCurrentFormPath ? state.brokerFormPathIndex : state.currentFormPathIndex,
          ),
        )
        const updatedFormPaths = []
        // console.log(
        //   JSON.parse(
        //     JSON.stringify({
        //       brokerFormPaths: state.brokerFormPaths,
        //       formPaths,
        //     }),
        //   ),
        //   { indexToUpdateFrom, indexToUpdateAfterFromReceivedForm },
        // )
        shuffledPaths.forEach((e, index) => {
          if (index <= indexToUpdateFrom) {
            updatedFormPaths?.push(e)
          }
        })

        if (indexToUpdateAfterFromReceivedForm > -1) {
          formPaths.forEach((e, index) => {
            if (index > indexToUpdateAfterFromReceivedForm) {
              updatedFormPaths?.push(e)
            }
          })
        } else {
          shuffledPaths.forEach((e, index) => {
            if (index > indexToUpdateFrom) {
              updatedFormPaths?.push(e)
            }
          })
        }

        // Set brokerFormPathIndex to last item of asset
        const lastIndexOfAsset = updatedFormPaths.findLastIndex(x => x[0].includes("asset") && x[0] !== "assetInfo")
        if (
          lastIndexOfAsset > -1 &&
          [
            "adverseCreditHistory",
            "assetDetails",
            "commonQuestion",
            "assetGlassGuide",
            "assetOptions",
            "assetPricingDetails",
            "assetIdentifiersDetails",
            "assetVendorDetails",
          ].includes(shuffledPaths[state.brokerFormPathIndex][1])
        ) {
          state.brokerFormPathIndex = lastIndexOfAsset
        }
        formPaths = updatedFormPaths
      }

      if (arrayOfMerge.length > 0) {
        arrayOfMerge.forEach(element => {
          const findIndexOffForm = formPaths.findIndex(fromOfPath => fromOfPath.join(".") === element.join("."))
          if (element?.["1"] === "dependant" && findIndexOffForm <= 0) {
            const findIndexOfPersonalDetails = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "personalDetails",
            )
            if (findIndexOfPersonalDetails > 0) {
              formPaths.splice(findIndexOfPersonalDetails + 1, 0, element)
            }
          }
          if (element?.["1"] === "driverLicenceDetails" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "dependant",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
          if (element?.["1"] === "assetGlassGuide" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "assetDetails",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
          if (element?.["1"] === "assetOptions" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "assetGlassGuide",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
          if (element?.["1"] === "assetPricingDetails" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "assetOptions",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
          if (element?.["1"] === "assetIdentifiersDetails" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "assetPricingDetails",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
          if (element?.["1"] === "assetVendorDetails" && findIndexOffForm <= 0) {
            const findIndexOfDependant = formPaths.findIndex(
              fromOfPath => fromOfPath?.["0"] === element?.["0"] && fromOfPath?.["1"] === "assetIdentifiersDetails",
            )
            if (findIndexOfDependant > 0) {
              formPaths.splice(findIndexOfDependant + 1, 0, element)
            }
          }
        })
      }

      if (shuffledPaths.length > 0 && formPaths.length > 0 && state.brokerFormPathIndex + 1 < formPaths.length) {
        state.brokerFormPathIndex++
      }
      state.brokerFormPaths = formPaths
      if (state.brokerFormPathIndex > formPaths?.length - 1) {
        state.brokerFormPathIndex = formPaths?.length - 1
      }
    },

    setInitialFormData(state) {
      for (let index = 0; index <= state.brokerFormPathIndex; index++) {
        const alreadyHaveData =
          Object.values(getFieldsFromFormData(state.formData, getCurrentForm(state.brokerFormPaths, index))).length > 0
        const jsonFieldList = getFieldsFromJSON(
          state.brokerApplicationJsonData,
          getCurrentForm(state.brokerFormPaths, index),
        )

        const { fieldsToKeep } = new FieldsToKeep(
          jsonFieldList,
          jsonFieldList,
          state.formData,
          getCurrentForm(state.brokerFormPaths, index),
        )
        if (!alreadyHaveData) {
          _.set(state.formData, getCurrentForm(state.brokerFormPaths, index).join("."), fieldsToKeep)
        }
      }
    },
    changeFormData(state, { payload }) {
      // state.waitForSecond = false
      state.formData = payload.fieldsToKeep
      state.brokerFormPaths = payload.brokerFormPath
      state.brokerFormPathIndex = payload.brokerFormIndex
    },

    changeSecondFlag(state, { payload }) {
      state.waitForSecond = payload
    },

    restoreFormData(state, { payload }) {
      if (payload?.isFromBroker) {
        if (payload?.navigationHistory && Object.keys(payload?.navigationHistory).length > 0) {
          const newFormData = _.omit(payload, ["navigationHistory"])
          // START   -------------------------------------------------------------- Restore saved redux state
          const navigationHistory = payload?.navigationHistory
          Object.keys(initialState).forEach(key => {
            if (navigationHistory?.[key]) {
              state[key] = navigationHistory[key]
            }
          })
          // END     -------------------------------------------------------------- Restore saved redux state

          // START   -------------------------------------------------------------- Generate formData from saved data

          const structuredData = restructureAllGetAPIData(newFormData)
          const newSavedFormData = convertAPIDataToSavedFormData(structuredData, state.brokerApplicationJsonData)

          // To change structure of income and outgoing savedFormData
          const restoreRepeaterData = data => {
            Object.keys(data).forEach(key => {
              if (data?.[key]?.fields && !data?.[key]?.key && !data?.[key]?.fieldName) {
                data[key] = data[key].fields
              } else if (typeof data?.[key] === "object") {
                restoreRepeaterData(data[key])
              }
            })
          }
          restoreRepeaterData(newSavedFormData)

          for (let formPathIndex = 0; formPathIndex < navigationHistory.brokerFormPaths.length - 1; formPathIndex++) {
            const formDataFieldList = getFieldsFromFormData(
              newSavedFormData,
              getCurrentForm(navigationHistory.brokerFormPaths, formPathIndex),
            )
            const jsonFieldList = getFieldsFromJSON(
              state.brokerApplicationJsonData,
              getCurrentForm(state.brokerFormPaths, formPathIndex),
            )
            const { fieldsToKeep } = new FieldsToKeep(
              formDataFieldList,
              jsonFieldList,
              state.formData,
              getCurrentForm(state.brokerFormPaths, formPathIndex),
              // checked is constructor called from sync or not
              true,
            )
            // console.log( fieldsToKeep )
            //  console.log( { fieldsToKeep } )
            _.set(state.formData, getCurrentForm(state.brokerFormPaths, formPathIndex).join("."), fieldsToKeep)
          }
        } else {
          const newFormData = _.omit(payload, [
            "navigationHistory",
            "quotePreviousQuestions",
            "applicationPreviousQuestions",
          ])
          // START   -------------------------------------------------------------- Set brokerFormPaths
          const quoteFormPath = payload?.quotePreviousQuestions?.reduce((acc, { form }) => {
            const isAlreadyExists = acc.findIndex(x => x[0] === form) > -1
            if (!isAlreadyExists && form) acc.push([form])
            return acc
          }, [])
          const applicationFormPath = payload?.applicationPreviousQuestions?.[0]?.completeNavigationHistory?.reduce(
            (acc, { formsKey, subFormsKey }) => {
              if (formsKey?.[0] && subFormsKey?.length > 0) {
                subFormsKey.forEach(subForm => {
                  acc.push([formsKey[0], subForm])
                })
              } else if (formsKey?.[0]) {
                acc.push([formsKey[0]])
              }
              return acc
            },
            [],
          )
          const formPath = [...(quoteFormPath || []), ...(applicationFormPath || [])]
          if (formPath?.length > 0) {
            state.brokerFormPaths = formPath
            state.brokerFormPathIndex = formPath.length - 1
          }
          // END     -------------------------------------------------------------- Set brokerFormPaths

          // START   -------------------------------------------------------------- Generate formData from saved data
          const structuredData = restructureAllGetAPIData(newFormData)
          const newSavedFormData = convertAPIDataToSavedFormData(structuredData, state.brokerApplicationJsonData)
          state.formData = newSavedFormData
          // END     -------------------------------------------------------------- Generate formData from saved data
        }
        // END     -------------------------------------------------------------- Generate formData from saved data
      } else {
        if (
          !payload?.isFromBroker &&
          payload?.navigationHistory &&
          Object.keys(payload?.navigationHistory).length > 0
        ) {
          const newFormData = _.omit(payload, ["navigationHistory"])
          // START   -------------------------------------------------------------- Restore saved redux state
          const navigationHistory = payload?.navigationHistory
          Object.keys(initialState).forEach(key => {
            if (navigationHistory?.[key]) {
              state[key] = navigationHistory[key]
            }
          })
          // END     -------------------------------------------------------------- Restore saved redux state

          // START   -------------------------------------------------------------- Generate formData from saved data

          const structuredData = restructureAllGetAPIData(newFormData)
          const newSavedFormData = convertAPIDataToSavedFormData(structuredData, state.brokerApplicationJsonData)

          // To change structure of income and outgoing savedFormData
          const restoreRepeaterData = data => {
            Object.keys(data).forEach(key => {
              if (data?.[key]?.fields && !data?.[key]?.key && !data?.[key]?.fieldName) {
                data[key] = data[key].fields
              } else if (typeof data?.[key] === "object") {
                restoreRepeaterData(data[key])
              }
            })
          }
          restoreRepeaterData(newSavedFormData)

          for (let formPathIndex = 0; formPathIndex < navigationHistory.brokerFormPaths.length - 1; formPathIndex++) {
            const formDataFieldList = getFieldsFromFormData(
              newSavedFormData,
              getCurrentForm(navigationHistory.brokerFormPaths, formPathIndex),
            )
            const jsonFieldList = getFieldsFromJSON(
              state.brokerApplicationJsonData,
              getCurrentForm(state.brokerFormPaths, formPathIndex),
            )
            const { fieldsToKeep } = new FieldsToKeep(
              formDataFieldList,
              jsonFieldList,
              state.formData,
              getCurrentForm(state.brokerFormPaths, formPathIndex),
              // checked is constructor called from sync or not
              true,
            )
            // console.log( fieldsToKeep )
            //  console.log( { fieldsToKeep } )
            _.set(state.formData, getCurrentForm(state.brokerFormPaths, formPathIndex).join("."), fieldsToKeep)
          }
        } else {
          const newFormData = _.omit(payload, [
            "navigationHistory",
            "quotePreviousQuestions",
            "applicationPreviousQuestions",
          ])
          // START   -------------------------------------------------------------- Set brokerFormPaths
          const quoteFormPath = payload?.quotePreviousQuestions?.reduce((acc, { form }) => {
            const isAlreadyExists = acc.findIndex(x => x[0] === form) > -1
            if (!isAlreadyExists && form) acc.push([form])
            return acc
          }, [])
          const applicationFormPath = payload?.applicationPreviousQuestions?.[0]?.completeNavigationHistory?.reduce(
            (acc, { formsKey, subFormsKey }) => {
              if (formsKey?.[0] && subFormsKey?.length > 0) {
                subFormsKey.forEach(subForm => {
                  acc.push([formsKey[0], subForm])
                })
              } else if (formsKey?.[0]) {
                acc.push([formsKey[0]])
              }
              return acc
            },
            [],
          )
          const formPath = [...(quoteFormPath || []), ...(applicationFormPath || [])]
          if (formPath?.length > 0) {
            state.brokerFormPaths = formPath
            state.brokerFormPathIndex = formPath.length - 1
          }
          // END     -------------------------------------------------------------- Set brokerFormPaths

          // START   -------------------------------------------------------------- Generate formData from saved data
          const structuredData = restructureAllGetAPIData(newFormData)
          const newSavedFormData = convertAPIDataToSavedFormData(structuredData, state.brokerApplicationJsonData)
          state.formData = newSavedFormData
          // END     -------------------------------------------------------------- Generate formData from saved data
        }
      }
    },
    restoreGlassGuideDataAndLabel(state, { payload }) {
      if (payload?.glassGuideDataBroker) {
        state.glassGuideDataBroker = payload.glassGuideDataBroker
      }
      if (payload?.glassGuideLabelBroker) {
        state.glassGuideLabelBroker = payload.glassGuideLabelBroker
      }
    },
    setFormData(state, { payload }) {
      _.set(state.formData, payload.path, payload.value)
    },
    setGlassGuideLabelData(state, { payload }) {
      _.set(state.glassGuideLabelBroker, `${payload?.formToShow}.${payload.fieldName}`, payload.label)
    },
    setFieldData(state, { payload }) {
      const { table, ...restData } = payload?.data
      if (
        !table.length ||
        payload?.field?.fieldName === "manufacturerOptions" ||
        payload?.field?.fieldName === "assetManufacturerOptions"
      ) {
        table.push({ label: "None", value: "None" })
      }

      if (payload?.field?.fieldName === "assetManufacturerOptions") {
        const fieldIndex = _.get(state.formData, `${payload?.pathOfField?.split("[")[0]}`)?.findIndex(
          x => x.fieldName === "assetGlassGuideData",
        )
        const restOfTheTable = payload?.data
        //  delete restOfTheTable.table
        _.set(
          state.formData,
          `${payload?.pathOfField?.split("[")[0]}[${fieldIndex}].answer`,
          JSON.stringify(restOfTheTable),
        )
      }
      state.glassGuideDataBroker = { ...state.glassGuideDataBroker, ...restData }
      _.set(state.formData, `${payload.pathOfField}.options`, table)
    },

    syncFormDataWithFields(state, action) {
      // state.errorCounter = 0
      const prevFormPaths = state.brokerFormPaths
      const { index: formPathIndex, path, glassGuideFieldAnswerChangedKey } = action.payload ?? {}

      const formDataFieldList = getFieldsFromFormData(
        state.formData,
        getCurrentForm(state.brokerFormPaths, formPathIndex),
      )
      const jsonFieldList = getFieldsFromJSON(
        state.brokerApplicationJsonData,
        getCurrentForm(state.brokerFormPaths, formPathIndex),
      )

      // To remove glassguide next fields when glassguide field option changed
      if (glassGuideFieldAnswerChangedKey) {
        const keys = formDataFieldList.reduce((acc, field) => {
          if (field?.key === glassGuideFieldAnswerChangedKey) {
            acc = getNextQuestionsFromAnswer(field?.nextQuestions, field?.answer)
          }
          return acc
        }, [])
        if (keys?.length)
          keys.forEach(key => {
            const fieldIndex = formDataFieldList.findIndex(x => x?.key === key)
            if (fieldIndex >= 0 && formDataFieldList[fieldIndex]?.options?.length > 0) {
              delete formDataFieldList[fieldIndex].options
              delete formDataFieldList[fieldIndex].answer
            }
          })
      }

      // START   -------------------------------------------------------------- To remove assetOptions if assetGlassguide fields changed
      if (
        path.includes("assetGlassGuide") &&
        (glassGuideFieldAnswerChangedKey === "" || glassGuideFieldAnswerChangedKey)
      ) {
        const assetOptionsIndex = state.brokerFormPaths.findIndex(
          (formPath, i) => i > formPathIndex && formPath[1] === "assetOptions",
        )
        if (assetOptionsIndex >= 0) {
          _.unset(state.formData, state.brokerFormPaths[assetOptionsIndex])
          state.brokerFormPaths.splice(assetOptionsIndex, 1)
          state.brokerFormPathIndex--
        }
      }
      // END     -------------------------------------------------------------- To remove assetOptions if assetGlassguide fields changed

      const { fieldsToKeep } = new FieldsToKeep(
        formDataFieldList,
        jsonFieldList,
        state.formData,
        getCurrentForm(state.brokerFormPaths, formPathIndex),
        // checked is constructor called from sync or not
        true,
      )
      _.set(state.formData, getCurrentForm(state.brokerFormPaths, formPathIndex).join("."), fieldsToKeep)

      const isQuoteField = _.get(
        state.brokerApplicationJsonData,
        `${getCurrentForm(state.brokerFormPaths, formPathIndex)}.isQuoteForm`,
      )

      const currentQuestion = _.get(current(state.formData), path)

      if (isQuoteField) {
        const isAPIField = currentQuestion?.quoteApiCallLastQuestion ?? false

        const nextQuestions = getNextQuestionsFromAnswer(currentQuestion?.nextQuestions, currentQuestion?.answer)
        const haveApiCall = _.get(
          state.brokerApplicationJsonData,
          `${getCurrentForm(state.brokerFormPaths, formPathIndex)?.[0]}.apiCall`,
        )
        if (
          getCurrentForm(state?.brokerFormPaths, formPathIndex)?.[0] === "residency" &&
          ["Q3", "Q4"].includes(currentQuestion?.key)
        ) {
          //   console.log("Last Question")
          if (
            currentQuestion?.key === "Q3" &&
            currentQuestion?.answer &&
            currentQuestion?.answer !== "Visa"
            // &&
            // state.brokerFormPathIndex < state.brokerFormPaths.length
          ) {
            //    console.log("Not Visa")
            const indexToGetPathFrom = state.brokerFormPaths?.findIndex(e => e?.[0] === "quoteDetails")
            if (indexToGetPathFrom <= 0) {
              state.brokerFormPaths.push(["quoteDetails"])
              state.brokerFormPathIndex++
            }
          }
          if (
            currentQuestion?.key === "Q4" &&
            currentQuestion?.answer
            // &&
            // state.brokerFormPathIndex < state.brokerFormPaths.length
          ) {
            const indexToGetPathFrom = state.brokerFormPaths?.findIndex(e => e?.[0] === "quoteDetails")
            if (indexToGetPathFrom <= 0) {
              //   console.log("It's Visa")
              state.brokerFormPaths.push(["quoteDetails"])
              state.brokerFormPathIndex++
            }
          }
        } else if (!nextQuestions && haveApiCall && isAPIField) {
          state.isLastQuestion = true
        }
      } else {
        // for application
        const isAPIField = currentQuestion?.applicationApiCallLastQuestion ?? false
        const canMerge = currentQuestion?.canMerge ?? false
        // console.log( "canMerge", canMerge )
        const haveApiCall =
          _.get(
            state.brokerApplicationJsonData,
            `${getCurrentForm(state.brokerFormPaths, formPathIndex)?.join(".")}.apiCall`,
          ) ?? false

        const mergeFormName = _.get(
          state.brokerApplicationJsonData,
          `${getCurrentForm(state.brokerFormPaths, formPathIndex)?.join(".")}.mergeFormName`,
        )
        // console.log( "mergeFormName", mergeFormName )

        if (haveApiCall && isAPIField) {
          if (!currentQuestion?.isSkip || (currentQuestion?.isSkip && currentQuestion?.answer)) {
            if (
              [
                "currentPropertyYears",
                "currentPropertyMonths",
                "previousPropertyYears",
                "previousPropertyMonths",
              ].includes(currentQuestion?.fieldName)
            ) {
              state.waitForSecond = true
            }
            state.isApplicationLastQuestion = true
          }
        }
        if (mergeFormName && state.brokerFormPaths?.[formPathIndex]?.[0] && canMerge) {
          if (!currentQuestion?.isSkip || (currentQuestion?.isSkip && isValidAnswer(currentQuestion?.answer))) {
            state.mergeFormsData = {
              formPathIndex,
              path,
            }
          }
        }
        // console.log( "mergeFormsData", state.mergeFormsData )
      }

      // setting index of current changing form to check validations
      state.currentFormPathIndex = formPathIndex
      const incomeOutgoingPassed = state.brokerFormPaths?.filter((formList, index) => {
        if (formList[1] === "customerDetails") {
          const valueFilled = state.formData?.[`${formList[0]}`]?.[`${formList[1]}`]?.[1]?.fields?.[0]?.answer
          if (valueFilled) {
            return true
          }
        }
        if (formList[1] === "companyRegistrationDetails") {
          const valueFilled = state.formData?.[`${formList[0]}`]?.[`${formList[1]}`]?.[0]?.answer

          if (valueFilled) {
            return true
          }
        }
        if (formList[1] === "trustDetails") {
          const valueFilled = state.formData?.[`${formList[0]}`]?.[`${formList[1]}`]?.[0]?.answer

          if (valueFilled) {
            return true
          }
        }
        if (formList[0] === "quoteDetails") {
          const valueFilled = state.formData?.[`${formList[0]}`]?.[3]?.answer

          if (valueFilled) {
            return true
          }
        }
        return false
      })
      if (
        incomeOutgoingPassed.length === 0 &&
        state?.brokerFormPaths?.length > prevFormPaths?.length &&
        state?.errorCounter <= 0
      ) {
        setTimeout(() => {
          const divScroll = document.querySelector("#root > div.application-tool-form.movetoSidebar")
          if (divScroll)
            divScroll.scrollTo({
              top: divScroll.scrollHeight + 150,
              left: 0,
              behavior: "smooth",
            })
        }, 100)
      }
      return state
    },
    performMergeForm: (state, action) => {
      // if ((!state.errorCounter || state.errorCounter <= 0) && !isEmpty(state.mergeFormsData)) {
      if (!isEmpty(state.mergeFormsData)) {
        const { formPathIndex } = action.payload ?? {}
        //  console.log({ formPathIndex })
        let mergeFormName = _.get(
          state.brokerApplicationJsonData,
          `${getCurrentForm(state.brokerFormPaths, formPathIndex)?.join(".")}.mergeFormName`,
        )

        //   console.log({ mergeFormName })

        if (Array.isArray(mergeFormName) && mergeFormName?.length > 0) {
          mergeFormName = getCorrectMergeForm(
            mergeFormName,
            state.formData,
            getCurrentForm(state.brokerFormPaths, formPathIndex)[0],
          )
        } else {
          mergeFormName = [mergeFormName]
        }

        //   console.log({ mergeFormName })

        mergeFormName = mergeFormFilterByFormVisibility(
          mergeFormName,
          getCurrentForm(state.brokerFormPaths, formPathIndex)[0],
          state.brokerApplicationJsonData,
          state.formData,
        )

        //   console.log({ mergeFormName })

        if (mergeFormName?.length > 0) {
          const formPaths = [...state.brokerFormPaths]
          let indexToIncrement = state.brokerFormPathIndex

          mergeFormName?.forEach((form, index) => {
            if (form) {
              const availableIndex = formPaths.findIndex(
                e => e?.[0] === formPaths?.[formPathIndex]?.[0] && e?.[1] === form,
              )
              if (availableIndex === -1) {
                formPaths.splice(formPathIndex + index + 1, 0, [formPaths[formPathIndex][0], form])
                //   console.log(indexToIncrement + 1, formPaths.length)
                if (indexToIncrement < formPaths.length) indexToIncrement++
              } else {
                if (indexToIncrement !== availableIndex && indexToIncrement < availableIndex) {
                  indexToIncrement = availableIndex
                }
              }
            }
          })

          state.brokerFormPaths = formPaths
          state.brokerFormPathIndex = indexToIncrement < formPaths.length - 1 ? indexToIncrement : formPaths?.length - 1

          // To increase formpathindex if brokerPathIndex is at commonQuestion subform
          if (state.brokerFormPaths[state.brokerFormPathIndex]?.[1] === "commonQuestion") state.brokerFormPathIndex++
        }
        state.mergeFormsData = null
      }
    },
    setLastQuestion: (state, action) => {
      state.isLastQuestion = action.payload
      state.isApplicationLastQuestion = action.payload
      state.waitForSecond = false
    },
    resetExtraFields: state => {
      state.isLastQuestion = false
      state.isApplicationLastQuestion = false
      state.errorCounter = 0
      state.mergeFormsData = {}
      state.waitForSecond = false
    },
    removeFormData(state, action) {
      const { index, mainIndex } = action?.payload ?? {}
      const isQuoteField = _.get(
        state.brokerApplicationJsonData,
        `${getCurrentForm(state.brokerFormPaths, index)}.isQuoteForm`,
      )
      if (state.brokerFormPathIndex > index) {
        state.brokerFormPaths = state?.brokerFormPaths?.filter((_, i) => i <= index)
        state.brokerFormPathIndex = index
      }
      if (isQuoteField) {
        let formToUpdate = state?.formData?.[state?.brokerFormPaths?.[index]]
        if (formToUpdate?.length > 0 && index >= 0 && mainIndex >= 0) {
          formToUpdate = formToUpdate?.filter((_, i) => i <= mainIndex)
          state.formData = {
            ...state.formData,
            [`${state.brokerFormPaths[index]}`]: formToUpdate,
          }
        }
      }
      const updatedFormData = {}
      state.brokerFormPaths.forEach(e => {
        _.set(updatedFormData, e?.join("."), _.get(state.formData, e?.join(".")))
      })
      state.formData = updatedFormData
    },

    // TO ADD AND REMOVE REPEATER SET
    repeaterFormAddAndRemove: (state, { payload: { index, mainIndex, isAdd, removeIndex } }) => {
      _.update(state.formData, `${state.brokerFormPaths[index].join(".")}[${mainIndex}]`, data => {
        // Need to remove repeater set
        if (removeIndex >= 0) {
          if (data?.fields?.[removeIndex]) data.fields.splice(removeIndex, 1)
          // Need to add repeater set
        } else {
          if (Array.isArray(data?.fields)) {
            // Check field visibility condition for repeater fields
            const checkRepeaterFieldVisibility = inputData =>
              inputData.filter(x => checkFieldVisibilityCondition(x, state.formData, data.fields))

            // if field has fieldsToRender then push it to fields
            if (data?.fieldsToRender?.length > 0) data.fields.push(checkRepeaterFieldVisibility(data.fieldsToRender))
            // if field dont have fieldToRender then use fields first element and remove answers from it
            else if (data?.fields?.[0]?.length > 0)
              data.fields.push(checkRepeaterFieldVisibility(data.fields[0].map(x => ({ ...x, answer: undefined }))))
          }
        }
        return data
      })
    },

    // validate fields
    validateFields: (state, { payload }) => {
      const index = Number(payload)
      let counter = 0
      const formPathsToValidate = state.brokerFormPaths.slice(
        0,
        (index || index === 0 ? index : state.brokerFormPathIndex) + 1,
      )

      // To only validate fields which needs to be validated
      // uses index of formpath to validate particular fields
      const doesPathIncludeFormPath = path =>
        formPathsToValidate.findIndex(formPath => path.includes(formPath.join("."))) >= 0

      const findFieldAndSetError = (data, path = "", NformData, formpath) => {
        for (const key in data) {
          if (data[key]?.key && data[key]?.question && data[key]?.fieldName) {
            const error = brokerFieldValidation(data[key], `${path}.${key}`, NformData, formpath)

            if (error && doesPathIncludeFormPath(path)) {
              data[key].errorMessage = error
              counter++
            } else if (data?.[key]?.errorMessage) data[key].errorMessage = ""

            if (data[key]?.fields)
              findFieldAndSetError(data[key]?.fields, `${path ? `${path}.` : ""}${key}.fields`, NformData, formpath)
          } else if (data[key] instanceof Object) {
            findFieldAndSetError(data[key], `${path ? `${path}.` : ""}${key}`, NformData, formpath)
          }
        }
      }
      const formpath = state.brokerFormPaths?.[state.currentFormPathIndex]?.[0]
      findFieldAndSetError(state.formData, "", state.formData, formpath)
      state.errorCounter = counter
    },
    copyAsset: (state, { payload }) => {
      const { mainForm, copyForms, isAdd } = payload
      // Loop through forms to copy
      if (state?.glassGuideLabelBroker?.[copyForms.formName] && state?.glassGuideLabelBroker && mainForm) {
        _.set(state.glassGuideLabelBroker, mainForm, state.glassGuideLabelBroker[copyForms.formName])
      }
      copyForms.fieldsToCopy.forEach(formToCopy => {
        // Need to add form or remove it
        if (isAdd) {
          // get fields from formData if exists
          let fields = _.get(state.formData, `${copyForms.formName}.${formToCopy}`)

          if (!copyForms?.formName) {
            // if it doesnt have formName then it means its copy vendor with "New" value
            // Get fields to keep from json
            const { fieldsToKeep } = new FieldsToKeep(
              getFieldsFromJSON(state.brokerApplicationJsonData, [mainForm, formToCopy]),
              getFieldsFromJSON(state.brokerApplicationJsonData, [mainForm, formToCopy]),
              state.formData,
              [mainForm, formToCopy],
              // checked is constructor called from sync or not
              false,
            )
            fields = fieldsToKeep
          }
          // If cant find fields then dont do anything
          if (!(Array.isArray(fields) && fields?.length > 0)) return
          // Set fields to formData
          _.set(state.formData, `${mainForm}.${formToCopy}`, fields)
        } else {
          // Delete data from formData
          delete state.formData[mainForm][formToCopy]
        }
      })

      // Add Forms
      if (isAdd) {
        const allFormsOfAssetToCopy = state.brokerFormPaths.filter(
          formPath => formPath[0] === copyForms.formName && !["commonQuestion"].includes(formPath[1]),
        )
        allFormsOfAssetToCopy.forEach(([_, assetFormToCopy]) => {
          const isAlreadyExists =
            state.brokerFormPaths.findIndex(formPath => formPath[0] === mainForm && formPath[1] === assetFormToCopy) >
            -1
          if (!isAlreadyExists) {
            const lastCurrentFormIndex = state.brokerFormPaths.findLastIndex(formPath => formPath[0] === mainForm)
            state.brokerFormPaths.splice(lastCurrentFormIndex + 1, 0, [mainForm, assetFormToCopy])
            if (state.brokerFormPathIndex + 1 < state.brokerFormPaths.length) state.brokerFormPathIndex++
          }
        })
        // Remove forms
      } else {
        const allFormsOfAssetToRemove = state.brokerFormPaths.filter(
          formPath =>
            formPath[0] === mainForm && !["commonQuestion", "assetDetails", "assetVendorDetails"].includes(formPath[1]),
        )
        allFormsOfAssetToRemove.forEach(([_, assetFormToRemove]) => {
          const indexToRemove = state.brokerFormPaths.findIndex(
            formPath => formPath[0] === mainForm && formPath[1] === assetFormToRemove,
          )
          if (indexToRemove > -1) {
            state.brokerFormPaths.splice(indexToRemove, 1)
            state.brokerFormPathIndex--
          }
        })
      }
      const lastAssetFormIndex = state.brokerFormPaths.findIndex(formPath => formPath[0] === mainForm)
      if (lastAssetFormIndex > state.brokerFormPathIndex) state.brokerFormPathIndex = lastAssetFormIndex
    },
    copyFields: (state, { payload }) => {
      const { field, formPathIndex } = payload
      copyFieldAnswer(
        field?.answer,
        _.get(state.formData, `${state.brokerFormPaths[formPathIndex].join(".")}`),
        field?.copyFields?.from,
        field?.copyFields?.to,
      )
    },
    updateBrokerFields: (state, action) => {
      const { fieldName, value } = action.payload ?? {}
      state[fieldName] = value
    },
    setQuoteAnswerData: (state, { payload }) => {
      state.brokerQuoteData = payload.data
    },
    setApplicationAnswerData: (state, { payload }) => {
      state.brokerApplicationData = payload
    },
    setRateData: (state, { payload }) => {
      state.rateData = payload.data
    },
    setBrokerExtraPathData: (state, action) => {
      state.brokerExtraPathData = action?.payload ?? {}
    },
  },
})

export const {
  setFormPath,
  changeSecondFlag,
  setJsonData,
  setFormData,
  setInitialFormData,
  syncFormDataWithFields,
  removeFormData,
  changeJsonData,
  setFieldData,
  setLastQuestion,
  repeaterFormAddAndRemove,
  validateFields,
  setGlassGuideLabelData,
  performMergeForm,
  copyAsset,
  copyFields,
  updateBrokerFields,
  restoreFormData,
  changeFormData,
  setQuoteAnswerData,
  setApplicationAnswerData,
  setRateData,
  setBrokerExtraPathData,
  resetExtraFields,
  restoreGlassGuideDataAndLabel,
} = brokerSlice.actions
export default brokerSlice.reducer
