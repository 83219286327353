import PropTypes from "prop-types"
import { RenderField } from "../../application/components/RenderField"

const RepeaterForm = ({
  field,
  onFieldUpdate,
  onAdd,
  onRemove,
  currentForm,
  isFromBrokerPage,
  isFromBrokerPageNew,
  autoFillFieldsRef,
}) => (
  <>
    <div className="repeater-wrapper repeater-questions">
      <div className="repeater-head">
        {field?.question || ""}
        {/* <span className="material-icons-outlined repeater-add-icon" onClick={onAdd}>
        control_point 
      </span> */}
      </div>
      <table className="repeater-table">
        {field?.fields?.length ? (
          field.fields.map((f, index) => (
            <tr key={index} className={`row${index} repeater-rows`}>
              {f?.map((e, innnerIndex) => {
                return (
                  <td key={`${e.key}-${e.fieldName}`} style={{ minWidth: "" }}>
                    <RenderField
                      field={e}
                      autoFillFieldsRef={autoFillFieldsRef}
                      isFromAssetPage={false}
                      indexForRepeater={index}
                      isFromBrokerPage={isFromBrokerPage}
                      isFromBrokerPageNew={isFromBrokerPageNew}
                      currentForm={currentForm}
                      onFieldUpdate={(key, value) => onFieldUpdate(index, innnerIndex, e, value)}
                      errorMessage={e?.errorMessage || ""}
                    />
                  </td>
                )
              })}
              {(field?.fieldsToRender?.length || field?.fields?.length > 1) && (
                <td className="repeater-remove-cell">
                  <div className="repeater_btns_wrapper">
                    <span className="material-icons-outlined repeater-add-icon" onClick={() => onAdd(index)}>
                      control_point
                    </span>
                    <span className="material-icons-outlined repeater-remove-icon" onClick={() => onRemove(index)}>
                      remove_circle_outline
                    </span>
                  </div>
                </td>
              )}
            </tr>
          ))
        ) : (
          <div className="no-entry">
            <span className="material-icons-outlined" onClick={onAdd}>
              control_point
            </span>
            <span onClick={onAdd}>Add Entry</span>
          </div>
        )}
      </table>
    </div>
  </>
)

RepeaterForm.propTypes = {
  // field to show
  field: PropTypes.object,
  // call when field changes
  onFieldUpdate: PropTypes.func,
  errorMessage: PropTypes.string,
  handleSearch: PropTypes.func,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  currentForm: PropTypes.array,
  isFromBrokerPageNew: PropTypes.bool,
  isFromBrokerPage: PropTypes.bool,
  autoFillFieldsRef: PropTypes.object,
}

export default RepeaterForm
