import { useState } from "react"
import { countries } from "../../constants/Constant"
import CustomSelect from "../CustomSelect/CustomSelect"
import Input from "../Input/Input"
import PropTypes from "prop-types"

const CountrySelect = props => {
  const {
    countryCode,
    className,
    countryName,
    phoneNumber,
    updatePhoneNumber,
    updatePhoneCountry,
    placeholder,
    isDisabled,
    error,
    suffix,
    suffixClick,
    suffixClass,
  } = props
  const options = countries.map(e => ({ label: `${e?.flag} ${e?.code}`, value: e }))
  let countryNameUpdated = countryName
  let errorPhone = ""
  if (countryCode) {
    const myCountry = countries.filter(country => country?.code === countryCode)[0]
    countryNameUpdated = {
      label: myCountry.flag + " " + myCountry.code,
      value: { name: myCountry.name, cca2: myCountry.cca, flag: myCountry.flag, code: myCountry.code },
    }
  }
  const [isInputFocused, setIsInputFocused] = useState(false)

  const formatPhoneNumber = phone => {
    const cleaned = phone.replace(/\D/g, "")

    if (cleaned.length === 10) {
      updatePhoneNumber(`${cleaned.slice(0, 2)} ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`)
    } else if (cleaned.length === 8) {
      const prefix = cleaned.slice(0, 2)
      const isStart0 = cleaned.slice(0, 1)

      if (isStart0 === "0") {
        updatePhoneNumber(`${prefix} ${cleaned.slice(2, 6)}-${cleaned.slice(6)}`)
        errorPhone = "Please enter valid mobile number"
      } else {
        updatePhoneNumber(`0${cleaned.slice(0, 1)} ${cleaned.slice(1, 5)}-${cleaned.slice(5)}`)
        errorPhone = "Please enter valid area code"
      }
    }

    setIsInputFocused(false)
  }

  return (
    <>
      <div
        className={`contact-number-container ${className} ${isInputFocused &&
          !isDisabled &&
          "border-secondary"} ${!updatePhoneCountry && "hide-arrow"}  ${phoneNumber?.toString().trim().length &&
          !isInputFocused &&
          "border-primary"}`}
      >
        <CustomSelect
          options={options}
          name="countryName"
          className={`country-select ${isDisabled && "disabled"} ${isInputFocused &&
            "country-code-select-focused"} ${phoneNumber?.toString().trim().length &&
            !isInputFocused &&
            "border-color-primary"}`}
          value={countryNameUpdated}
          isLabelRequired={false}
          placeholder="Code"
          isCountryDropDown={true}
          onChangeCustomSelect={updatePhoneCountry}
          isDisabled={!updatePhoneCountry || isDisabled}
        />
        <Input
          type="text"
          placeholder={placeholder}
          name="phoneNumber"
          className="country-input"
          value={phoneNumber}
          disabled={isDisabled}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => formatPhoneNumber(phoneNumber)}
          minLength="8"
          maxLength="12"
          onChange={e => {
            updatePhoneNumber(e?.target?.value?.toString()?.trim())
          }}
          isCountryInput
          suffix={suffix}
          suffixClick={suffixClick}
          suffixClass={suffixClass}
          error={error?.phoneNumber}
        />
        {errorPhone}
      </div>
      {error && <span className="error-text">{error}</span>}
      {errorPhone && <span className="error-text">{errorPhone}</span>}
    </>
  )
}

CountrySelect.propTypes = {
  countryCode: PropTypes.string,
  className: PropTypes.string,
  countryName: PropTypes.object,
  phoneNumber: PropTypes.string,
  updatePhoneNumber: PropTypes.func,
  updatePhoneCountry: PropTypes.func,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  error: PropTypes.string,
  inputType: PropTypes.string,
  suffix: PropTypes.string,
  suffixClick: PropTypes.func,
  suffixClass: PropTypes.string,
}

CountrySelect.defaultProps = {
  countryCode: "",
  className: "",
  countryName: {},
  phoneNumber: "",
  updatePhoneNumber: () => {},
  updatePhoneCountry: null,
  placeholder: "Phone",
  disabled: false,
  error: "",
  inputType: "text",
  suffixClick: () => {},
  suffixClass: "",
  suffix: "",
}

export default CountrySelect
