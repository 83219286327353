import { cloneDeep, isEmpty, set, update } from "lodash"
import { Fragment, useEffect, useRef, useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import Loader from "../../../common/loader/Loader"
import {
  areFieldsCorrect,
  checkFieldVisibilityCondition,
  copyFieldAnswer,
  getCloneDeep,
  isObject,
  isValidAnswer,
  stringCompare,
} from "../../../helpers/applicationHelper"
import { ROUTE_CONSTANTS_VARIABLE } from "../../../routes/RouteConstants"
import ApplicationFooter from "../ApplicationFooter/ApplicationFooter"
import { FormWrapper } from "../components/FormWrapper"
import { APPLICATION_REDUX_CONSTANTS } from "../redux/ApplicaitonReduxConstants"
import { useUpdateFields } from "../hooks/useUpdateFields"
import { useMergeForm } from "../hooks/useMergeForm"
import { applicationSelector } from "../redux/ApplicationSelector"
import { useApplicationCommon } from "../hooks/useApplicationCommon"
import { QuickAccessMenuDrawer } from "../components/quickAccessMenuDrawer"
import { errorNotification } from "../../../common/NotifyToaster"
import Modal from "../../../common/Modal/Modal"
import { startLoader, stopLoader } from "../../../common/loader/redux/LoaderActions"
import { isEditedContext } from "../../../helpers/contexts"

const ApplicationPage = () => {
  // START   ---------------------------------------------------- Hooks
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    updateFieldsToShowOnRefresh,
    keysOfFieldsToKeep,
    keysOfFieldsToRemove,
    getCorrectFields,
    copyAsset,
    copyVendor,
    updateFieldsToShowAccordingToSubformsAndFirstQuestion,
    removeAssetData,
    isFieldUpdated,
  } = useUpdateFields()
  const isEdited = useContext(isEditedContext)
  const { mergeFormWithString, mergeFormWithObject } = useMergeForm()
  const { formTitles, getCurrentFormData, formTitlesMain, activeSaveApiCall, formPathAPICall } = useApplicationCommon()
  // END     ---------------------------------------------------- Hooks

  // START   ---------------------------------------------------- Redux states
  const [isPrefilled, setIsPrefilled] = useState()
  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)
  const { isApplicationCommonLoader, isApplicationCommonLoaderActiveSave } = useSelector(
    ({ loaderReducer }) => loaderReducer ?? {},
  )
  const {
    applicationJsonData,
    initialDataLoadedTrigger,
    savedDataLoadedTrigger,
    formsKey,
    subFormsKey,
    fieldsToShow,
    formPathIndex,
    savedFormData,
    isOnDisclosurePage,
    isNextOrPrev,
    // isEdited,
  } = useSelector(applicationSelector)
  // END     ---------------------------------------------------- Redux states
  // START   ---------------------------------------------------- React Refs
  const disableEffect = useRef(true)
  const isForceUpdateEnable = useRef(false)

  // START   -------------------------------------------------------------- Validate fields for multiple forms
  const { current: validateFields } = useRef([])
  const registerCallback = (callback, i) => {
    set(validateFields, i, callback)
  }
  // END     -------------------------------------------------------------- Validate fields for multiple forms

  // START   -------------------------------------------------------------- It returns data of fields from each form
  const { current: getFormFields } = useRef([])
  const registerGetFormFieldsCallback = (callback, i) => {
    set(getFormFields, i, callback)
  }
  // END     -------------------------------------------------------------- It returns data of fields from each form
  // END     ---------------------------------------------------- React Refs

  // START   ---------------------------------------------------- React effects
  // START   -------------------------------------------------------------- On refresh/load it'll show fields with answers and its nextQuestions
  useEffect(() => {
    if (!isEmpty(applicationJsonData)) {
      isForceUpdateEnable.current = false
      updateFieldsToShowOnRefresh()
      // console.log("1")
    }
  }, [applicationJsonData, initialDataLoadedTrigger, savedDataLoadedTrigger, formsKey, formPathIndex])
  // END     -------------------------------------------------------------- On refresh/load it'll show fields with answers and its nextQuestions

  // set field of google lookup field

  // START   -------------------------------------------------------------- Show merge form based on fieldsToShow
  useEffect(() => {
    if (fieldsToShow?.length > 0) {
      fieldsToShow.forEach((fields, index) => {
        const formData = getCurrentFormData(index)

        const haveAnswer = fields.some(x => isValidAnswer(x?.answer))
        const haveMergeForm = formData?.mergeFormName
        // for default merge forms
        if (typeof formData?.mergeFormName === "string") {
          mergeFormWithString(haveAnswer, haveMergeForm, formData)
        } else if (Array.isArray(formData?.mergeFormName) && formData?.mergeFormName?.length > 0) {
          mergeFormWithObject(formData, isForceUpdateEnable)
        }
      })
      // console.log("2")
    }
  }, [fieldsToShow])
  useEffect(() => {
    if (!disableEffect.current && subFormsKey?.length > fieldsToShow?.length) {
      isForceUpdateEnable.current = false
      updateFieldsToShowAccordingToSubformsAndFirstQuestion()
      //   console.log("3")
    }
  }, [subFormsKey, fieldsToShow])
  // END     -------------------------------------------------------------- Show merge form based on fieldsToShow
  // END     ---------------------------------------------------- React effects

  // START   ---------------------------------------------------- Functions
  const updateFieldsToShowOnAnswerSet = (updatedField, index, isRepeater, updatedForm) => {
    // console.log(updatedField)
    if (updatedField?.fieldToCheckRecalculation) {
      dispatch({ type: APPLICATION_REDUX_CONSTANTS.SET_RECALCULATION_FIELD_UPDATE, payload: true })
    }
    if (isRepeater) {
      const currentVisibleFields = cloneDeep(fieldsToShow)
      currentVisibleFields[index] = updatedForm
      dispatch({
        type: APPLICATION_REDUX_CONSTANTS.UPDATE_FIELDS_TO_SHOW,
        payload: currentVisibleFields,
      })
    } else {
      // To remove next fields when asset details, glassguide asset field is updated
      if (removeAssetData(index, updatedField)) {
        return
      }
      const formDataToSave = [...fieldsToShow]
      // START   -------------------------------------------------------------- To fix address getting removed after changing different value
      const haveObectInsideObject = fieldsToShow.reduce((acc, x, i) => {
        if (x?.length > 0) {
          x.forEach((y, j) => {
            if (y?.fields) {
              acc.push(`[${i}].fields[${j}]`)
            }
          })
        }
        return acc
      }, [])
      if (
        haveObectInsideObject?.length > 0 ||
        formsKey?.[0] === "serviceability" ||
        updatedField?.fieldName === "nvic" ||
        updatedField?.fieldName === "assetDescription"
      ) {
        set(formDataToSave, `[${index}]`, updatedForm)
      }
      // END     -------------------------------------------------------------- To fix address getting removed after changing different value
      const currentVisibleFields = cloneDeep(formDataToSave)

      const showingFields = [...(currentVisibleFields?.[index] || [])]

      const allFields = getCloneDeep(
        applicationJsonData,
        subFormsKey?.length
          ? `${formsKey?.[formsKey?.length - 1]}.${subFormsKey?.[index]}.fields`
          : `${formsKey[index]}.fields`,
      )

      const removeIndex = showingFields.findIndex(x => updatedField?.key === x?.key)

      const deletedField = currentVisibleFields?.[index]?.[removeIndex]

      if (removeIndex >= 0) {
        showingFields.splice(removeIndex, 1)
      }

      const fieldsToPerformOperation = [...showingFields, updatedField]

      let fields = []
      if (updatedField?.answer) {
        const keys = keysOfFieldsToKeep(fieldsToPerformOperation)

        const finalFields = keys.reduce((acc, key) => {
          const field = fieldsToPerformOperation.find(x => x?.key === key)
          if (field) {
            acc.push(field)
          } else {
            const field = allFields?.find(x => x?.key === key)
            if (field) acc.push(field)
          }
          return acc
        }, [])
        fields = getCorrectFields(finalFields, keys)
        // To remove fields
      } else {
        const keys = keysOfFieldsToRemove(fieldsToPerformOperation, deletedField)
        fields = [...fieldsToPerformOperation]
        keys.forEach(key => {
          const findFieldIndex = fields.findIndex(x => x?.key === key)
          if (findFieldIndex > -1) {
            fields.splice(findFieldIndex, 1)
          }
        })
      }

      const fieldsToTick = updatedField?.fieldsToTick
      fields = fields.map(field => {
        if (fieldsToTick?.length && fieldsToTick?.includes(field?.fieldName)) {
          field.answer = updatedField?.answer
          field.dependentCheckboxField = updatedField?.fieldName
        }
        return field
      })

      const savedFormDataNew = cloneDeep(savedFormData)

      if (["asset1"].includes(formsKey[0])) {
        update(savedFormDataNew, `${formsKey[0]}.assetDetails[1].answer`, () => currentVisibleFields?.[0]?.[1]?.answer)
      }
      if (["asset2", "asset3", "asset4"].includes(formsKey[0])) {
        update(savedFormDataNew, `${formsKey[0]}.assetDetails[1]`, () => currentVisibleFields?.[1]?.[1])
      }

      fields = fields.filter((field, i, self) => {
        return checkFieldVisibilityCondition(field, savedFormDataNew, self)
      })
      if (formsKey?.[0] === "disclosure" && !updatedField?.fieldsToTick) {
        const updatedFormWithOutFirst = updatedForm.slice(1)
        let AllSelected = false
        const checkedAll = updatedFormWithOutFirst.map(field => {
          if (field?.answer) {
            AllSelected = true
          } else {
            AllSelected = false
          }
          return AllSelected
        })
        const findIndexToAll = updatedForm?.findIndex(x => x?.fieldName === "acknowledgeAllBelow")
        if (!checkedAll.includes(false)) {
          fields[findIndexToAll].answer = true
        } else {
          fields[findIndexToAll].answer = false
        }
      }

      // START   -------------------------------------------------------------- Copy fields data (same address as above)
      if (updatedField?.copyFields) {
        fields = copyFieldAnswer(
          updatedField?.answer,
          fields,
          updatedField?.copyFields?.from,
          updatedField?.copyFields?.to,
        )
      }
      // END     -------------------------------------------------------------- Copy fields data (same address as above)
      if (areFieldsCorrect(subFormsKey[index], fields)) {
        const updatedFieldsToShow = update(currentVisibleFields, index, () => fields)
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.UPDATE_FIELDS_TO_SHOW,
          payload: updatedFieldsToShow,
        })
      }
    }
  }
  // it'll get called when form gets updated
  // useCase: to push next question in array based on answer selected
  const formUpdateListener = (updatedForm, index, updatedField, isRepeater) => {
    disableEffect.current = false
    isFieldUpdated.current = true
    if (updatedField?.fieldName !== "sameAssetDetailsAs" && updatedField?.type !== "formula")
      isForceUpdateEnable.current = true
    updateFieldsToShowOnAnswerSet(updatedField, index, isRepeater, updatedForm)
    // START   -------------------------------------------------------------- To copy forms (ex. copy asset1 forms to asset2)
    if (updatedField?.copyForms) {
      // ONLY FOR ASSET COPY
      if (typeof updatedField.copyForms.formName === "string" && updatedField.copyForms.formName.includes("asset")) {
        isForceUpdateEnable.current = false
        copyAsset(updatedField, updatedForm, index)
        // ONLY FOR VENDOR DETAILS
      } else if (isObject(updatedField.copyForms.formName)) {
        isForceUpdateEnable.current = false
        copyVendor(updatedField, updatedForm, index)
      }
    }
    // END     -------------------------------------------------------------- To copy forms (ex. copy asset1 forms to asset2)
  }
  // START   -------------------------------------------------------------- For navigation
  const runValidations = () => {
    // will call all validate function in array and return single boolean
    validateFields.length = fieldsToShow.length

    const isFormDataValid = validateFields.reduce((prev, current) => prev && current(), true)
    // const isFormDataValid = true
    return isFormDataValid
  }

  useEffect(() => {
    fieldsToShow.forEach(field => {
      field.forEach(item => {
        if (item?.preFilled === true) {
          setIsPrefilled(true)
        }
        return ""
      })
      return ""
    })
  }, [isPrefilled, fieldsToShow])

  const handleNavigation = async (isNext, saveAsDraft = false, disableNextPage = false) => {
    try {
      disableEffect.current = !isNext
      isFieldUpdated.current = false
      // -------------------------------------------------------------------- Next
      if (isNext) {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.SET_NEXT_PREV_UPDATE,
          payload: true,
        })
        if (runValidations()) {
          startLoader("isApplicationCommonLoaderActiveSave")
          const formDataToSave = {}
          fieldsToShow.forEach((fields, i) => {
            const newFields =
              fields?.map?.((x, i2) => {
                let field = x
                if (x?.fields && getFormFields?.[i]?.()?.[i2]) {
                  field = getFormFields[i]()[i2]
                }
                if (
                  !("answer" in field) &&
                  stringCompare(field?.inputType, "boolean") &&
                  stringCompare(field?.type, "checkbox")
                ) {
                  field.answer = false
                }
                return field
              }) || []
            if (formsKey?.[formsKey?.length - 1]) {
              if (subFormsKey?.length) {
                if (subFormsKey[i])
                  set(formDataToSave, `${formsKey[formsKey?.length - 1]}.${subFormsKey[i]}`, newFields)
              } else {
                set(formDataToSave, formsKey[formsKey?.length - 1], newFields)
              }
            }
          })
          dispatch({
            type: APPLICATION_REDUX_CONSTANTS.ADD_SAVED_FORM_DATA,
            payload: formDataToSave,
          })
          await formPathAPICall()
          const { isZohoUpdated } = await activeSaveApiCall(disableNextPage, saveAsDraft)

          if (isEdited.current || isOnDisclosurePage || isPrefilled) {
            if (!disableNextPage && isZohoUpdated) {
              dispatch({
                type: APPLICATION_REDUX_CONSTANTS.NAVIGATE_NEXT,
              })
              isEdited.current = false
            }
          } else {
            dispatch({
              type: APPLICATION_REDUX_CONSTANTS.NAVIGATE_NEXT,
            })
            isEdited.current = false
          }

          stopLoader("isApplicationCommonLoaderActiveSave")
        } else {
          if (["disclosure"].includes(formsKey[0])) {
            dispatch({ type: APPLICATION_REDUX_CONSTANTS.SET_POPUP_FIELD_UPDATE, payload: false })
          }
        }
        // -------------------------------------------------------------------- Previous
      } else {
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.UPDATE_IS_LAST_FORM,
          payload: false,
        })
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.SET_NEXT_PREV_UPDATE,
          payload: false,
        })
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.NAVIGATE_PREVIOUS,
        })
        isEdited.current = false
        setIsPrefilled(false)
      }
    } catch (error) {
      errorNotification("Please try again")
      // eslint-disable-next-line no-console
      console.error("Error occured in navigation:", error)
    }
  }
  // END     -------------------------------------------------------------- For navigation
  const getCurrentPath = index => {
    let path = [...formsKey]

    if (subFormsKey?.length) {
      path = [...path, subFormsKey[index]]
    }

    return path
  }
  // END     ---------------------------------------------------- Functions
  const applicationCompletionButton = [
    {
      title: "Save As Draft",
      buttonType: "secondary",
      className: `smallButton`,
      onClick: () => {
        handleNavigation(true, true)
      },
    },
    {
      title: "Submit Application",
      buttonType: "secondary",
      className: `smallButton mar4left`,
      onClick: () => {
        handleNavigation(true)
      },
    },
  ]
  return (
    <Fragment>
      <section className="application-container">
        {authStatus && (
          <div className="breadcrumb">
            <span className="breadcrumbClick" onClick={() => history.push(ROUTE_CONSTANTS_VARIABLE.APPLICATION_LIST)}>
              Application List
            </span>
            <span className="material-icons-round">navigate_next</span>
            <span>Application</span>

            {formTitlesMain?.["0"] && (
              <>
                <span className="material-icons-round">navigate_next</span>
                <span>{formTitlesMain[0]}</span>
              </>
            )}
          </div>
        )}
        <div className="application-tool-form">
          <div className="application-tool-form-content">
            {fieldsToShow?.length > 0 &&
              fieldsToShow.map((form, i) => {
                return (
                  <FormWrapper
                    key={i}
                    index={i}
                    isNextOrPrev={isNextOrPrev}
                    title={formTitles[i]}
                    formFields={cloneDeep(form)}
                    formPath={getCurrentPath(i)}
                    formUpdateListener={formUpdateListener}
                    validateFields={registerCallback}
                    getFormFields={registerGetFormFieldsCallback}
                  />
                )
              })}
          </div>
        </div>
        {/* <FileUpload multiple={false} name="example-upload" maxSize={300000} onUpload={""} label="Upload Files" /> */}
      </section>

      <ApplicationFooter
        handleNextClick={() => handleNavigation(true)}
        handlePreviousClick={() => handleNavigation(false)}
      />
      <Loader isLoading={isApplicationCommonLoader || isApplicationCommonLoaderActiveSave} />
      <QuickAccessMenuDrawer runValidations={runValidations} handleNavigation={handleNavigation} />
      {isOnDisclosurePage && (
        <Modal
          header="Application Confirmation"
          buttons={applicationCompletionButton}
          className="confirmation-modal"
          bodyClassName="just-center"
          footerClassName="just-center equalWidthButton flex-direction-column"
          isOutSideClickAllowed={false}
        >
          <div className="confirmation-text">
            By Submitting this application, you understand that you will not be able to edit it further and you agree to
            all information being true and correct, or would you like to Save As A Draft?
          </div>
        </Modal>
      )}
    </Fragment>
  )
}
export default ApplicationPage
