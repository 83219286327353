import { isEqual, uniq } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { formatAnswerForKey, isObject } from "../../../helpers/applicationHelper"
import { APPLICATION_REDUX_CONSTANTS } from "../redux/ApplicaitonReduxConstants"
import { applicationSelector } from "../redux/ApplicationSelector"
import { useUpdateFields } from "./useUpdateFields"

export const useMergeForm = () => {
  const dispatch = useDispatch()
  const { updateFieldsToShowAccordingToSubformsAndFirstQuestion, subformfilterByFormVisibility } = useUpdateFields()
  const { subFormsKey, fieldsToShow } = useSelector(applicationSelector)

  // START   -------------------------------------------------------------- Fix asset form sequence of forms
  const assetSubForms = [
    "commonQuestion",
    "assetDetails",
    "assetGlassGuide",
    "assetOptions",
    "assetPricingDetails",
    "assetIdentifiersDetails",
    "assetVendorDetails",
    "assetOtherDetails",
  ]
  const fixSubFormsKeySequence = subforms => {
    subforms.sort((x, y) => {
      const x2 = assetSubForms.findIndex(a => a === x)
      const y2 = assetSubForms.findIndex(a => a === y)
      if (x2 < y2) {
        return -1
      } else if (x2 > y2) {
        return 1
      } else return 0
    })
  }
  // END     -------------------------------------------------------------- Fix asset form sequence of forms

  // START   -------------------------------------------------------------- When mergeFormName is string
  const mergeFormWithString = (haveAnswer, haveMergeForm, formData) => {
    // field has answer and has mergeFormName key
    if (haveAnswer && haveMergeForm) {
      // check if subFormsKey already have that key or not
      const alreadyHaveSubformKey = subFormsKey?.includes(formData?.mergeFormName)

      // merge all subForms (including new merge form) and check for formVisibility conditions
      const updatedSubFormKey = subformfilterByFormVisibility([...subFormsKey, formData.mergeFormName])

      // If merge form is not there in subFormsKey
      if (!alreadyHaveSubformKey) {
        // update sub forms state with filter(by formvisibility) subforms
        dispatch({
          type: APPLICATION_REDUX_CONSTANTS.UPDATE_SUB_FORMS_KEY,
          payload: updatedSubFormKey,
        })

        // Add first question of subforms key in fieldsToShow
        // get fields from subformKey and add it to fieldsToShow
        updateFieldsToShowAccordingToSubformsAndFirstQuestion(updatedSubFormKey)
      }
    }
  }
  // END     -------------------------------------------------------------- When mergeFormName is string

  // START   -------------------------------------------------------------- When mergeFormName is an array
  const mergeFormWithObject = (formData, isForceUpdateEnable) => {
    // self explanatory
    let subFormsToPush = []
    let subFormsToRemove = []

    // Loop throught array of mergeformname

    formData.mergeFormName.forEach(mergeForm => {
      // All fields of subform which is mentioned in mergeForm
      let fieldToSearch
      // If not found then search in current form
      if (!fieldToSearch) {
        // find subform which is mentioned in mergeForm object
        const index = subFormsKey?.findIndex(subform => subform === mergeForm.formName)
        // copy fields to show of that subform into fieldToSearch
        if (index > -1) fieldToSearch = fieldsToShow[index]
      }

      // If fieldToSearch has fields
      if (fieldToSearch?.length > 0) {
        // Find field which is mentioned in mergeForm object
        const foundField = fieldToSearch.find(y => y?.fieldName === mergeForm?.fieldName)
        // START   -------------------------------------------------------------- SubForms to remove
        if (mergeForm?.value) {
          // mergeForm.value contains mergeForms that needs to be merged
          // so if its object get all values of object and store it in subFormsToRemove
          // if its array the get array and store it uniquely in subFormsToremove
          if (isObject(mergeForm?.value)) {
            Object.values(mergeForm.value).forEach(x => {
              subFormsToRemove = uniq([...subFormsToRemove, ...x].flat())
            })
          } else if (Array.isArray(mergeForm?.value)) {
            subFormsToRemove = uniq([...subFormsToRemove, ...mergeForm.value].flat())
          }
        }
        // END     -------------------------------------------------------------- SubForms to remove
        // START   -------------------------------------------------------------- SubForms to add
        // If field which is mentioned in mergeForm has value and mergeForm has mergeForms list
        if (foundField?.answer && mergeForm?.value) {
          // if its array add values to subformsToPush
          // if its object add values to subFormsToPush
          if (Array.isArray(mergeForm?.value)) {
            subFormsToPush.push(mergeForm.value)
          } else if (isObject(mergeForm?.value)) {
            subFormsToPush.push(mergeForm.value[formatAnswerForKey(foundField.answer)])
          }
        }
        // END     -------------------------------------------------------------- SubForms to add
      }
    })
    // Flatten the array

    subFormsToPush = subFormsToPush.flat()
    // filter subforms by checking if its inside subFormsToPush
    subFormsToRemove = subFormsToRemove.filter(x => !subFormsToPush.includes(x))

    // if form to remove is assetGlassGuide then also remove assetOptions as they're depedent on each other

    if (subFormsToRemove.includes("assetGlassGuide") && !subFormsToRemove.includes("assetOptions")) {
      subFormsToRemove.push("assetOptions")
    }
    // Check if all subFormsToPush is already inside subForms state
    // check if subFormsRemove actually reside in subforms state
    if (
      (subFormsToPush?.length > 0 && !subFormsToPush.every(x => subFormsKey.includes(x))) ||
      (subFormsToRemove?.length > 0 && subFormsToRemove.some(x => subFormsKey.includes(x)))
    ) {
      // clone subFormsKey and fieldsToShow so that it don't actually mutate original state
      let updatedSubFormKey = [...subFormsKey]
      let updatedFieldsToShow = [...fieldsToShow]

      // Find indexes to remove in subForms state
      const removeIndexes = subFormsKey.reduce((acc, subform, i) => {
        if (subFormsToRemove.includes(subform)) {
          acc.push(i)
        }
        return acc
      }, [])

      // If indexes found then remove those subForms and fieldsToShow
      if (removeIndexes?.length > 0) {
        updatedSubFormKey = updatedSubFormKey.filter((x, i) => !removeIndexes.includes(i))
        updatedFieldsToShow = updatedFieldsToShow.filter((x, i) => !removeIndexes.includes(i))
      }

      // if there are some subForms to push
      if (subFormsToPush?.length > 0) {
        // if forms of subforms to push is already in subForms state
        const removeIndex = []
        updatedSubFormKey.forEach((x, i) => {
          if (subFormsToPush.includes(x)) {
            removeIndex.push(i)
          }
        })
        // If indexes found then remove those subForms and fieldsToSho
        if (removeIndex?.length > 0) {
          updatedSubFormKey = updatedSubFormKey.filter((x, i) => !removeIndex.includes(i))
          updatedFieldsToShow = updatedFieldsToShow.filter((x, i) => !removeIndex.includes(i))
        }
        // update with old and new subFormsToPush
        updatedSubFormKey = [...updatedSubFormKey, ...subFormsToPush]
      }
      // if new updated FieldsToShow and old fieldsToShow are not equal then update fieldsToShow state
      // console.log("refresh 3")
      if (!isEqual(updatedFieldsToShow, fieldsToShow) && isForceUpdateEnable.current) {
        // console.log("innerForm")
        dispatch({ type: APPLICATION_REDUX_CONSTANTS.UPDATE_FIELDS_TO_SHOW, payload: updatedFieldsToShow })
      }

      // ceck subform visibility
      updatedSubFormKey = subformfilterByFormVisibility(updatedSubFormKey)

      // fix sequence of asset if exists
      fixSubFormsKeySequence(updatedSubFormKey)

      const sameVendorDetailsAsField = updatedFieldsToShow?.flat()?.find(x => x?.fieldName === "sameVendorDetailsAs")
      let additionalSubforms = []
      if (
        sameVendorDetailsAsField &&
        sameVendorDetailsAsField?.answer &&
        sameVendorDetailsAsField?.copyForms?.fieldsToCopy?.length > 0
      ) {
        additionalSubforms = sameVendorDetailsAsField?.copyForms?.fieldsToCopy
      }
      dispatch({
        type: APPLICATION_REDUX_CONSTANTS.UPDATE_SUB_FORMS_KEY,
        payload: uniq([...updatedSubFormKey, ...additionalSubforms]),
      })
    }
  }
  // END     -------------------------------------------------------------- When mergeFormName is an array

  return { mergeFormWithString, mergeFormWithObject }
}
