import { cloneDeep, isArray, isEmpty, last, merge, set, sortBy, uniqBy, unset, update } from "lodash"
import moment from "moment"
import { DEFAULT_DATE_FORMAT } from "../constants/Constant"
import { ASSET, GLASS_GUIDE } from "../screens/application/ApplicationPage/ApplicationPageConstants"
import { clean, formatAnswerForKey, getCloneDeep, isObject, isValidAnswer, uniqFields } from "./applicationHelper"
import {
  AssetChange,
  AssetCommonQuestionChange,
  BeneficiaryChange,
  MultiFieldWithSameName,
  ReferencesChange,
} from "./specialChanges"

const isIsoDate = str => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false
  const d = new Date(str)
  return d.toISOString() === str
}

const isOptionsHaveAnswer = (options, answer) => {
  if (isArray(options) && options?.length > 0 && isValidAnswer(answer)) {
    const isNumber = options.every(x => typeof x === "number")
    if (isNumber) {
      return options.includes(parseInt(answer))
    } else {
      return options.includes(answer?.toString?.())
    }
  }
  return false
}

export const fixSavedData = finalData => {
  if (finalData && isObject(finalData)) {
    Object.entries(finalData).forEach(([key, value]) => {
      if (value && isObject(value)) {
        Object.entries(value).forEach(([key2, value2]) => {
          if (value2 && isObject(value2) && value2?.fields) {
            finalData[key][key2] = value2?.fields
          }
        })
      }
    })
  }
  return finalData
}

export const restructureAllGetAPIData = data => {
  const clonedData = cloneDeep(data)
  // because same data needed as multiple arguments so built wrapper arount it
  const restructureAllData = (t, payload, finalData, path = "") => {
    const allData = cloneDeep(t)
    // if its array call functions recursively
    if (Array.isArray(payload)) {
      payload.forEach((x, i) => {
        restructureAllData(allData, x, finalData, path.concat(`[${i}]`))
      })
    } else if (isObject(payload)) {
      // if its object call it recursively
      if (Object.entries(payload).length > 0) {
        Object.entries(payload).forEach(([key, value]) => {
          // if key is formName and path dont include that value
          // which means its pointing to other form
          if (key === "sameAssetDetailsAs" || key === "sameVendorDetailsAs") {
            const newPath = new AssetCommonQuestionChange().structureChange(path, key, allData)
            set(finalData, newPath, value)
          }
          if (key === "formName") {
            if (!path.includes(value)) {
              // BENEFICIARY SPECIAL CODE FOR RESTRUCTURING ITS UNIQUE DATA
              if (path.includes("beneficiaryDetails.beneficiaryData")) {
                new BeneficiaryChange().structureChange(path, value, allData, (newPath, deletePath) => {
                  // generate new object with modified name and get apidata from that path
                  set(finalData, newPath, getCloneDeep(allData, path))
                  unset(finalData, deletePath)
                })
                // REFERENCES SPECIAL CODE FOR RESTRUCTURING ITS UNIQUE DATA
              } else if (path.includes("references.referencesData")) {
                new ReferencesChange().structureChange(path, allData, value, newPath => {
                  // generate new object with modified name and get apidata from that path
                  set(finalData, newPath, getCloneDeep(allData, path))
                  unset(finalData, path)
                })
              } else {
                // split current path and check if path array contains any array identifier
                const newPath = path.split(".").reduce((acc, x) => {
                  if (x.includes("[") && x.includes("]")) {
                    // if its array join path by . to generate path to formName
                    // return data inside it
                    const dataPath = [...acc, x].join(".")
                    const lastData = getCloneDeep(allData, `${dataPath}.formName`, x)
                    return [...acc, lastData]
                  }
                  // if its not array return default data
                  return [...acc, x]
                }, [])
                // generate new object with modified name and get apidata from that path
                update(finalData, new AssetChange().changePath(newPath), x => ({
                  ...x,
                  ...getCloneDeep(allData, path),
                }))
                // delete old objects
                unset(finalData, path)
              }
            } else {
              if (!path.includes(".") && path.includes("[") && path.includes("]")) {
                const newPath = path.split("[")[0]
                update(finalData, newPath, x => ({ ...x, ...getCloneDeep(allData, path) }))
                unset(finalData, path)
              }
            }
          }
          restructureAllData(allData, value, finalData, path.concat(path ? `.${key}` : key))
        })
      }
    }
    return finalData
  }
  // unset keeps undefined as value so to remove key i used custom clean functions to clean all nullish values
  return clean(restructureAllData(clonedData, clonedData, clonedData))
}
// To convert restructured api data to savedFormData redux state structure

export const convertAPIDataToSavedFormData = (apiData, applicationJSON, finalData = {}, path = "") => {
  const normalValue = (data, fieldKey, fieldValue) => {
    if (data?.fields?.length > 0) {
      const field = data.fields.find(x => {
        let returnValue = x?.fieldName === fieldKey

        if (returnValue && x?.fieldName === "purchaseTimeframe") {
          returnValue = !!new MultiFieldWithSameName().duplicateFieldNameCommon(finalData, path, x, "assetCondition")
        }
        if (returnValue && x?.fieldName === "vendor") {
          returnValue = !!new MultiFieldWithSameName().duplicateFieldNameCommon(finalData, path, x, "purchaseTimeframe")
        }
        // START   -------------------------------------------------------------- Multiple finance type
        if (returnValue && x?.fieldName === "financeType") {
          returnValue = !!new MultiFieldWithSameName().duplicateFieldNameCommon(finalData, path, x, "applicantType")
        }
        // END     -------------------------------------------------------------- Multiple finance type
        // START   -------------------------------------------------------------- Multiple employer start date
        if (returnValue && x?.fieldName === "employerStartDate") {
          returnValue = !!new MultiFieldWithSameName().employerStartDate(apiData, applicationJSON, path, x)
        }
        // END     -------------------------------------------------------------- Multiple employer start date
        // START   -------------------------------------------------------------- Multiple finance type
        if (returnValue && x?.fieldName === "assetCondition") {
          returnValue = !!new MultiFieldWithSameName().duplicateFieldNameCommon(finalData, path, x, "assetType")
        }
        // END     -------------------------------------------------------------- Multiple finance type
        // START   --------------------------------------------------------------Company structure based on fieldVisibility
        if (returnValue && x?.fieldName === "companyStructure") {
          returnValue = !!new MultiFieldWithSameName().companyStructureBasedOnFieldVisibility(finalData, path, x)
        }
        // END     --------------------------------------------------------------Company structure based on fieldVisibility
        // added because there might be multiple fields with same fieldName
        // so checking if there is options and answer is inside those options then use that field
        if (x?.options?.length > 0 && isValidAnswer(fieldValue) && returnValue) {
          returnValue = isOptionsHaveAnswer(x.options, fieldValue)
          const haveAnswerInOptions = isOptionsHaveAnswer(x.options, fieldValue)
          const finalDataWithpath = getCloneDeep(finalData, path)
          if (haveAnswerInOptions && finalDataWithpath?.length > 0) {
            const nextQuestions = finalDataWithpath
              .reduce((acc, field) => {
                if (Array.isArray(field?.nextQuestions)) {
                  acc.push(field.nextQuestions)
                }
                if (isObject(field?.nextQuestions) && field?.answer) {
                  acc.push(field.nextQuestions[formatAnswerForKey(field.answer)])
                }
                return acc
              }, [])
              .flat(1)
            returnValue = nextQuestions.includes(x?.key) || returnValue
          }
        }
        return returnValue
      })
      if (!isEmpty(field)) {
        field.answer = fieldValue
        const defaultFields = data.fields.filter(x => x?.defaultQuestion)
        let myFields = uniqBy([...defaultFields, field], "key")
        myFields = myFields?.fields || myFields
        // sorting fields based on key (Q1,Q2) because sequence might be different as its getting fields from data
        update(finalData, path, value => {
          let finalValue = value?.fields ? value?.fields : value
          if (Array.isArray(finalValue)) {
            finalValue = [...finalValue, ...myFields]
          } else if (isObject(finalValue)) {
            finalValue = [...Object.values(finalValue), ...myFields]
          } else {
            finalValue = [...myFields]
          }
          return sortBy(uniqFields(finalValue), "key")
        })
      }
    }
  }
  const repeaterValue = (fieldKey, fieldValue) => {
    // temporarily splitting path to pop out last element
    // last element is fieldname inside fields so it'll be used to catch that data
    const temp = path.split(".")
    const poppedPath = temp.pop().replace(/\[.*?\]/, "")
    const newPath = temp.join(".")

    // getting data from applicationJSON to add answer in it
    const getRepeaterData = getCloneDeep(applicationJSON, newPath)
    if (getRepeaterData?.fields?.length > 0) {
      // find repeater field using popped element of path and type repeater
      const repeaterField = getRepeaterData.fields.find(x => x?.fieldName === poppedPath && x?.type === "repeater")
      if (repeaterField) {
        // to take array index from path of popped element
        // it'll be used to add data in 2d array as JSOn will have only one element in array if its repeated it'll create multiple elements
        const arrayIndex = path.match(/\[.*?\]/)[0]

        // update final data call with path
        update(finalData, newPath, v2 => {
          // cloning because it was giving same answer for all questions
          let value = cloneDeep(v2)

          // if value is empty fill it with object of repeater
          if (isEmpty(value)) {
            value = getRepeaterData
          }
          const fieldsToRenderFieldChange = (repeaterData, firstIndex, secondIndex) => {
            if (
              !repeaterData?.fields?.[firstIndex]?.fields?.[secondIndex] &&
              repeaterData?.fields?.[firstIndex]?.fieldsToRender?.[secondIndex]
            ) {
              set(
                repeaterData,
                `fields[${firstIndex}].fields[${secondIndex}]`,
                getCloneDeep(repeaterData, `fields[${firstIndex}].fieldsToRender`),
              )
            }
            return repeaterData
          }
          // find index of field inside repeater fields
          const modifiedValue =
            value?.fieldsToRender?.length > 0 && value?.fields?.length === 0
              ? [...value.fieldsToRender]
              : [...value.fields]
          const index = modifiedValue.findIndex(x => x?.fieldName === repeaterField?.fieldName)

          if (index >= 0) {
            // need to set value if index is not 0
            // because data at 0 index will be there because its coming from JSON
            // but need to set same data at new index so we can put answer in it
            if (!arrayIndex.includes("0")) {
              const newRepeaterData = fieldsToRenderFieldChange(getRepeaterData, index, 0)

              update(value, `fields[${index}].fields[${arrayIndex}]`, v => {
                let returnValue = merge(v, getCloneDeep(newRepeaterData, `fields[${index}].fields[0]`))
                if (isObject(returnValue)) {
                  returnValue = Object.values(returnValue)
                }
                return returnValue
              })
            }
            // find actual field which is inside fields of fields
            const newValue = fieldsToRenderFieldChange(value, index, 0)
            // code added for repeater same form fields
            if (["incomeOwnership", "outgoingOwnership", "assetOwnership", "liabilitiesOwnership"].includes(fieldKey)) {
              const updatedValueIndex = getCloneDeep(newValue, `fields[${index}].fields[${arrayIndex}]`)
              const arrayAppearance = getAllIndexes(updatedValueIndex, fieldKey)
              if (arrayAppearance?.length > 0) {
                for (let i = 0; i < arrayAppearance?.length; i++) {
                  set(value, `fields[${index}].fields${arrayIndex}[${arrayAppearance[i]}].answer`, fieldValue)
                }
              }
              // code added for repeater same form fields end
            } else {
              const updatedValueIndex = getCloneDeep(newValue, `fields[${index}].fields[${arrayIndex}]`)?.findIndex?.(
                x => x?.fieldName === fieldKey,
              )
              if (updatedValueIndex >= 0) {
                // update actual field with answer
                set(value, `fields[${index}].fields${arrayIndex}[${updatedValueIndex}].answer`, fieldValue)
              }
            }
          }
          return value
        })
      }
    }
  }
  const getAllIndexes = (arrayElement, Element) => {
    return arrayElement.reduce(function(a, e, i) {
      if (e?.fieldName === Element) a.push(i)
      return a
    }, [])
  }
  const containsObjectInValue = (fieldKey, fieldValue) => {
    const lastFieldName = last(path.split("."))
    const newPath = path
      .split(".")
      .slice(0, -1)
      .join(".")
    const objectInsideFieldData = getCloneDeep(applicationJSON, newPath)
    if (objectInsideFieldData?.fields?.length > 0) {
      const objectIndex = objectInsideFieldData.fields.findIndex(x => x?.fieldName === lastFieldName)
      if (objectIndex > -1 && objectInsideFieldData.fields[objectIndex]?.fields?.length > 0) {
        const fieldIndex = objectInsideFieldData.fields[objectIndex].fields.findIndex(x => x?.fieldName === fieldKey)
        if (fieldIndex > -1) {
          objectInsideFieldData.fields[objectIndex].fields[fieldIndex].answer = fieldValue
        }
        update(finalData, newPath, v => {
          const oldFields = Array.isArray(v) ? v : v?.fields
          const oldData = isObject(v) ? v : {}
          return {
            ...oldData,
            ...(objectInsideFieldData || {}),
            fields: merge(oldFields || [], objectInsideFieldData?.fields || []),
          }
        })
      }
    }
  }
  // loop through api data
  Object.entries(apiData).forEach(([k, v2]) => {
    let v = isIsoDate(v2) ? moment(v2).format(DEFAULT_DATE_FORMAT) : v2

    // Special case: change asset age type to string because sometimes it comes as number from backend
    v = new AssetChange().typeCastAssetAge(k, v)

    // if value is object or array keep calling recursive function
    // while also maintaining path to know where it came from
    if (
      (isObject(v) || Array.isArray(v)) &&
      ![ASSET.ASSET_MANUFACTURER_OPTIONS, GLASS_GUIDE.MANUFACTURER_OPTIONS].includes(k)
    ) {
      // if key is number path will be array else it'll be object
      const newPath = path.concat(!isNaN(parseInt(k)) ? `[${k}]` : `${path ? "." : ""}${k}`)
      convertAPIDataToSavedFormData(v, applicationJSON, finalData, newPath)
    } else if (path) {
      // if its not array or object then get data from path
      const getData = getCloneDeep(applicationJSON, path)

      // ----------------------------------------------------------For normal values
      if (getData) {
        // console.log("normal fields", path, k, v)
        normalValue(getData, k, v)

        // ----------------------------------------------------------For repeater values
      } else if (path.includes("[") && path.includes("]")) {
        // console.log("repeater fields:", path, k, v)
        repeaterValue(k, v)

        // ----------------------------------------------------------For contains object eg address,fullName which contains fields inside
      } else {
        // console.log("contains object", path, k, v)
        containsObjectInValue(k, v)
      }
    }
  })
  return finalData
}

export const fieldVisibilityWithoutFormname = (data, path = "") => {
  if (data && (Array.isArray(data) || typeof data === "object")) {
    for (const key in data) {
      const value = data[key]
      if (
        typeof value === "object" &&
        path.includes("fieldVisibility") &&
        !value?.formName &&
        value?.subFormName &&
        value?.fieldName &&
        value?.value
      ) {
        data[key].formName = path.split(".").shift()
      } else {
        fieldVisibilityWithoutFormname(value, path ? path.concat(`.${key}`) : key)
      }
    }
  }
}
