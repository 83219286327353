import { useRef } from "react"
import PropTypes from "prop-types"
import Button from "../Button/Button"
import { useOnClickOutside } from "../../hooks/UserClickOutsideHook"

const Modal = props => {
  const {
    header,
    headerIcon,
    buttons,
    children,
    headerClassName,
    bodyClassName,
    footerClassName,
    className,
    hideModal,
    onClick,
    closeIcon,
    closeClassName,
    isOutSideClick,
    isOutSideClickAllowed,
    ...restProps
  } = props
  const dialogContentClass = `modal-content ${className}`
  const dialogHeaderClass = `modal-header ${headerClassName}`
  const dialogBodyClass = `modal-body ${bodyClassName}`
  const dialogFooterClass = `modal-footer ${footerClassName}`
  const closeClass = `material-icons-outlined cursor-pointer ${closeClassName}`
  const modalRef = useRef()
  useOnClickOutside(modalRef, () => {
    if (isOutSideClickAllowed) hideModal(isOutSideClick)
  })

  return (
    <div className="modal">
      <div className={dialogContentClass} ref={modalRef} {...restProps}>
        <div className={dialogHeaderClass}>
          <div className={`d-flex align-center ${closeIcon ? "just-bet" : "just-center"}`}>
            <div>
              {headerIcon && (
                <div className="d-flex just-center align-center">
                  <span className="material-icons-outlined mr-5">{headerIcon}</span>
                  {header}
                </div>
              )}
              {!headerIcon && header}
            </div>
            {closeIcon && (
              <span title="Close" className={closeClass} onClick={() => onClick(false)}>
                {closeIcon}
              </span>
            )}
          </div>
        </div>
        <div className={dialogBodyClass}>{children}</div>
        <div className={dialogFooterClass}>
          {buttons.map((e, index) => (
            <Button key={index.toString()} type="button" className="modal-footer-buttons" {...e} />
          ))}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  header: PropTypes.string,
  buttons: PropTypes.array,
  className: PropTypes.string,
  headerIcon: PropTypes.string,
  headerClassName: PropTypes.string,
  bodyClassName: PropTypes.string,
  footerClassName: PropTypes.string,
  hideModal: PropTypes.func,
  onClick: PropTypes.func,
  closeIcon: PropTypes.string,
  closeClassName: PropTypes.string,
  children: PropTypes.node,
  isOutSideClickAllowed: PropTypes.bool,
  isOutSideClick: PropTypes.bool,
}

Modal.defaultProps = {
  header: "",
  buttons: [],
  headerIcon: "",
  className: "",
  headerClassName: "",
  bodyClassName: "",
  footerClassName: "",
  closeIcon: "",
  closeClassName: "",
  children: null,
  hideModal: () => {},
  isOutSideClick: false,
  isOutSideClickAllowed: true,
}

export default Modal
