export const addQueryParameter = (name, value) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  urlSearchParams.set(name, value)
  const newUrl = window.location.pathname + "?" + urlSearchParams.toString()
  window.history.pushState({ path: newUrl }, "", newUrl)
}

export const getQueryParams = queryParamKey => {
  const urlSearchParams = new URLSearchParams(window.location.search)

  for (const [key, value] of urlSearchParams) {
    if (queryParamKey === key) return value
  }
}
