import _ from "lodash"
import {
  checkBusinessSearch,
  checkCarSearch,
  getNextQuoteForm,
  handleBackCLick,
  handleNextCLick,
  handleOtherQuestionOnSamePage,
  handleQuestionErrorMessage,
  updatePreviousQuestionDetails,
  updatePreviousQuestionDetailsManual,
  updateQuoteAnswers,
} from "../screens/quote/redux/QuoteActions"

import { activeSave, checkEquifaxShow } from "../helpers/QuoteActiveSavingHelper"

import { handleSubmitEquifax } from "../screens/auth/EquifaxPage/redux/EquifaxActions"

// import { defaultAssetYears } from "../constants/DefaultDropDownOptions"
import { ROUTE_CONSTANTS_VARIABLE } from "../routes/RouteConstants"

export const handleValidation = async (currentQuoteData, quoteAnswersData, dropDownOptions, currentForm, dispatch) => {
  const { fieldName, type, fields, errorField } = currentQuoteData ?? {}
  const answer = quoteAnswersData?.[currentForm]?.[fieldName]
  const errorMessages = []
  let valueToPush = {}
  const quoteMessageFromType = (fieldName, type, errorField) => {
    switch (type) {
      case "radio":
      case "button":
      case "dropdown":
      case "dropdownForGlassGuide":
      case "regoState":
        valueToPush = {}
        valueToPush.fieldName = fieldName
        valueToPush.errorMessage = `Please make a choice of ${errorField}`
        errorMessages.push(valueToPush)
        break

      case "text":
      case "search":
      case "amount":
        valueToPush = {}
        valueToPush.fieldName = fieldName
        valueToPush.errorMessage = `Please enter valid answer for ${errorField}`
        errorMessages.push(valueToPush)
        break

      default:
        valueToPush = {}
        valueToPush.fieldName = fieldName
        valueToPush.errorMessage = `Please select/enter valid answer for ${errorField}`
        errorMessages.push(valueToPush)
        break
    }
  }

  const checkFieldValidation = field => {
    const { required, inputType, type, minLimit, maxLimit, fieldName, placeholder, errorField, editable } = field
    const answer = quoteAnswersData?.[currentForm]?.[fieldName]
    const name = errorField || placeholder || ""
    if (required && !answer && editable) {
      quoteMessageFromType(fieldName, type, name)
    } else if (answer && minLimit && answer?.toString()?.trim()?.length < minLimit) {
      valueToPush = {}
      valueToPush.fieldName = fieldName
      valueToPush.errorMessage = `The minimum limit for ${name} is ${minLimit} characters`
      errorMessages.push(valueToPush)
    } else if (
      answer &&
      maxLimit &&
      answer?.toString()?.replace(/ +/g, "")?.length > maxLimit &&
      fieldName !== "balloon"
    ) {
      valueToPush = {}
      valueToPush.fieldName = fieldName
      valueToPush.errorMessage = `The maximum limit for ${name} is ${maxLimit} characters`
      errorMessages.push(valueToPush)
    } else if (["amount", "number"].includes(type || inputType) && editable && isNaN(answer)) {
      valueToPush = {}
      valueToPush.fieldName = fieldName
      valueToPush.errorMessage = `Amount must be greater than 0`
      errorMessages.push(valueToPush)
    } else if (field?.fieldName === "abn") {
      const answerVal = answer ? Number(answer?.toString()?.replace(/ +/g, "")) : 0
      if (isNaN(answerVal)) {
        valueToPush = {}
        valueToPush.fieldName = fieldName
        valueToPush.errorMessage = `Please enter valid answer for ${name}`
        errorMessages.push(valueToPush)
      }
    }
    if (["string", "text"].includes(type || inputType) && editable) {
      if (fieldName === "balloon") {
        const format = /[`!@#$%^&*()_+\-={};':"|,<>?~]/
        if (format.test(answer)) {
          valueToPush = {}
          valueToPush.fieldName = fieldName
          valueToPush.errorMessage = `Please enter valid answer for ${name}`
          errorMessages.push(valueToPush)
        }
      } else {
        const format = /[`!@#$%^&*()_+\-={};':"|,.<>?~]/

        if (format.test(answer)) {
          valueToPush = {}
          valueToPush.fieldName = fieldName
          valueToPush.errorMessage = `Please enter valid answer for ${name}`
          errorMessages.push(valueToPush)
        }
      }
    }

    if (field?.type === "amount" && field?.fieldName !== "toBeFinanced") {
      const answerVal = answer ? Number(answer) : 0
      if (answerVal === 0) {
        errorMessages.push()
        valueToPush = {}
        valueToPush.fieldName = fieldName
        valueToPush.errorMessage = `Amount must be greater than 0`
        errorMessages.push(valueToPush)
      }
    }
  }

  const checkOptionsValidation = field => {
    const { fieldName, errorField, inputType, type } = field ?? {} // fieldToMatchOptions
    const answer = quoteAnswersData?.[currentForm]?.[fieldName] ?? ""

    // const getOptions = question => {
    //   const { type, isDefaultYearOptions, previousYears, option } = question ?? {}
    //   if (type === "dropdownForGlassGuide") {
    //     return dropDownOptions?.glassGuideOptions?.map(e => e?.label)
    //   } else if (isDefaultYearOptions) {
    //     return defaultAssetYears(previousYears)?.map(e => e?.label)
    //   } else {
    //     return option
    //   }
    // }

    if (currentQuoteData?.type === "multipleQuestions") {
      // const optionsField = currentQuoteData?.fields?.find(e => e?.fieldName === fieldToMatchOptions)
      // const options = getOptions(optionsField)
      // if (options?.includes(answer)) {
      //   valueToPush = {}
      //   valueToPush.fieldName = fieldName
      //   valueToPush.errorMessage = "Enter other than the existing values"
      //   errorMessages.push(valueToPush)
      // }

      if (Number(answer) === 0) {
        valueToPush = {}
        valueToPush.fieldName = fieldName
        valueToPush.errorMessage = "Please enter valid answer for " + errorField
        errorMessages.push(valueToPush)
      }
    } else {
      // const options = getOptions(currentQuoteData)
      // if (options?.includes(answer)) {
      //   valueToPush = {}
      //   valueToPush.fieldName = fieldName
      //   valueToPush.errorMessage = "Enter other than the existing values"
      //   errorMessages.push(valueToPush)
      // }
      if (Number(answer) === 0 && fieldName !== undefined) {
        if ((inputType === "number" || type === "amount") && fieldName !== "visaCode") {
          valueToPush = {}
          valueToPush.fieldName = fieldName
          valueToPush.errorMessage = "Amount must be greater than 0"
          errorMessages.push(valueToPush)
        } else {
          valueToPush = {}
          valueToPush.fieldName = fieldName
          valueToPush.errorMessage = "Please enter valid answer for " + errorField
          errorMessages.push(valueToPush)
        }
      }
    }
  }

  if (type === "multipleQuestions") {
    const questionToCheckFlow = fields?.find(e => e?.questionToCheckFlow)
    const otherFormCallQuestion = fields?.find(e => e?.otherForm)

    const questionToCheckFlowAnswer = quoteAnswersData?.[currentForm]?.[questionToCheckFlow?.fieldName]
    const otherFormCallAnswer = quoteAnswersData?.[currentForm]?.[otherFormCallQuestion?.fieldName]

    if (!questionToCheckFlow && !otherFormCallQuestion && fields?.length) {
      fields?.forEach(e => checkFieldValidation(e))
    }

    if (questionToCheckFlow && otherFormCallQuestion) {
      if (
        questionToCheckFlowAnswer &&
        otherFormCallAnswer &&
        otherFormCallQuestion?.fieldName !== "canNotFindWhatYouAreLookingForVehicleNote"
      ) {
        valueToPush = {}
        valueToPush.fieldName = otherFormCallQuestion?.fieldName
        valueToPush.errorMessage = "Please provide appropriate answer or select checkbox to continue"
        errorMessages.push(valueToPush)
      } else if (!otherFormCallAnswer) {
        fields?.forEach(e => checkFieldValidation(e))
      }
    }
    if (questionToCheckFlow && !otherFormCallQuestion) {
      if (!questionToCheckFlowAnswer) {
        fields?.forEach(e => checkFieldValidation(e))
      }
    }
    if (!questionToCheckFlow && otherFormCallQuestion) {
      if (!otherFormCallAnswer) {
        fields?.forEach(e => checkFieldValidation(e))
      }
    }
    if (questionToCheckFlowAnswer && questionToCheckFlowAnswer === "Other") {
      const otherQuestion = currentQuoteData?.otherField ?? {}
      checkOptionsValidation(otherQuestion)
      checkFieldValidation(otherQuestion)
    }
  } else if (
    currentQuoteData?.required &&
    !answer &&
    (currentQuoteData?.type === "button" ||
      currentQuoteData?.type === "radio" ||
      currentQuoteData?.type === "dropdown" ||
      currentQuoteData?.type === "dropdownForGlassGuide")
  ) {
    valueToPush = {}
    valueToPush.fieldName = fieldName
    valueToPush.errorMessage = `Please make a choice of ${errorField}`
    errorMessages.push(valueToPush)
  } else if (currentQuoteData?.required && !answer) {
    checkFieldValidation(currentQuoteData)
  } else if (answer === "Other" || answer === "Visa" || (answer === "Yes" && fieldName !== "signedAgreement")) {
    const otherQuestion = currentQuoteData?.otherField ?? {}
    checkOptionsValidation(otherQuestion)
    checkFieldValidation(otherQuestion)
  } else if (
    currentQuoteData?.type === "amount" ||
    currentQuoteData?.fieldName === "toBeFinanced" ||
    currentQuoteData?.fieldName === "balloonCalculation"
  ) {
    let answerVal = answer ? Number(answer) : 0
    answerVal = Math.round(answerVal)
    if (answerVal <= 0) {
      valueToPush = {}
      valueToPush.fieldName = fieldName
      valueToPush.errorMessage = `Amount must be greater than 0`
      errorMessages.push(valueToPush)
    }
    if (
      answerVal >= quoteAnswersData?.financeNumbers?.toBeFinanced &&
      currentQuoteData?.fieldName === "balloonCalculation"
    ) {
      valueToPush = {}
      valueToPush.fieldName = fieldName
      valueToPush.errorMessage = `Balloon must be less than to be financed amount`
      errorMessages.push(valueToPush)
    }
  }
  if (errorMessages.length > 0) {
    dispatch(handleQuestionErrorMessage(errorMessages))
    return false
  } else {
    if (currentForm === "carSearch" && type === "multipleQuestions") {
      let isValid = false
      const fieldList = []
      currentQuoteData?.fields.forEach(e => fieldList.push(e.fieldName))

      if (
        errorMessages.length === 0 &&
        fieldList.includes("rego") &&
        fieldList.includes("state") &&
        quoteAnswersData?.[currentForm]?.rego &&
        quoteAnswersData?.[currentForm]?.state
      ) {
        const data = {
          rego: quoteAnswersData?.[currentForm]?.rego,
          state: quoteAnswersData?.[currentForm]?.state,
          vin: quoteAnswersData?.[currentForm]?.vin,
        }

        const checkValid = async () => await dispatch(checkCarSearch(data))
        isValid = await checkValid()
      } else {
        isValid = true
      }

      return isValid
    } else if (currentForm === "employment" && type === "multipleQuestions") {
      const fieldList = []
      currentQuoteData?.fields.forEach(e => fieldList.push(e.fieldName))
      let isValid = true
      if (
        quoteAnswersData?.[currentForm]?.entityName &&
        quoteAnswersData?.[currentForm]?.abn &&
        fieldList.includes("entityName") &&
        fieldList.includes("abn")
      ) {
        if (errorMessages.length === 0) {
          const checkValid = async () => await dispatch(checkBusinessSearch(quoteAnswersData, currentForm))
          isValid = await checkValid()
        }
      }

      return isValid
    } else {
      dispatch(handleQuestionErrorMessage(errorMessages))
      return true
    }
  }
}
export const handleFieldValidation = (
  fieldItem,
  quoteAnswersData,
  currentForm,
  currentQuoteData,
  dropDownOptions,
  glassGuideData,
  glassGuideLabel,
  currentQuestion,
  previousQuestions,
  equifaxData,
  equifaxDataFinal,
  authStatus,
  userId,
  quoteId,
  isonEquifaxPage,
  history,
  quoteJsonData,
  dispatch,
) => {
  const { nextQuestions, defaultQuestions, otherField, checkDefaultForNext, fields, lastQuestionFor, error } =
    currentQuoteData ?? {}

  const { isLastQuestion } = currentQuoteData ?? {}
  // const { error } =   currentQuoteData ?? {}

  let valueToPush = {}
  let errorMessages = []
  if (error !== undefined) {
    errorMessages = [...error]
  }

  if (currentQuoteData?.type === "multipleQuestions") {
    currentQuoteData?.fields.forEach(field => {
      const { required, inputType, type, minLimit, maxLimit, fieldName, editable } = field
      const answerOfField = quoteAnswersData?.[currentForm]?.[field?.fieldName]

      if (required && answerOfField && editable) {
        switch (type) {
          case "radio":
          case "button":
          case "dropdown":
          case "dropdownForGlassGuide":
          case "regoState":
            errorMessages = errorMessages.filter(function(error) {
              return error.fieldName !== fieldName
            })
            break

          case "text":
          case "search":
          case "amount":
            errorMessages = errorMessages.filter(function(error) {
              return error.fieldName !== fieldName
            })
            break

          default:
            errorMessages = errorMessages.filter(function(error) {
              return error.fieldName !== fieldName
            })
            break
        }
      } else if (answerOfField && maxLimit) {
        if (answerOfField?.toString()?.replace(/ +/g, "")?.length === maxLimit) {
          errorMessages = errorMessages.filter(function(error) {
            return error.fieldName !== fieldName
          })
        }
      } else if (answerOfField && minLimit) {
        if (
          answerOfField?.toString()?.trim()?.length > minLimit &&
          answerOfField?.toString()?.trim()?.length === maxLimit
        ) {
          errorMessages = errorMessages.filter(function(error) {
            return error.fieldName !== fieldName
          })
        }
      } else if (["amount", "number"].includes(type || inputType) && editable && !isNaN(answerOfField)) {
        errorMessages = errorMessages.filter(function(error) {
          return error.fieldName !== fieldName
        })
      }
    })
  }

  const {
    required,
    inputType,
    type,
    minLimit,
    maxLimit,
    fieldName,
    placeholder,
    errorField,
    editable,
    isMultipleSelect,
  } = fieldItem
  const answer = quoteAnswersData?.[currentForm]?.[fieldName]
  const name = errorField || placeholder || ""

  if (required && answer && editable) {
    switch (type) {
      case "radio":
      case "button":
      case "dropdown":
      case "dropdownForGlassGuide":
      case "regoState":
        errorMessages = errorMessages.filter(function(error) {
          return error.fieldName !== fieldName
        })
        break

      case "text":
      case "search":
      case "amount":
        errorMessages = errorMessages.filter(function(error) {
          return error.fieldName !== fieldName
        })
        break

      default:
        errorMessages = errorMessages.filter(function(error) {
          return error.fieldName !== fieldName
        })
        break
    }
  } else if (answer && maxLimit) {
    if (answer?.toString()?.replace(/ +/g, "")?.length === maxLimit) {
      errorMessages = errorMessages.filter(function(error) {
        return error.fieldName !== fieldName
      })
    }
  } else if (answer && minLimit) {
    if (answer?.toString()?.trim()?.length > minLimit && answer?.toString()?.trim()?.length === maxLimit) {
      errorMessages = errorMessages.filter(function(error) {
        return error.fieldName !== fieldName
      })
    }
  } else if (["amount", "number"].includes(type || inputType) && editable && !isNaN(answer)) {
    errorMessages = errorMessages.filter(function(error) {
      return error.fieldName !== fieldName
    })
  }
  if (fieldName === "abn") {
    const answerVal = answer ? Number(answer?.toString()?.replace(/ +/g, "")) : 0
    if (!isNaN(answerVal)) {
      if (answer && maxLimit) {
        if (answer?.toString()?.replace(/ +/g, "")?.length === maxLimit) {
          errorMessages = errorMessages.filter(function(error) {
            return error.fieldName !== fieldName
          })
        }
      }
    }

    if (isNaN(answerVal)) {
      valueToPush = {}
      valueToPush.fieldName = fieldName
      valueToPush.errorMessage = `${name} field must contain valid numeric value`
      errorMessages.push(valueToPush)
    }
  }
  if (fieldName === "visaCode") {
    const answerVal = answer ? Number(answer?.toString()?.replace(/ +/g, "")) : 0
    if (!isNaN(answerVal)) {
      if (answer && maxLimit) {
        if (answer?.toString()?.replace(/ +/g, "")?.length === maxLimit) {
          errorMessages = errorMessages.filter(function(error) {
            return error.fieldName !== fieldName
          })
        }
      }
    }

    if (isNaN(answerVal)) {
      valueToPush = {}
      valueToPush.fieldName = fieldName
      valueToPush.errorMessage = `${name} field must contain valid numeric value`
      errorMessages.push(valueToPush)
    }
  }
  if (type === "amount" && fieldName === "toBeFinanced") {
    const answerVal = answer ? Number(answer) : 0
    if (answerVal !== 0) {
      valueToPush = {}
      valueToPush.fieldName = fieldName
      valueToPush.errorMessage = `${name} field must contain valid numeric value`
      errorMessages = errorMessages.filter(function(error) {
        return error.fieldName !== fieldName
      })
    }
  }

  if (errorMessages.length > 0) {
    dispatch(handleQuestionErrorMessage(errorMessages))
    return false
  } else {
    dispatch(handleQuestionErrorMessage(errorMessages))
    if (
      type === "button" ||
      type === "radio" ||
      type === "dropdown" ||
      type === "checkbox" ||
      (type === "dropdownForGlassGuide" && !isMultipleSelect)
    ) {
      const answer = quoteAnswersData?.[currentForm]?.[fieldName] ?? ""
      if (
        (answer !== "Other" && answer !== "Visa" && answer !== "Yes") ||
        (answer === "Yes" &&
          (fieldName === "signedAgreement" ||
            fieldName === "ownProperty" ||
            fieldName === "theVehicleYouAreLookingFor" ||
            fieldName === "theCarYouAreLookingFor"))
      ) {
        if (answer !== lastQuestionFor) {
          handleNextClick(
            isonEquifaxPage ? true : isLastQuestion,
            isonEquifaxPage,
            currentQuoteData,
            quoteAnswersData,
            dropDownOptions,
            currentForm,
            equifaxDataFinal,
            equifaxData,
            currentQuestion,
            authStatus,
            userId,
            quoteId,
            glassGuideData,
            glassGuideLabel,
            previousQuestions,
            isLastQuestion,
            isonEquifaxPage,
            nextQuestions,
            defaultQuestions,
            otherField,
            type === "dropdownForGlassGuide" || type === "checkbox" ? "multipleQuestions" : type,
            checkDefaultForNext,
            fields,
            fieldName,
            history,
            false,
            quoteJsonData,
            false,
            true,
            dispatch,
            false,
            false,
          )
        }
      }
    }
    return true
  }
}
export const getNextFormFromAPI = (currentForm, quoteAnswersData, dispatch, quoteJsonData, canSkip) => {
  const data = {
    [currentForm]: {
      ...quoteAnswersData?.[currentForm],
      isBlocked: false,
      isCompleted: true,
    },
  }
  dispatch(getNextQuoteForm(data, quoteJsonData, canSkip))
}
export const handleBackClick = (isonEquifaxPage, authStatus, dispatch, history) => {
  if (isonEquifaxPage) {
    // if (authStatus) {
    //  dispatch(resetQuoteReducer())
    // }
    history.goBack(-1)
  } else {
    dispatch(handleBackCLick())
    dispatch(handleOtherQuestionOnSamePage())
  }
}
export const isActiveSavingActive = (authStatus, userId) => {
  return !!(authStatus && userId)
}

export const handleNextClick = async (
  isSubmit,
  isSaveAsDraft,
  currentQuoteData,
  quoteAnswersData,
  dropDownOptions,
  currentForm,
  equifaxDataFinal,
  equifaxData,
  currentQuestion,
  authStatus,
  userId,
  quoteId,
  glassGuideData,
  glassGuideLabel,
  previousQuestions,
  isLastQuestion,
  isonEquifaxPage,
  nextQuestions,
  defaultQuestions,
  otherField,
  type,
  checkDefaultForNext,
  fields,
  fieldName,
  history,
  bypassvalidation,
  quoteJsonData,
  isEquifaxFailed,
  isFormShow,
  dispatch,
  canSkip = false,
  finalSubmit = false,
) => {
  const nextQuestion1 = [currentQuestion] // need to check here
  let isValidate = true

  if (!bypassvalidation) {
    isValidate = await handleValidation(currentQuoteData, quoteAnswersData, dropDownOptions, currentForm, dispatch)
  }
  // need to check equifax 3 week here
  if (isValidate && isActiveSavingActive(authStatus, userId)) {
    let isEquifaxActive = false
    const loanType = quoteAnswersData?.loanType?.financeWhat
    const residencyDetails = quoteAnswersData?.residency?.residencyDetails
    const employmentDetails = quoteAnswersData?.employment?.employmentStatus

    if (isLastQuestion) {
      isEquifaxActive = await checkEquifaxShow(dispatch)

      if (!isEquifaxActive) {
        if (
          (loanType !== "Asset Finance" && loanType !== "Personal Loan") ||
          residencyDetails === "Visa" ||
          employmentDetails === "Unemployed"
        ) {
          isEquifaxActive = true
          // dispatch(handleEquifaxFieldChange("isFormShow", false))
        }
      }
    }

    const { isSaved, quoteIdForRateCard } = await activeSave(
      userId,
      quoteId,
      glassGuideData,
      glassGuideLabel,
      quoteAnswersData,
      previousQuestions,
      currentForm,
      currentQuestion,
      finalSubmit,
      isEquifaxFailed,
      equifaxData?.data?.creditScore,
      dispatch,
      canSkip,
    )
    if (isSaved) {
      if (isSubmit || isLastQuestion) {
        if (!isEquifaxActive) {
          dispatch(handleNextCLick(nextQuestion1, canSkip))
          dispatch(handleOtherQuestionOnSamePage())
        }
        if (isEquifaxActive) {
          if (isSaveAsDraft) {
            dispatch(handleSubmitEquifax(equifaxDataFinal))
            history.push(ROUTE_CONSTANTS_VARIABLE.RATE_CARD, {
              quoteId: quoteIdForRateCard,
              rateFilter: quoteAnswersData?.[currentForm]?.[fieldName],
            })
          } else {
            history.push(ROUTE_CONSTANTS_VARIABLE.EQUIFAX, {
              isRedirectedFromQuote: true,
              isQuoteSubmit: true,
            })
          }
        } else {
          if (!isEquifaxActive && isLastQuestion && isSubmit) {
            history.push(ROUTE_CONSTANTS_VARIABLE.RATE_CARD, {
              quoteId: quoteIdForRateCard,
              rateFilter: quoteAnswersData?.[currentForm]?.[fieldName],
            })
          } else {
            history.push(ROUTE_CONSTANTS_VARIABLE.DASHBOARD, {
              isRedirectedFromQuote: true,
              isQuoteSubmit: true,
            })
          }
        }
      } else {
        goToNextQuestion(
          nextQuestions,
          defaultQuestions,
          otherField,
          type,
          checkDefaultForNext,
          fields,
          quoteAnswersData,
          currentForm,
          currentQuoteData,
          fieldName,
          quoteJsonData,
          dispatch,
          canSkip,
        )
      }
    }
  } else if (isValidate) {
    if (isSaveAsDraft) {
      dispatch(updatePreviousQuestionDetails(canSkip))
      if (isonEquifaxPage) {
        history.push(ROUTE_CONSTANTS_VARIABLE.REGISTER, {
          isQuoteSubmit: true,
          isRedirectedFromQuote: true,
        })
      } else {
        history.push(ROUTE_CONSTANTS_VARIABLE.REGISTER, { isRedirectedFromQuote: true })
      }
    } else if (isSubmit || isLastQuestion) {
      // dispatch(handleNextCLick(nextQuestion1))
      history.push(ROUTE_CONSTANTS_VARIABLE.EQUIFAX, {
        isRedirectedFromQuote: true,
        isQuoteSubmit: true,
      })
    } else {
      goToNextQuestion(
        nextQuestions,
        defaultQuestions,
        otherField,
        type,
        checkDefaultForNext,
        fields,
        quoteAnswersData,
        currentForm,
        currentQuoteData,
        fieldName,
        quoteJsonData,
        dispatch,
        canSkip,
      )
    }
  }
}
export const goToNextQuestion = async (
  nextQuestions,
  defaultQuestions,
  otherField,
  type,
  checkDefaultForNext,
  fields,
  quoteAnswersData,
  currentForm,
  currentQuoteData,
  fieldName,
  quoteJsonData,
  dispatch,
  canSkip = false,
) => {
  let nextQuestion

  if (type === "multipleQuestions") {
    const otherFormCallQuestion = fields?.find(e => e?.otherForm)
    if (otherFormCallQuestion && quoteAnswersData?.[currentForm]?.[otherFormCallQuestion?.fieldName]) {
      // ifOtherForm Flag is there
      getNextFormFromAPI(currentForm, quoteAnswersData, dispatch, quoteJsonData, canSkip)
      return
    } else if (nextQuestions) {
      const questionToCheckFlow = fields?.find(e => e?.questionToCheckFlow)
      // Get Flow Checker question
      let selectedAns = quoteAnswersData?.[currentForm]?.[questionToCheckFlow?.fieldName]
      if (questionToCheckFlow?.fieldName === "newBusinessOrIDontKnowMyABN" && selectedAns === undefined) {
        selectedAns = false
      }

      if (["Other", "Yes"]?.includes(selectedAns) && otherField && !["signedAgreement"].includes(fieldName)) {
        // if Other is selected
        getNextFormFromAPI(currentForm, quoteAnswersData, dispatch, quoteJsonData, canSkip)
        return
      } else if (!["Other", "Yes"]?.includes(selectedAns) || ["signedAgreement"].includes(fieldName)) {
        // Looping for field checker with selected ans~
        for (const [key, value] of Object.entries(nextQuestions?.[questionToCheckFlow?.fieldName])) {
          if (_.toLower(_.camelCase(key)) === _.toLower(_.camelCase(selectedAns))) {
            nextQuestion = value
            break
          }
        }
      }
      if (!nextQuestion) {
        // if Default is available for drop downs
        nextQuestion = currentQuoteData?.nextQuestions?.[questionToCheckFlow?.fieldName]?.default
      }
    }
  } else {
    if (nextQuestions) {
      const selectedAns = quoteAnswersData?.[currentForm]?.[fieldName]

      if (otherField && ["Other", "Visa", "Yes"].includes(selectedAns)) {
        // if Other option is selected
        if (selectedAns === "Yes" || (selectedAns === "Other" && fieldName === "assetYear")) {
          nextQuestion = currentQuoteData?.otherField?.defaultQuestions
        } else {
          if (quoteAnswersData?.[currentForm]?.visaCode !== undefined && selectedAns === "Visa") {
            nextQuestion = currentQuoteData?.otherField?.defaultQuestions
          } else {
            nextQuestion = undefined
          }
        }
      } else if (nextQuestions && Object.keys(nextQuestions).length > 0) {
        if (checkDefaultForNext && selectedAns) {
          // if next default question is there
          nextQuestion = currentQuoteData?.nextQuestions?.default
        } else {
          // console.log("checkDefaultForNext", checkDefaultForNext)
          if (fieldName === "theVehicleYouAreLookingFor" || fieldName === "theCarYouAreLookingFor") {
            nextQuestion = currentQuoteData?.nextQuestions?.assetDescription?.default
          } else {
            for (const [key, value] of Object.entries(nextQuestions)) {
              // Looping for field checker with selected ans
              if (_.toLower(_.camelCase(key)) === _.toLower(_.camelCase(selectedAns))) {
                nextQuestion = value
                break
              }
            }
          }
        }
      }
    }
  }
  if (nextQuestion) {
    const nextQuestionNew = checkNextQuestionValue(nextQuestion, currentForm, quoteJsonData, dispatch)
    if (nextQuestionNew === undefined) {
      getNextFormFromAPI(currentForm, quoteAnswersData, dispatch, quoteJsonData, canSkip)
    } else {
      dispatch(handleNextCLick(nextQuestionNew, canSkip))
      dispatch(handleOtherQuestionOnSamePage())
    }
  } else if (defaultQuestions) {
    const nextQuestionNew = checkNextQuestionValue(defaultQuestions, currentForm, quoteJsonData, dispatch)

    if (nextQuestionNew === undefined) {
      getNextFormFromAPI(currentForm, quoteAnswersData, dispatch, quoteJsonData, canSkip)
    } else {
      dispatch(handleNextCLick(nextQuestionNew, canSkip))
      dispatch(handleOtherQuestionOnSamePage())
    }
  } else {
    getNextFormFromAPI(currentForm, quoteAnswersData, dispatch, quoteJsonData, canSkip)
  }
}
export const checkNextQuestionValue = (nextQuestion, currentForm, quoteJsonData, dispatch) => {
  const questionAns = quoteJsonData?.[currentForm]?.[nextQuestion]?.option
  const questionType = quoteJsonData?.[currentForm]?.[nextQuestion].type

  if (questionAns !== undefined && questionAns.length > 1 && questionType !== "multipleQuestions") {
    return nextQuestion
  } else if (questionAns === undefined) {
    return nextQuestion
  } else {
    dispatch(
      updateQuoteAnswers(
        quoteJsonData?.[currentForm]?.[nextQuestion]?.fieldName,
        quoteJsonData?.[currentForm]?.[nextQuestion]?.option[0],
        quoteJsonData?.[currentForm]?.[nextQuestion]?.isOtherField || false,
      ),
    )
    dispatch(updatePreviousQuestionDetailsManual(currentForm, nextQuestion[0]))

    return quoteJsonData?.[currentForm]?.[nextQuestion]?.nextQuestions?.[questionAns]
  }
}
