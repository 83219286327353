import PropTypes from "prop-types"
import React, { createRef, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ABNModalTable from "../../../common/ABNModalTable/ABNModalTable"
import Button from "../../../common/Button/Button"
import CustomSelect from "../../../common/CustomSelect/CustomSelect"
import Input from "../../../common/Input/Input"
import RadioButton from "../../../common/RadioButton/RadioButton"
import { defaultAssetYears, defaultManufactureOption } from "../../../constants/DefaultDropDownOptions"
import { handleFieldValidation } from "../../../helpers/QuoteValidationHelper"
import { NumberCommaSeparator } from "../../../helpers/NumberCommaSeparator"
import { calculateBalloon, calculateToBeFinanced } from "../../../helpers/QuoteFormulaCalculations"
import NumberFormat from "react-number-format"
import {
  checkCarSearchPartial,
  disableFields,
  enableFields,
  getDataFromExternalSources,
  getQuoteValueUpdate,
  handleABNSearch,
  handleAssetConditionFieldChange,
  handleFinanceNumberFormChangeOnAssetChange,
  handleNextCLick,
  handleRegoSearch,
  updateAnswersOnPrevChanges,
  updateGlassGuideAnsData,
  updateGlassGuideLabelData,
  updateQuoteAnswers,
  wipeOutDataOnFieldsChange,
} from "../redux/QuoteActions"

import { EQUIFAX_DEFAULT_SCORE, REGEX } from "../../../constants/regexConstants"
import _ from "lodash"
import { useHistory } from "react-router-dom"
import { QUOTE_REDUX_CONSTANTS } from "../redux/QuoteReduxConstants"
import { getModifiedVehicleSelectForDefaultOption } from "../../../helpers/QuoteActiveSavingHelper"
import { stopLoader } from "../../../common/loader/redux/LoaderActions"
import { CheckBox } from "../../../common"
// import { event } from "jquery"
const GetQuoteComponent = Props => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [isAbnModalOpen, setIsAbnModalOpen] = useState(false)
  const [isHideError, setIsHideError] = useState(false)
  const [isAbnDataReceived, setIsAbnDataReceived] = useState(true)
  const searchRef = createRef()
  let index = 0
  const { data, isOtherField, error } = Props

  const {
    question,
    fieldName,
    fieldModel,
    placeholder,
    inputType,
    label,
    type,
    option,
    isMultipleSelect,
    request,
    isDefaultYearOptions,
    editable,
    previousYears,
    calculationFormula,
    formulaFields,
    getFieldFromLabel,
    dividerText,
    dependentFieldName,
    dependentFieldForm,
    isDependent,
    wipeOutFilledData,
    fieldsToWipeOut,
    isDisabled,
    minLimit,
    maxLimit,
    lessFortySuffix,
    moreFortySuffix,
    suggestion,
  } = data ?? {}

  const {
    quoteId,
    currentQuoteData,
    currentForm,
    dropDownOptions,
    glassGuideData,
    glassGuideLabel,
    AbnOrAcnSearchResults,
    isAbnDataCalled,
    currentQuestion,
    previousQuestions,
    isRegoDataCalled,
    quoteJsonData,
    isOnlyOneData,
    quoteAnswersData,
  } = useSelector(({ quoteReducer }) => quoteReducer ?? {})

  const { firstName, lastName, equifaxData, isonEquifaxPage } = useSelector(
    ({ equifaxReducer }) => equifaxReducer ?? {},
  )
  const equifaxDataFinal = {
    data: {
      firstName: firstName,
      lastName: lastName,
      creditScore: EQUIFAX_DEFAULT_SCORE,
      isDefaultScore: true,
    },
  }
  const authStatus = useSelector(({ authReducer }) => authReducer?.authStatus ?? false)
  const { _id: userId } = useSelector(({ headerReducer }) => headerReducer?.userProfile ?? {})
  const answer = quoteAnswersData?.[currentForm]

  const MAX_VALUE = 9999999999

  const checkDisable = currentQuoteData?.fieldToCheckDisable
  const isFieldDisabled = checkDisable ? quoteAnswersData?.[currentForm]?.[checkDisable] : undefined

  // const checkFinanceWhat = currentQuoteData?.checkFinanceWhat
  // useEffect(async () => {
  //   if (checkFinanceWhat) {
  //     const dependentFieldForm = currentQuoteData?.dependentFieldForm
  //     const dependentFieldName = currentQuoteData?.dependentFieldName
  //     const FinanceWhatAns = quoteAnswersData?.[dependentFieldForm]?.[dependentFieldName]
  //     await dispatch(changeFieldValue(FinanceWhatAns, currentQuoteData?.option, currentQuoteData?.fieldName))
  //   }
  // }, [checkFinanceWhat])

  const handleABNOrACNSearch = async (value, identifier) => {
    if (request?.url) {
      const { url, method, params } = request

      setIsAbnDataReceived(false)
      let paramData = {
        ...params,
        identifier: identifier ?? params?.identifier,
        searchString: value.trim(),
      }
      if (paramData?.identifier !== "ABNLookUp") {
        setIsAbnModalOpen(true)
      } else {
        paramData = {
          ...params,
          identifier: identifier ?? params?.identifier,
          searchString: value.replace(/ /g, ""),
        }
      }

      try {
        delete quoteAnswersData?.employment?.gstStatus
        delete quoteAnswersData?.employment?.businessName
        await dispatch(handleABNSearch(url, method, paramData))
        setIsAbnDataReceived(true)
        setIsHideError(true)
      } catch (e) {
        /**/
      }
    }
    setIsHideError(true)
    setTimeout(function() {
      dispatch(getQuoteValueUpdate(false))
    }, 2000)
  }
  // const { isFormShow, isEquifaxFailed } = useSelector( ( { equifaxReducer } ) => equifaxReducer ?? {} )

  const handleRegoStateSearch = value => {
    if (request?.url) {
      const { url, method, params } = request
      const paramData = {
        ...params,
        identifier: params?.identifier ?? params?.identifier,
        rego: params?.identifier === "getByRegoState" ? answer?.rego.toUpperCase() : undefined,
        state: params?.identifier === "getByRegoState" ? value : undefined,
        vin: params?.identifier === "getByVIN" ? value : undefined,
        assetType: quoteAnswersData?.loanType?.assetType,
      }
      dispatch(handleRegoSearch(url, method, paramData)).then(data => {
        setIsHideError(true)
        if (data) {
          dispatch(handleNextCLick(currentQuoteData?.defaultQuestions))
        }
      })
    }
  }

  const onCloseAbnModal = () => {
    setIsAbnModalOpen(false)
    setIsHideError(true)
    setTimeout(function() {
      dispatch(getQuoteValueUpdate(false))
    }, 2000)
  }

  const handleSuffixClick = e => {
    if (e?.value) {
      if (fieldName === "vin") {
        handleRegoStateSearch(e?.value)
      } else {
        handleABNOrACNSearch(e?.value)
      }
    }
  }

  const handleDropDownChange = e => {
    if (fieldName === "manufacturerOptions") {
      const noneSelected = e?.filter(e => e?.value === "None")
      if (!_.isEmpty(noneSelected)) {
        updateData(fieldName, ["None"], "None", isOtherField)
      } else {
        updateData(
          fieldName,
          isMultipleSelect ? e?.map(e => e?.value) : e?.value,
          isMultipleSelect ? e?.map(e => e?.label) : e?.label,
          isOtherField,
        )
      }
    } else {
      updateData(
        fieldName,
        isMultipleSelect ? e?.map(e => e?.value) : e?.value,
        isMultipleSelect ? e?.map(e => e?.label) : e?.label,
        isOtherField,
      )
    }
    if (getFieldFromLabel) {
      updateData(getFieldFromLabel, e?.label)
    }

    switch (type) {
      case "dropdownForGlassGuide": {
        dispatch(updateGlassGuideAnsData(fieldName, isMultipleSelect ? e?.map(e => e?.value) : e?.value))
        if (
          fieldName === "assetYear" ||
          fieldName === "assetMake" ||
          fieldName === "assetModel" ||
          fieldName === "assetBadge" ||
          fieldName === "assetSeries" ||
          fieldName === "manufacturerOptions"
        ) {
          dispatch(updateGlassGuideLabelData(fieldName, isMultipleSelect ? e?.map(e => e?.label) : e?.label))
        }
        setIsHideError(true)

        break
      }
      case "regoState":
        if (
          isDependent &&
          dependentFieldForm &&
          dependentFieldName &&
          quoteAnswersData?.[dependentFieldForm]?.[dependentFieldName]?.length > 0
        ) {
          handleRegoStateSearch(e?.value)
          setIsHideError(true)
        }
        break
      default:
        break
    }
  }
  useEffect(() => {
    if (isHideError) {
      handleFieldValidation(
        data,
        quoteAnswersData,
        currentForm,
        currentQuoteData,
        dropDownOptions,
        glassGuideData,
        glassGuideLabel,
        currentQuestion,
        previousQuestions,
        equifaxData,
        equifaxDataFinal,
        authStatus,
        userId,
        quoteId,
        isonEquifaxPage,
        history,
        quoteJsonData,
        dispatch,
      )
      setIsHideError(!isHideError)
    }
  }, [isHideError, quoteAnswersData])

  const updateData = async (fieldName, fieldValue, fieldLabel, isOtherField) => {
    if (fieldName === "financeWhat") {
      await dispatch(handleFinanceNumberFormChangeOnAssetChange(fieldValue))

      // await dispatch(
      //   changeFieldValue(fieldValue, quoteJsonData?.employment?.Q1?.option, quoteJsonData?.employment?.Q1?.fieldName),
      // )
    }

    if (
      fieldName === "financeWhat" ||
      fieldName === "assetType" ||
      fieldName === "assetCondition" ||
      fieldName === "purchaseTimeframe"
    ) {
      if (authStatus) {
        if (quoteAnswersData?.loanType?.vendor !== undefined) {
          dispatch(updateQuoteAnswers("vendor", "", false))
        }
      }
    }

    if (currentQuoteData?.isAlreadyVisited && quoteAnswersData?.[currentForm]?.[fieldName] !== fieldValue) {
      if (fieldName !== "theVehicleYouAreLookingFor") {
        let fieldsToKeep = currentQuoteData?.fields?.map(e => e?.fieldName) ?? []
        if (currentQuoteData?.otherField) fieldsToKeep = [...fieldsToKeep, currentQuoteData?.otherField?.fieldName]
        if (isOtherField) {
          // for other field in single field
          fieldsToKeep = [...fieldsToKeep, currentQuoteData?.fieldName]
        }
        fieldsToKeep = fieldsToKeep?.filter(e => e !== fieldName)

        dispatch(updateAnswersOnPrevChanges(fieldsToKeep))
      }
    }

    if (fieldName === "entityName") {
      dispatch(getQuoteValueUpdate(true))
    }
    if (fieldName === "model") {
      dispatch(
        updateQuoteAnswers("assetDescription", quoteAnswersData?.vehicleSelect?.assetYear + " " + fieldValue, false),
      )
    }
    if (fieldName === "assetType") {
      dispatch(updateGlassGuideAnsData(fieldName, fieldValue))
    }
    if (
      fieldName === "assetYear" ||
      fieldName === "assetMake" ||
      fieldName === "assetModel" ||
      fieldName === "assetBadge" ||
      fieldName === "assetSeries" ||
      fieldName === "manufacturerOptions"
    ) {
      dispatch(updateGlassGuideLabelData(fieldName, fieldLabel))
    }

    if (fieldName === "assetCondition" && fieldValue === "New") dispatch(handleAssetConditionFieldChange())
    if (fieldName === "entityName" && !fieldValue) {
      dispatch({
        type: QUOTE_REDUX_CONSTANTS.ABN_ACN.RESET_ABN_DATA,
      })
    }

    // fieldValue used directly because this case is always on checkbox
    if (wipeOutFilledData && fieldsToWipeOut && fieldValue) {
      dispatch(wipeOutDataOnFieldsChange(fieldsToWipeOut))
    }

    dispatch(updateQuoteAnswers(fieldName, fieldValue, isOtherField))

    setIsHideError(!isHideError)
  }

  useEffect(async () => {
    if (request?.url && type === "dropdownForGlassGuide") {
      const updatedGlassGuideData = getModifiedVehicleSelectForDefaultOption(glassGuideData)
      const { url, method, params } = request
      let paramData = {}
      for (const [key, value] of Object.entries(params)) {
        paramData = {
          ...paramData,
          [key]: value || updatedGlassGuideData?.[key],
        }
      }
      await dispatch(
        getDataFromExternalSources(
          url,
          method,
          paramData,
          fieldName,
          isOtherField,
          quoteAnswersData,
          currentQuoteData,
          isOnlyOneData,
        ),
      )
    }
    if (type === "inlineText" && fieldName === "assetDescription") {
      await dispatch({
        type: QUOTE_REDUX_CONSTANTS.SET_API_RESPONSE_CALLED,
        data: false,
      })
      stopLoader("isQuoteCommonLoader")
      stopLoader("isGlassGuideSkipActive")
    }
  }, [type])

  useEffect(() => {
    if (calculationFormula) {
      let fields
      formulaFields?.forEach(e => {
        fields = {
          ...fields,
          [e]: Number(answer?.[e]),
        }
      })

      if (calculationFormula === "toBeFinancedCalculation") calculateToBeFinanced(fields, dispatch)

      if (calculationFormula === "balloonCalculation") calculateBalloon(fields, dispatch)
    }
  }, [calculationFormula])

  useEffect(() => {
    if (checkDisable) {
      if (isFieldDisabled === true) dispatch(disableFields(checkDisable))
      else dispatch(enableFields())
    }
  }, [checkDisable, isFieldDisabled])
  const getComponent = () => {
    if (error !== undefined) {
      index = error.map(o => o.fieldName).indexOf(fieldName)
    }
    let isDisabledNew = false
    if (fieldName === "theVehicleYouAreLookingFor") {
      isDisabledNew = quoteAnswersData?.[currentForm]?.[checkDisable]
    }
    if (fieldName === "theCarYouAreLookingFor") {
      isDisabledNew = quoteAnswersData?.[currentForm]?.notTheCar
    }
    let fieldval = answer?.[fieldName]
    if (fieldName === "filter") {
      if (answer?.[fieldName] === "CHEAPEST_PAYMENTS") {
        fieldval = "Cheapest Repayments"
      }
      if (answer?.[fieldName] === "LOWEST_INTEREST_RATES") {
        fieldval = "Lowest Interest Rates"
      }
      if (answer?.[fieldName] === "CHEAPEST_TOTAL_COST") {
        fieldval = "Cheapest Total Cost"
      }
    }
    // console.log('type', type, answer);
    switch (type) {
      case "button": {
        return (
          <div className="answer-buttons">
            {option?.map(element => (
              <Button
                key={element}
                buttonType={
                  answer?.[fieldName] === element || fieldval === element ? "outlined-secondary" : "secondary"
                }
                className={`${answer?.[fieldName] === element || fieldval === element ? "quote-button" : ""}`}
                onClick={() => updateData(fieldName, element, "", isOtherField)}
                isDisabled={
                  fieldName === "theVehicleYouAreLookingFor" || fieldName === "theCarYouAreLookingFor"
                    ? isDisabledNew
                    : isDisabled
                }
                title={element?.toString()}
              />
            ))}
            {error && index >= 0 && error[index].fieldName === fieldName ? (
              <span className="quote-error-message">{error[index].errorMessage}</span>
            ) : (
              ""
            )}
          </div>
        )
      }

      case "text": {
        if (editable) {
          return (
            <>
              {isOtherField ? (
                <div className="question">
                  <div className="question_text text_small">{question}</div>{" "}
                </div>
              ) : (
                ""
              )}
              <Input
                type={inputType}
                className={`answer-input ${isOtherField ? "quote-other-question" : ""} ${
                  fieldName === "balloon" && answer?.[fieldName] !== undefined && answer?.[fieldName] > 40
                    ? "ballon-dollar-sign"
                    : ""
                } ${
                  error && index >= 0 && error[index].fieldName === fieldName
                    ? "input-has-value-or-focused border-error"
                    : ""
                }`}
                name={fieldName}
                value={
                  fieldName === "Kilometres" || fieldName === "kilometers"
                    ? NumberCommaSeparator(answer?.[fieldName])
                    : answer?.[fieldName]
                }
                minLength={minLimit}
                maxLength={maxLimit}
                suffixClass={
                  !_.isEmpty(moreFortySuffix) && !_.isEmpty(lessFortySuffix)
                    ? "material-icons-round quote-input-suffix"
                    : ""
                }
                suffix={
                  fieldName === "balloon" && answer?.[fieldName] !== undefined && answer?.[fieldName] < 41
                    ? lessFortySuffix
                    : ""
                }
                placeholder={placeholder}
                prefix={
                  fieldName === "balloon" && answer?.[fieldName] !== undefined && answer?.[fieldName] > 40 ? "$" : ""
                }
                prefixType="icon"
                onBlur={e => {
                  if (e.target.name === "rego" && isRegoDataCalled) {
                    dispatch(checkCarSearchPartial())
                  }
                }}
                onChange={e => {
                  if (e.target.name === "Kilometres" || e.target.name === "kilometers") {
                    const value = e.target.value !== undefined ? e.target.value.replaceAll(",", "") : e.target.value
                    if (REGEX.NUMBER_REGEX.test(value) || value === "") {
                      updateData(fieldName, value ?? 0, "", isOtherField)
                    }
                  } else if (
                    e.target.type === "number" &&
                    (e.target.name !== "kilometers" || e.target.name !== "Kilometers")
                  ) {
                    if (e.target.name === "balloon") {
                      const value = e.target.value
                      if (REGEX.DECIMAL_REGEX.test(value) || value === "") {
                        if (String(value).length <= maxLimit) {
                          value.replace(REGEX.DECIMAL_REGEX, "")
                          updateData(fieldName, value ?? 0, "", isOtherField)
                        }
                      }
                    } else {
                      const value = e.target.value
                      if (REGEX.NUMBER_REGEX.test(value) || value === "") {
                        value.replace(REGEX.NUMBER_REGEX, "")
                        updateData(fieldName, value ?? 0, "", isOtherField)
                      }
                    }
                  } else {
                    updateData(fieldName, e.target.value, "", isOtherField)
                  }
                }}
                disabled={!editable || isDisabled}
              />
              {isOtherField ? <div className="suggestion">{suggestion}</div> : ""}
              {error && index >= 0 && error[index].fieldName === fieldName ? (
                <span className="quote-error-message">{error[index].errorMessage}</span>
              ) : (
                ""
              )}
            </>
          )
        } else {
          return (
            <>
              {answer?.[fieldName] && (
                <div
                  className={`text-info small_width ${fieldName} ${
                    label === "Entity Name" && isAbnDataCalled ? "hidethis" : ""
                  }`}
                >{`${label}: ${answer?.[fieldName]}`}</div>
              )}
            </>
          )
        }
      }

      case "amount": {
        if (editable) {
          return (
            <>
              {isOtherField ? (
                <div className="question">
                  <div className="question_text text_small">{question}</div>{" "}
                </div>
              ) : (
                ""
              )}
              <NumberFormat
                value={answer?.[fieldName]}
                isAllowed={({ floatValue }) => {
                  return floatValue !== undefined ? floatValue < MAX_VALUE : true
                }}
                className={`answer-input ${isOtherField ? "quote-other-question" : ""}  ${
                  error && index >= 0 && error[index].fieldName === fieldName
                    ? "input-has-value-or-focused border-error"
                    : ""
                }`}
                name={fieldName}
                placeholder={placeholder}
                // onChange={e => {
                //   const value = e.target.value !== undefined ? e.target.value : 0
                //   let value1 = value.replace(/[&#,+()$~%'":*?<>{}]/g, "")
                //   value1 = value1.replace("- ", "").trim()
                //   value1 = value1.replace("-", "").trim()
                //   value1 = value1.replace(/^0+/, "")
                //   updateData(fieldName, value1 ?? 0, "", isOtherField)
                // }}
                customInput={Input}
                thousandSeparator={true}
                prefix={"$ "}
                disabled={!editable || isDisabled}
                onValueChange={values => {
                  let value1 = values?.value.replace(/[&#,+()$~%'":*?<>{}]/g, "")
                  value1 = value1.replace("- ", "").trim()
                  value1 = value1.replace("-", "").trim()
                  value1 = value1.replace(/^0+/, "")
                  updateData(fieldName, value1 ?? 0, "", isOtherField)
                }}
              />
              {isOtherField ? <div className="suggestion">{suggestion}</div> : ""}

              {error && index >= 0 && error[index].fieldName === fieldName ? (
                <span className="quote-error-message">{error[index].errorMessage}</span>
              ) : (
                ""
              )}
            </>
          )
        } else {
          return (
            <>
              {answer?.[fieldName] && (
                <div className="text-info small_width">{`${label}: $ ${NumberCommaSeparator(
                  answer?.[fieldName],
                )}`}</div>
              )}
              {error && index >= 0 && error[index].fieldName === fieldName ? (
                <span className="quote-error-message">{error[index].errorMessage}</span>
              ) : (
                ""
              )}
            </>
          )
        }
      }
      case "inlineText": {
        return (
          <>
            {answer?.[fieldName] && answer?.[fieldModel] && (
              <div className="text-info small_width">{`${answer?.[fieldName]}`}</div>
            )}
            {error && index >= 0 && error[index].fieldName === fieldName ? (
              <span className="quote-error-message">{error[index].errorMessage}</span>
            ) : (
              ""
            )}
          </>
        )
      }
      case "search": {
        return (
          <>
            {isOtherField ? (
              <div className="question">
                <div className="question_text text_small">{question}</div>{" "}
              </div>
            ) : (
              ""
            )}
            <Input
              type={inputType}
              className={`answer-input ${isOtherField ? "quote-other-question" : ""}  ${
                error && index >= 0 && error[index].fieldName === fieldName
                  ? "input-has-value-or-focused border-error"
                  : ""
              }`}
              name={fieldName}
              value={answer?.[fieldName]}
              ref={searchRef}
              placeholder={placeholder}
              onChange={e => updateData(fieldName, e.target.value, "", isOtherField)}
              disabled={!editable || isDisabled}
              suffixClass="material-icons-round cursor-pointer quote-input-suffix"
              suffix="search"
              suffixClick={handleSuffixClick}
              onKeyDown={e => {
                if (e?.key === "Enter") {
                  handleSuffixClick(e?.target)
                }
              }}
            />
            {isOtherField ? <div className="suggestion">{suggestion}</div> : ""}
            {error && index >= 0 && error[index].fieldName === fieldName ? (
              <span className="quote-error-message">{error[index].errorMessage}</span>
            ) : (
              ""
            )}
          </>
        )
      }

      case "radio": {
        return (
          <>
            <div className="d-flex">
              {option?.map((e, index) => (
                <RadioButton
                  name={fieldName}
                  id={`rad` + index}
                  key={index}
                  label={e}
                  value={e}
                  checked={answer?.[fieldName] === e}
                  onChange={e => updateData(fieldName, e.target.value, "", isOtherField)}
                  disabled={isDisabled}
                />
              ))}
            </div>
            {error && index >= 0 && error[index].fieldName === fieldName ? (
              <span className="quote-error-message">{error[index].errorMessage}</span>
            ) : (
              ""
            )}
          </>
        )
      }

      case "checkbox": {
        return (
          <>
            <div className="w-50">
              <CheckBox
                title={question}
                name={fieldName}
                checked={answer?.[fieldName]}
                onChange={e => updateData(fieldName, e.target.checked, "", isOtherField)}
                disabled={isDisabled}
                className="d-flex align-center"
              />
            </div>
            {error && index >= 0 && error[index].fieldName === fieldName ? (
              <span className="quote-error-message">{error[index].errorMessage}</span>
            ) : (
              ""
            )}
          </>
        )
      }

      case "dropdown":
      case "dropdownForGlassGuide":
      case "regoState": {
        const optionData = isDefaultYearOptions
          ? defaultAssetYears(previousYears)
          : (type === "dropdownForGlassGuide"
              ? fieldName === "manufacturerOptions"
                ? defaultManufactureOption(dropDownOptions?.glassGuideOptions)
                : dropDownOptions?.glassGuideOptions
              : option?.map(e => ({ label: e, value: e }))) ?? []

        const value = isMultipleSelect
          ? optionData?.filter(e => answer?.[fieldName]?.includes(e?.value))
          : optionData?.find(e => e?.value === answer?.[fieldName])

        return (
          <>
            <CustomSelect
              placeholder={placeholder}
              options={optionData}
              value={value}
              className={`answer-custom-select ${fieldName === "nvic" ? "big_custom_input" : ""} ${
                error && index >= 0 && error[index].fieldName === fieldName
                  ? "input-has-value-or-focused border-error"
                  : ""
              }`}
              onChangeCustomSelect={handleDropDownChange}
              isMulti={isMultipleSelect}
              isDisabled={
                fieldName === "state" &&
                (quoteAnswersData?.[dependentFieldForm]?.[dependentFieldName]?.length === 0 ||
                  quoteAnswersData?.[dependentFieldForm]?.[dependentFieldName]?.length === undefined)
                  ? true
                  : isDisabled
              }
            />
            {error && index >= 0 && error[index].fieldName === fieldName ? (
              <span className="quote-error-message">{error[index].errorMessage}</span>
            ) : (
              ""
            )}
          </>
        )
      }

      case "divider":
        return <div className="divider-text">{dividerText ?? ""}</div>

      default:
        return <div />
    }
  }
  return (
    <React.Fragment>
      {getComponent()}

      {isAbnModalOpen && (
        <ABNModalTable
          tableData={AbnOrAcnSearchResults}
          isModalOpen={isAbnModalOpen}
          isLoading={isAbnDataReceived}
          onClose={onCloseAbnModal}
          onRecordClick={e => handleABNOrACNSearch(e?.abn, "ABNLookUp")}
        />
      )}
    </React.Fragment>
  )
}

GetQuoteComponent.propTypes = {
  data: PropTypes.object.isRequired,
  isOtherField: PropTypes.bool.isRequired,
}

export default React.memo(GetQuoteComponent)
